import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Autocomplete, Box, Grid, MenuItem, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectMerchants } from "../../store/merchant/merchantSlice";
import {
    useGetMerchantAddressQuery,
    useLazyGetMerchantAddressQuery,
    useGetMerchantsByNameQuery,
    useLazyGetMerchantsByNameQuery
} from "../../store/merchant/merchantApiSlice";
import {
    useGetMyOrderQuery,
    useGetOrderQuery, useLazyGetMyOrderQuery, useLazyGetOrderQuery,
    usePostOrderMutation,
    usePutOrderMutation
} from "../../store/orders/orderApiSlice";
import { setPackages } from "../../store/orders/orderSlice";
import { toast } from "react-toastify";
import moment from "moment";
import CustomPaperBox from "../../theme/style-components/CustomPaperBox";
import CustomSelect from "../../theme/style-components/CustomSelect";
import CustomButton from "../../theme/style-components/CustomButton";
import CustomDatePicker from "../../theme/style-components/CustomDatePicker";
import ComponentTitle from "../../shared/ComponentTitle";
import TabTitle from "../../shared/TabTitle";

const CreateMyOrder = (props) => {
    //creating classes from styles
    const [selectedDate, setSelectedDate] = useState(null);
    const [createdDate, setCreatedDate] = useState(null);


    const authState = useSelector(state => state.auth)
    const { userType, userID } = authState || {}

    //redux logic
    const dispatch = useDispatch();

    //router logic
    const navigate = useNavigate();
    const location = useLocation();
    let orderID = useParams();

    //variables
    let date = new Date();

    let merchants = useSelector(selectMerchants);

    //state variables
    const [merchantName, setMerchantName] = useState("");
    const [orderDate, setOrderDate] = useState(date);
    const [selectedMerchant, setSelectedMerchant] = useState("");
    const [addresses, setAddresses] = useState();
    const [address, setAddress] = useState();
    const [isEditing, setIsEditing] = useState(false);
    const [searchedName, setSearchedName] = useState('');
    const [merchantsToDisplay, setMerchantsToDisplay] = useState([]);
    const [selectedTime, setSelectedTime] = useState();
    const [selectedTimeOptions, setSelectedTimeOptions] = useState();
    const [startMinDate, setStartDate] = useState(new Date());
    const [time, setTime] = useState()
    const [order, setOrder] = useState({});
    const [hasUpdatedStartMinDate, setHasUpdatedStartMinDate] = useState(false);
    const [addressData, setAddressData] = useState([]);
    const [otherFees, setOtherFees] = useState('');
    const [otherFeesComment, setOtherFeesComment] = useState('');
    const [isCustomPickupOpen, setIsCustomPickupOpen] = useState(false);
    const [customPickupAddress, setCustomPickupAddress] = useState({});
    const [isCustomPickup, setIsCustomPickup] = useState(false);

    //base query hooks

    const [getMerchantAddress, {
        isLoading,
        data: dataReturned,
        isSuccess,
        isError,
        error,
    }] = useLazyGetMerchantAddressQuery();


    useEffect(() => {
        if (userType === "MERCHANT") {
            getAddress()
        }
    }, [userType]);

    const getAddress = async (newValue, orderData) => {
        try {
            const Addressdata = getMerchantAddress({ userID: userType === "MERCHANT" ? userID : newValue?.id ? newValue.id : newValue, subscribedOnly: true }).unwrap().then((res) => {
                if (res) {
                    if (res?.data) {
                        if (orderData) {
                            const editTimeDate = moment.utc(orderData.scheduled_dt);
                            const formattedTime = editTimeDate.format('HH:mm:ss');
                            const preSelectedTimeID = res?.data.filter((add) => add.id === orderData.merchant_address_details.id)[0].assigned_time_slots.filter((slot) => slot.start_time === formattedTime)[0].id
                            setSelectedTime(preSelectedTimeID, "preSelectedTimeID")
                        }

                        res.data.forEach((address) => {
                            if (address.id === orderData?.merchant_address_details.id) {
                                setAddress(address.id);
                                const selectedTimeSlot = orderData?.merchant_address_details?.assigned_time_slots?.map((slot) => slot)
                            }
                        })
                    }
                }
            })
        } catch (err) {
            console.log(err, "err");
        }
    };

    const [getMyOrder, {
        isLoading: isMyOrderLoading,
        data: myOrderReturned,
        isSuccess: isMyOrderSuccess,
        isError: isMyOrderError,
        error: myOrderError,
    }] = useLazyGetMyOrderQuery();

    useEffect(() => {
        if (orderID?.orderID && userType === "MERCHANT") {
            getMyOrder({ page: 1, query: `&order_id=${orderID.orderID}` })
        }
    }, [orderID, userType])

    const [getOrder, {
        isLoading: isOrderLoading,
        data: newOrder,
        isSuccess: isOrderSuccess,
        isError: isOrderError,
        error: orderError,
    }] = useLazyGetOrderQuery();

    const [postOrder, { isPostOrderLoading }] = usePostOrderMutation();
    const [putOrder, { isPutOrderLoading }] = usePutOrderMutation();

    useEffect(() => {
        const fetchOrder = (id) => {
            const orderData = getOrder(id && id.orderID).unwrap().then((res) => {
                if (res) {
                    setOrder(res)
                    setIsEditing(true);
                }
                if (res?.data) {
                    if (res?.data[0]) {
                        setSelectedMerchant({
                            label: res.data[0].merchant_first_name + " " + res.data[0].merchant_last_name,
                            id: res.data[0].merchant
                        });
                        if (res?.data[0]?.merchant) {
                            setSearchedName("")
                            getAddress(res?.data[0]?.merchant, res?.data[0])
                        }

                        let d = new Date(res?.data[0]?.scheduled_dt)
                        setOrderDate(d);
                        const convertedDate = moment.utc(res?.data[0]?.scheduled_dt).utcOffset("+05:30").format('YYYY-MM-DDTHH:mm:ss');
                        setSelectedDate(convertedDate);
                        setCreatedDate(moment(res?.data[0]?.created_at).format("DD MMMM YYYY"))
                    }

                    if (res?.data[0]?.pickup_address_details) {
                        if (res?.data[0]?.custom_pickup) {
                            setIsCustomPickup(true);
                            setCustomPickupAddress(res?.data[0]?.pickup_address_details)
                        }
                    }
                    // if(res?.data[0]?.pickup_address_details){
                    //   setPickupAddress(res?.data[0]?.pickup_address_details)
                    // }
                }
            })
        }

        if (orderID?.orderID && userType === "ADMIN") {
            fetchOrder(orderID)
        }
    }, [orderID, userType])


    useEffect(() => {
        if (!isLoading && dataReturned?.data) {
            setAddresses(dataReturned.data);
        }
    }, [isLoading, dataReturned?.data, selectedMerchant, selectedMerchant?.id, addresses]);

    useEffect(() => {
        if (addresses?.length && !isEditing) {
            setAddress(addresses[addresses?.length - 1]?.id)
            const preSelectedTime = addresses[addresses?.length - 1]?.assigned_time_slots?.map((time) => time);
            setSelectedTime(preSelectedTime[0]?.id)
        }
    }, [addresses, order]);

    useEffect(() => {
        if (!isLoading && dataReturned?.data) {
            setAddresses(dataReturned.data);
        }
    }, [isLoading, dataReturned?.data]);

    useEffect(() => {
        if (userType === "MERCHANT") {
            if (!isMyOrderLoading && !isMyOrderError && myOrderReturned?.data) {
                prePopulateFormWithMyOrder(myOrderReturned?.data);
                setIsEditing(true);
            }
        }
    }, [isOrderLoading, order, isOrderError, dataReturned, isLoading, isMyOrderLoading, isMyOrderError, myOrderReturned]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    const handleTimeChange = (event) => {
        setSelectedTime(event.target.value);
    };

    useEffect(() => {
        if (selectedTimeOptions?.length && selectedTime) {
            const timeD = selectedTimeOptions.filter((option) => option.id === selectedTime)[0]?.start_time
            setTime(timeD)
        }
    }, [selectedTime, selectedTimeOptions]);

    const [isSubmiting, setIsSubmiting] = useState(false);

    const handleNextClicked = async () => {

        //write validation logic here
        try {
            var dateObj = new Date(selectedDate);

            const year = dateObj ? dateObj.getFullYear().toString() : null;
            const month = dateObj ? (dateObj.getMonth() + 1).toString() : null;
            const day = dateObj ? dateObj.getDate().toString() : null;

            const latestD = `${year}-${month}-${day} ${time}`

            if (!isEditing) {
                setIsSubmiting(true);
                const postOrderValue = {
                    user_id: userType === "MERCHANT" ? userID : selectedMerchant.id,
                    pickup_address_id: address,
                    scheduled_dt: latestD,
                    name: selectedMerchant.name,
                }

                if (!isEmptyCustomPickupAddress) {
                    postOrderValue.pickup_address = customPickupAddress
                };
                const response = await postOrder(postOrderValue).unwrap();
                toast.success(`New order created successfully with ID ${response.data.id}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                if (response) {
                    setIsSubmiting(false);
                }
                // navigate(`/orders/packages/${response.data.id}`);
                navigate(`/my-orders/${response.data.id}`);
                dispatch(setPackages([]));
            } else {
                setIsSubmiting(true);
                try {
                    const putOrderValue = {
                        order_id: orderID.orderID,
                        user_id: userType === "MERCHANT" ? userID : selectedMerchant.id,
                        pickup_address_id: address,
                        scheduled_dt: latestD,
                        name: selectedMerchant.name,
                    }

                    if (!isEmptyCustomPickupAddress) {
                        putOrderValue.pickup_address = customPickupAddress
                    };
                    const response = await putOrder(putOrderValue).unwrap();
                    toast.success(`Order updated successfully with ID ${response.data.id}`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    if (response) {
                        setIsSubmiting(false);
                    }
                } catch (err) {
                    console.log(err);
                }
            }
        } catch (error) {
            toast.error(`Failed ${error.data?.message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            setIsSubmiting(false);
            console.log(error);
        }
    };

    const prePopulateFormWithMyOrder = () => {
        let d = new Date(myOrderReturned.data[0].scheduled_dt)
        setCreatedDate(moment(myOrderReturned.data[0].created_at).format("DD MMMM YYYY"))
        setOrderDate(d);
        const convertedDate = moment.utc(myOrderReturned.data[0].scheduled_dt).utcOffset("+05:30").format('YYYY-MM-DDTHH:mm:ss');
        setSelectedDate(new Date(convertedDate));

        if (myOrderReturned?.data[0]?.pickup_address_details) {
            if (myOrderReturned?.data[0]?.custom_pickup) {
                setIsCustomPickup(true);
                setCustomPickupAddress(myOrderReturned?.data[0]?.pickup_address_details)
            }
        }

        if (!isLoading && dataReturned?.data) {
            // Parse the API date string into a Moment.js object and set the timezone to UTC
            const editTimeDate = moment.utc(myOrderReturned.data[0].scheduled_dt);
            const formattedTime = editTimeDate.format('HH:mm:ss');
            const preSelectedTimeID = dataReturned.data
                .filter((add) => add.id === myOrderReturned.data[0]?.merchant_address_details?.id)[0]?.assigned_time_slots
                .filter((slot) => slot.start_time === formattedTime)[0]?.id;
            if (preSelectedTimeID) {
                setSelectedTime(preSelectedTimeID)
            } else {
                const wihoutSlots = dataReturned.data
                    .filter((add) => add.id === myOrderReturned.data[0]?.merchant_address_details?.id)[0]?.assigned_time_slots[0]?.id
                setSelectedTime(wihoutSlots)
            }
            dataReturned.data.forEach((address) => {
                if (address.id === myOrderReturned.data[0].merchant_address_details.id) {
                    setAddress(address.id);
                }
            })
        }
    }

    useEffect(() => {
        if (selectedTimeOptions?.length && selectedTime) {
            const exactTime = selectedTimeOptions.find(item => item.id === selectedTime)?.start_time;
            // Assume the API returns a string value for the time in "hh:mm:ss" format
            const apiTime = exactTime;
            if (apiTime) {
                // Get the current date and time
                const currentTime = new Date();

                // Split the API time into hours, minutes, and seconds
                const [hours, minutes, seconds] = apiTime && apiTime.split(':');

                // Set the time part of the current time to the API time
                currentTime.setHours(hours);
                currentTime.setMinutes(minutes);
                currentTime.setSeconds(seconds);

                // Subtract 30 minutes from the current time
                const thirtyMinutesBefore = new Date(currentTime.getTime() - 30 * 60 * 1000);

                // Check if the calculated time is less than or equal to the threshold time
                if (new Date() <= thirtyMinutesBefore) {
                    console.log('The current time is less than or equal to the calculated time.');
                    if (!isEditing) {
                        setSelectedDate(startMinDate);
                    }
                } else {
                    if (!hasUpdatedStartMinDate) {
                        startMinDate.setDate(startMinDate.getDate() + 1);
                        if (!isEditing) {
                            setSelectedDate(startMinDate);
                        }
                        setHasUpdatedStartMinDate(true);
                    }
                }
            }
        }
    }, [selectedTime, selectedTimeOptions, isEditing])

    useEffect(() => {
        if (addresses?.length) {
            addresses.map((add) => {
                if (add.id === address) {
                    if (add?.assigned_time_slots) {
                        setSelectedTimeOptions(add?.assigned_time_slots)
                    }
                }
            })
        } else {
            setSelectedTimeOptions(undefined)
        }
    }, [address, address?.length, addresses?.length, addresses, selectedMerchant, selectedMerchant?.id])

    function isEmptyObject(obj) {
        return Object.keys(obj).length === 0;
    }

    const isEmptyCustomPickupAddress = isEmptyObject(customPickupAddress);

    const breadcrumbs = [
        <Link to="/my-orders">
            My Orders
        </Link>,
        <Typography key="2" variant="body1">
            {orderID?.orderID ? "Edit Order" : "Create Order"}
        </Typography>,
    ];


    return (
        <Box>
            <TabTitle title={orderID?.orderID ? "Edit Order" : "Create Order"} />
            <ComponentTitle breadcrumbs={breadcrumbs} />
            <CustomPaperBox elevation={5} >
                <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}>
                    <Box>{orderID?.orderID ? <h3> Order ID: {orderID.orderID}</h3> : null}</Box>
                    <Box>{createdDate ? <h3 style={{
                        color: "gray",
                        fontWeight: 200,
                        fontSize: "15px"
                    }}> Created At: {createdDate}</h3> : null}</Box>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <Box>
                            <CustomDatePicker selectedDate={selectedDate}
                                onChange={handleDateChange}
                                minDate={startMinDate}
                            />
                        </Box>
                    </Grid>

                    {selectedTimeOptions?.length !== 0 && selectedTimeOptions !== undefined && selectedTime &&
                        <Grid item xs={12} md={3}>
                            <Box>
                                <CustomSelect value={selectedTime} label="Time" onChange={handleTimeChange}>
                                    {selectedTimeOptions?.map((time, index) => {
                                        return (
                                            <MenuItem key={index} value={time.id}>{time.start_time} - {time.end_time}</MenuItem>
                                        )
                                    })}
                                </CustomSelect>
                            </Box>
                        </Grid>
                    }
                    {address?.length !== 0 && addresses?.length !== 0 && address && <Grid item xs={12} md={6}>
                        <Box>
                            <Box>
                                <CustomSelect
                                    fullWidth
                                    id="address"
                                    name="address"
                                    label="Select Address"
                                    placeholder="Select Address"
                                    onChange={(e) => {
                                        setAddress(e.target.value);
                                    }}
                                    value={address}>
                                    {addresses &&
                                        addresses.map((address, index) => (
                                            <MenuItem value={address.id} key={index}>
                                                <strong>{address.title} </strong>&nbsp; {address.address}
                                            </MenuItem>
                                        ))}
                                </CustomSelect>
                            </Box>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <h2 style={{ marginBottom: '0' }}> {isCustomPickup ? "Custom Pickup Address" : "PickUp Address"}</h2>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                {isCustomPickup ? <>
                                    {customPickupAddress.title} <br />
                                    {customPickupAddress.address} <br />
                                    {customPickupAddress.city} <br />
                                    {customPickupAddress.postcode}
                                </> : addresses && address && addresses?.map((add, index) => {
                                    if (add.id === address) {
                                        return (
                                            <Box key={index}>
                                                {add.title} <br />
                                                {add.address} <br />
                                                {add.city} <br />
                                                {add.postcode}
                                            </Box>
                                        )
                                    }
                                })}
                            </Grid>
                        </Grid>
                    </Grid>}
                </Grid>
                <br />
                <Grid
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingBottom: "1em",
                        paddingTop: '20px',
                    }}
                    container
                    fullWidth
                    spacing={1}
                >
                    <CustomButton
                        color="primary"
                        disabled={isSubmiting}
                        buttonName={isEditing ? "Update" : "Next"}
                        onClick={handleNextClicked}
                    />
                </Grid>
            </CustomPaperBox>
        </Box>
    );
};

export default CreateMyOrder;
