import React, { useEffect } from 'react'
import CustomPaperBox from '../../theme/style-components/CustomPaperBox'
import CustomDatePicker from '../../theme/style-components/CustomDatePicker'
import { Autocomplete, Box, Grid, IconButton, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import CustomInput from '../../theme/style-components/CustomInput'
import CustomSelect from '../../theme/style-components/CustomSelect'
import CustomButton from '../../theme/style-components/CustomButton'
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { selectMerchants } from "../../store/merchant/merchantSlice";
import moment from 'moment/moment'
import { useLazyGetMerchantAddressQuery, useLazyGetMerchantsByNameQuery } from "../../store/merchant/merchantApiSlice";
import {
    useLazyGetMyOrderQuery,
    useLazyGetOrderQuery,
    usePostOrderMutation,
    usePutOrderMutation
} from "../../store/orders/orderApiSlice";
import { toast } from 'react-toastify'
import { setPackages } from "../../store/orders/orderSlice";
import CustomAutoComplete from '../../theme/style-components/CustomAutoComplete'
import CustomModal from '../../theme/style-components/CustomModal'
import CustomPickupAddress from './CustomPickupAddress'
import ComponentTitle from '../../shared/ComponentTitle'
import TabTitle from '../../shared/TabTitle'
import DeleteIcon from '@mui/icons-material/Delete';
import { showError } from '../../shared/helperFunction'

const CreateOrder = () => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [createdDate, setCreatedDate] = useState(null);


    const authState = useSelector(state => state.auth)
    const { userType, userID } = authState || {}

    //redux logic
    const dispatch = useDispatch();

    //router logic
    const navigate = useNavigate();
    const location = useLocation();
    let orderID = useParams();

    //variables
    let date = new Date();

    let merchants = useSelector(selectMerchants);

    // merchants = merchants.map((merchant) => {
    //   return {
    //     label: merchant.first_name + " " + merchant.last_name,
    //     id: merchant.id,
    //   };
    // });

    //state variables
    const [merchantName, setMerchantName] = useState("");
    const [orderDate, setOrderDate] = useState(date);
    const [selectedMerchant, setSelectedMerchant] = useState("");
    const [addresses, setAddresses] = useState();
    const [address, setAddress] = useState();
    const [isEditing, setIsEditing] = useState(false);
    const [searchedName, setSearchedName] = useState('');
    const [merchantsToDisplay, setMerchantsToDisplay] = useState([]);
    const [selectedTime, setSelectedTime] = useState();
    const [selectedTimeOptions, setSelectedTimeOptions] = useState();
    const [startMinDate, setStartDate] = useState(new Date());
    const [time, setTime] = useState()
    const [order, setOrder] = useState({});
    const [hasUpdatedStartMinDate, setHasUpdatedStartMinDate] = useState(false);
    const [addressData, setAddressData] = useState([]);
    const [otherFees, setOtherFees] = useState('');
    const [otherFeesComment, setOtherFeesComment] = useState('');
    const [isCustomPickupOpen, setIsCustomPickupOpen] = useState(false);
    const [customPickupAddress, setCustomPickupAddress] = useState({});
    const [isCustomPickup, setIsCustomPickup] = useState(false);

    //base query hooks

    const [getMerchantAddress, {
        isLoading,
        data: dataReturned,
        isSuccess,
        isError,
        error,
    }] = useLazyGetMerchantAddressQuery();


    useEffect(() => {
        if (userType === "MERCHANT") {
            getAddress()
        }
    }, [userType]);

    const getAddress = async (newValue, orderData) => {
        try {
            const Addressdata = getMerchantAddress({ userID: userType === "MERCHANT" ? userID : newValue?.id ? newValue.id : newValue, subscribedOnly: true }).unwrap().then((res) => {
                if (res) {
                    if (res?.data) {
                        if (orderData) {
                            const editTimeDate = moment.utc(orderData.scheduled_dt);
                            const formattedTime = editTimeDate.format('HH:mm:ss');
                            const preSelectedTimeID = res?.data?.filter((add) => add?.id === orderData?.merchant_address_details?.id)[0]?.assigned_time_slots.filter((slot) => slot?.start_time === formattedTime)[0]?.id
                            setSelectedTime(preSelectedTimeID, "preSelectedTimeID")
                        }

                        res.data.forEach((address) => {
                            if (address.id === orderData?.merchant_address_details.id) {
                                setAddress(address.id);
                                const selectedTimeSlot = orderData?.merchant_address_details?.assigned_time_slots?.map((slot) => slot)


                                // if(selectedTimeSlot){
                                //   setSelectedTime(selectedTimeSlot[0])
                                // }
                            }
                        })
                    }
                }
            })
        } catch (err) {
            console.log(err, "err");
        }
    };

    // const {
    //   isLoading: isMyOrderLoading,
    //   data: myOrderReturned,
    //   isSuccess: isMyOrderSuccess,
    //   isError: isMyOrderError,
    //   error: myOrderError,
    // } = useGetMyOrderQuery({ page: 1, query: `&order_id=${orderID.orderID}` });


    const [getMyOrder, {
        isLoading: isMyOrderLoading,
        data: myOrderReturned,
        isSuccess: isMyOrderSuccess,
        isError: isMyOrderError,
        error: myOrderError,
    }] = useLazyGetMyOrderQuery();

    useEffect(() => {
        if (orderID?.orderID && userType === "MERCHANT") {
            getMyOrder({ page: 1, query: `&order_id=${orderID.orderID}` })
        }
    }, [orderID, userType])



    // const {
    //   isLoading: isMerchantSearchedByNameLoading,
    //   data: merchantSearchedByName,
    //   isSuccess: isMerchantSearchedByNameSuccess,
    //   isError: isMerchantSearchedByNameError,
    //   error: merchantSearchedByNameError
    // } = useGetMerchantsByNameQuery(searchedName);

    const [getMerchantsByName, {
        isLoading: isMerchantSearchedByNameLoading,
        data: merchantSearchedByName,
        isSuccess: isMerchantSearchedByNameSuccess,
        isError: isMerchantSearchedByNameError,
        error: merchantSearchedByNameError
    }] = useLazyGetMerchantsByNameQuery();

    useEffect(() => {
        searchMerchant(searchedName);
    }, [searchedName])

    const searchMerchant = async (searchText) => {
        const data = await getMerchantsByName({ name: searchText, status: true }).unwrap();
    }


    // const {
    //   isLoading: isOrderLoading,
    //   data: order,
    //   isSuccess: isOrderSuccess,
    //   isError: isOrderError,
    //   error: orderError,
    // } = useGetOrderQuery(orderID && orderID.orderID);

    const [getOrder, {
        isLoading: isOrderLoading,
        data: newOrder,
        isSuccess: isOrderSuccess,
        isError: isOrderError,
        error: orderError,
    }] = useLazyGetOrderQuery();

    const [postOrder, { isPostOrderLoading }] = usePostOrderMutation();
    const [putOrder, { isPutOrderLoading }] = usePutOrderMutation();

    // useEffect(()=> {
    //   if(order?.data){
    //     if(order?.data[0]){
    //       setSelectedMerchant({
    //         label: order.data[0].merchant_first_name + " " + order.data[0].merchant_last_name,
    //         id: order.data[0].merchant
    //       });
    //       if(order?.data[0]?.merchant){
    //         setSearchedName("")
    //         getAddress(order?.data[0]?.merchant, order?.data[0])
    //       }
    //
    //       let d = new Date(order?.data[0]?.scheduled_dt)
    //       setOrderDate(d);
    //       setSelectedDate(d)
    //     }
    //   }
    // }, [order]);

    useEffect(() => {
        const fetchOrder = (id) => {
            const orderData = getOrder(id && id.orderID).unwrap().then((res) => {
                if (res) {
                    setOrder(res)
                    setIsEditing(true);
                }
                if (res?.data) {
                    if (res?.data[0]) {
                        setSelectedMerchant({
                            label: res.data[0].merchant_first_name + " " + res.data[0].merchant_last_name,
                            id: res.data[0].merchant
                        });
                        if (res?.data[0]?.merchant) {
                            setSearchedName("")
                            getAddress(res?.data[0]?.merchant, res?.data[0])
                        }

                        let d = new Date(res?.data[0]?.scheduled_dt)
                        setOrderDate(d);
                        const convertedDate = moment.utc(res?.data[0]?.scheduled_dt).utcOffset("+05:30").format('YYYY-MM-DDTHH:mm:ss');
                        setSelectedDate(new Date(convertedDate));

                        setCreatedDate(moment(res?.data[0]?.created_at).format("DD MMMM YYYY"))
                    }

                    if (res?.data[0]?.pickup_address_details) {
                        if (res?.data[0]?.custom_pickup) {
                            setIsCustomPickup(true);
                            setCustomPickupAddress(res?.data[0]?.pickup_address_details)
                        }
                    }
                    // if(res?.data[0]?.pickup_address_details){
                    //   setPickupAddress(res?.data[0]?.pickup_address_details)
                    // }
                }
            })
        }

        if (orderID?.orderID && userType === "ADMIN") {
            fetchOrder(orderID)
        }
    }, [orderID, userType])

    //hooks
    useEffect(() => {
        const { userType, userID } = authState || {}
        // setUserType()
        if (!isMerchantSearchedByNameLoading && isMerchantSearchedByNameSuccess) {
            const tempMerchants = merchantSearchedByName?.data?.map((merchant) => {
                return {
                    label: merchant.first_name + " " + merchant.last_name,
                    id: merchant.id,
                };
            });
            if (tempMerchants.length > 0) {
                setMerchantsToDisplay(tempMerchants);
            }
        }
    }, [merchantSearchedByName?.data?.length, isOrderLoading, merchantSearchedByName, order?.data?.length, userType, isMerchantSearchedByNameLoading, isMerchantSearchedByNameSuccess])

    useEffect(() => {
        if (!isLoading && dataReturned?.data) {
            setAddresses(dataReturned.data);
        }
    }, [isLoading, dataReturned?.data, selectedMerchant, selectedMerchant?.id, addresses]);

    useEffect(() => {
        if (addresses?.length && !isEditing) {
            setAddress(addresses[addresses?.length - 1]?.id)
            const preSelectedTime = addresses[addresses?.length - 1]?.assigned_time_slots?.map((time) => time);
            setSelectedTime(preSelectedTime[0]?.id)
        }
    }, [addresses, order]);

    useEffect(() => {
        if (!isLoading && dataReturned?.data) {
            setAddresses(dataReturned.data);
        }
    }, [isLoading, dataReturned?.data]);

    useEffect(() => {
        if (userType === "MERCHANT") {
            if (!isMyOrderLoading && !isMyOrderError && myOrderReturned?.data) {
                prePopulateFormWithMyOrder(myOrderReturned?.data);
                setIsEditing(true);
            }
        }

        // if (location.pathname.includes('editmyorder')) {
        //   if (!isMyOrderLoading && !isMyOrderError) {
        //     prePopulateFormWithMyOrder(myOrderReturned?.data);
        //     setIsEditing(true);
        //   }
        // }

    }, [isOrderLoading, merchantSearchedByName?.data?.length, order, isOrderError, dataReturned, isLoading, isMyOrderLoading, isMyOrderError, myOrderReturned]);

    // useEffect(() => {
    //   if (!isOrderLoading && !isOrderError ) {
    //     prePopulateForm();
    //     setIsEditing(true);
    // if(order?.data[0]){
    //   setSelectedMerchant({
    //     label: order.data[0].merchant_first_name + " " + order.data[0].merchant_last_name,
    //     id: order.data[0].merchant
    //   });
    //   if(order?.data[0]?.merchant){
    //     setSearchedName("")
    //     getAddress(order?.data[0]?.merchant)
    //   }
    // }
    //   }
    // }, [isOrderLoading, isOrderError,order])

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    const handleTimeChange = (event) => {
        setSelectedTime(event.target.value);
    };

    useEffect(() => {
        if (selectedTimeOptions?.length && selectedTime) {
            const timeD = selectedTimeOptions.filter((option) => option.id === selectedTime)[0]?.start_time
            setTime(timeD)
        }
    }, [selectedTime, selectedTimeOptions]);

    const [isSubmiting, setIsSubmiting] = useState(false);

    const handleNextClicked = async () => {

        //write validation logic here
        try {
            var dateObj = new Date(selectedDate);

            const year = dateObj ? dateObj.getFullYear().toString() : null;
            const month = dateObj ? (dateObj.getMonth() + 1).toString() : null;
            const day = dateObj ? dateObj.getDate().toString() : null;

            const latestD = `${year}-${month}-${day} ${time}`

            if (!isEditing) {
                setIsSubmiting(true);
                const postOrderValue = {
                    user_id: userType === "MERCHANT" ? userID : selectedMerchant?.id,
                    pickup_address_id: address,
                    scheduled_dt: latestD,
                    name: selectedMerchant.name,
                }

                if (!isEmptyCustomPickupAddress) {
                    postOrderValue.pickup_address = customPickupAddress
                };
                const response = await postOrder(postOrderValue).unwrap();
                toast.success(`New order created successfully with ID ${response.data.id}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                if (response) {
                    setIsSubmiting(false);
                }
                navigate(`/orders/${response.data.id}`);
                dispatch(setPackages([]));
            } else {
                setIsSubmiting(true);
                try {
                    const putOrderValue = {
                        order_id: orderID.orderID,
                        user_id: userType === "MERCHANT" ? userID : selectedMerchant?.id,
                        pickup_address_id: address,
                        scheduled_dt: latestD,
                        name: selectedMerchant.name,
                    }

                    if (!isEmptyCustomPickupAddress) {
                        putOrderValue.pickup_address = customPickupAddress
                    };
                    const response = await putOrder(putOrderValue).unwrap();
                    toast.success(`Order updated successfully with ID ${response.data.id}`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    if (response) {
                        setIsSubmiting(false);
                    }
                } catch (err) {
                    showError(err?.data)
                    if (err) {
                        setIsSubmiting(false);
                    }
                }
            }
        } catch (error) {
            setIsSubmiting(false);
            showError(error?.data)
        }
    };
    const MenuProps = {
        PaperProps: {
            style: {
                width: 250,
            },
        },
    };

    const prePopulateFormWithMyOrder = () => {
        let d = new Date(myOrderReturned.data[0].scheduled_dt)
        setCreatedDate(moment(myOrderReturned.data[0].created_at).format("DD MMMM YYYY"))
        setOrderDate(d);
        const convertedDate = moment.utc(myOrderReturned.data[0].scheduled_dt).utcOffset("+05:30").format('YYYY-MM-DDTHH:mm:ss');
        setSelectedDate(new Date(convertedDate));

        // merchantsToDisplay.forEach((merchant) => {
        //   if (merchant.id === myOrderReturned.data[0].merchant) {
        //     setSelectedMerchant(merchant);
        //   }
        // });

        // if(myOrderReturned?.data[0]?.pickup_address_details){
        //   setPickupAddress(myOrderReturned?.data[0]?.pickup_address_details)
        // }

        if (myOrderReturned?.data[0]?.pickup_address_details) {
            if (myOrderReturned?.data[0]?.custom_pickup) {
                setIsCustomPickup(true);
                setCustomPickupAddress(myOrderReturned?.data[0]?.pickup_address_details)
            }
        }

        if (!isLoading && dataReturned?.data) {
            // const editTime = new Date(myOrderReturned.data[0].scheduled_dt).getTime()
            // Parse the API date string into a Moment.js object and set the timezone to UTC
            const editTimeDate = moment.utc(myOrderReturned.data[0].scheduled_dt);
            const formattedTime = editTimeDate.format('HH:mm:ss');
            const preSelectedTimeID = dataReturned.data.filter((add) => add.id === myOrderReturned.data[0].merchant_address_details.id)[0].assigned_time_slots.filter((slot) => slot.start_time === formattedTime)[0].id
            setSelectedTime(preSelectedTimeID)

            dataReturned.data.forEach((address) => {
                if (address.id === myOrderReturned.data[0].merchant_address_details.id) {
                    setAddress(address.id);
                    // const selectedTimeSlot = myOrderReturned?.data[0]?.pickup_address_details?.assigned_time_slots?.map((slot)=> slot)
                    // if(selectedTimeSlot){
                    //   setSelectedTime(selectedTimeSlot[0])
                    // }
                }
            })
        }
    }

    const prePopulateForm = () => {
        // let d = new Date(order?.data[0]?.scheduled_dt)
        // setOrderDate(d);
        // setSelectedDate(d)


        // if (dataReturned?.data) {
        //   dataReturned.data.forEach((address) => {
        //     if (address.id === order.data[0].pickup_address_details.id) {
        //       setAddress(address.id);
        //       const selectedTimeSlot = order?.data[0]?.pickup_address_details?.assigned_time_slots?.map((slot)=> slot)
        //       if(selectedTimeSlot){
        //         setSelectedTime(selectedTimeSlot[0])
        //       }
        //     }
        //   })
        // }
    };

    useEffect(() => {
        // if (currentHour > 15 || (currentHour === 15 && currentMinute > 30)) {
        //   return setSelectedDate(new Date());
        // } else {
        //   startMinDate.setDate(startMinDate.getDate() + 1);
        //   setSelectedDate(startMinDate)
        // }


        if (selectedTimeOptions?.length && selectedTime) {
            const exactTime = selectedTimeOptions.find(item => item.id === selectedTime)?.start_time;
            // Assume the API returns a string value for the time in "hh:mm:ss" format
            const apiTime = exactTime;

            if (apiTime) {
                // Get the current date and time
                const currentTime = new Date();

                // Split the API time into hours, minutes, and seconds
                const [hours, minutes, seconds] = apiTime && apiTime.split(':');

                // Set the time part of the current time to the API time
                currentTime.setHours(hours);
                currentTime.setMinutes(minutes);
                currentTime.setSeconds(seconds);

                // Subtract 30 minutes from the current time
                const thirtyMinutesBefore = new Date(currentTime.getTime() - 30 * 60 * 1000);

                // Check if the calculated time is less than or equal to the threshold time
                if (new Date() <= thirtyMinutesBefore) {
                    console.log('The current time is less than or equal to the calculated time.');
                    if (!isEditing) {
                        setSelectedDate(startMinDate);
                    }
                } else {
                    if (!hasUpdatedStartMinDate) {
                        startMinDate.setDate(startMinDate.getDate() + 1);
                        if (!isEditing) {
                            setSelectedDate(startMinDate);
                        }
                        setHasUpdatedStartMinDate(true);
                    }
                }
            }
        }
    }, [selectedTime, selectedTimeOptions, isEditing])

    useEffect(() => {
        if (addresses?.length) {
            addresses.map((add) => {
                if (add.id === address) {
                    if (add?.assigned_time_slots) {
                        setSelectedTimeOptions(add?.assigned_time_slots)
                        // setSelectedTime(add?.assigned_time_slots[0]?.id)
                    }
                }
            })
        } else {
            setSelectedTimeOptions(undefined);
        }
    }, [address, address?.length, addresses?.length, addresses, selectedMerchant, selectedMerchant?.id])


    const handleCustomPickupClick = () => {
        setIsCustomPickupOpen(true);
    };

    const handleCustomAddressSubmit = (value) => {
        setCustomPickupAddress(value);
        setIsCustomPickupOpen(false);
        setIsCustomPickup(true)
    };

    function isEmptyObject(obj) {
        return Object.keys(obj).length === 0;
    }

    const isEmptyCustomPickupAddress = isEmptyObject(customPickupAddress);

    const handleDeleteCustomPickup = () => {
        setIsCustomPickup(false);
        setCustomPickupAddress({})
    };

    const breadcrumbs = [
        <Link key="1" to="/orders">
            Orders
        </Link>,
        <Typography key="2" variant="body1">
            {orderID?.orderID ? "Edit Order" : "Create Order"}
        </Typography>,
    ];

    return (<>
        <TabTitle title={orderID?.orderID ? "Edit Order" : "Create Order"} />
        <ComponentTitle breadcrumbs={breadcrumbs} />
        <CustomPaperBox>
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
            }}>
                <Box>{orderID?.orderID ? <h3> Order ID: {orderID.orderID}</h3> : null}</Box>
                <Box>{createdDate ? <h3 style={{
                    color: "gray",
                    fontWeight: 200,
                    fontSize: "15px"
                }}> Created At: {createdDate}</h3> : null}</Box>
            </Box>
            <Grid container spacing={2}>
                {userType !== "MERCHANT" && merchantsToDisplay?.length > 0 &&
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <CustomAutoComplete
                            value={selectedMerchant}
                            onChange={(event, newValue) => {
                                setSelectedMerchant(newValue);
                                if (newValue !== null) {
                                    getAddress(newValue);
                                }
                            }}
                            onChangeText={(e) => {
                                setSearchedName(e.target.value)
                            }}
                            onInputChange={(event, newInputValue) => {
                                if (newInputValue === '') {
                                    setSelectedMerchant(null); // reset selected merchant when the input value is empty
                                    // searchMerchant(newInputValue)
                                }
                                setSearchedName(newInputValue);
                            }}
                            label="Search Merchant" options={merchantsToDisplay} />
                    </Grid>}
                <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                    <Box>
                        <CustomDatePicker
                            selectedDate={selectedDate}
                            onChange={handleDateChange}
                            minDate={startMinDate}
                        />
                    </Box>
                </Grid>
                {selectedTimeOptions?.length !== 0 && selectedTimeOptions !== undefined && selectedTime &&
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <Box>
                            <CustomSelect value={selectedTime} label="Time" onChange={handleTimeChange}>
                                {selectedTimeOptions?.map((time, index) => {
                                    return (
                                        <MenuItem key={index} value={time.id}>{time.start_time} - {time.end_time}</MenuItem>
                                    )
                                })}
                            </CustomSelect>
                        </Box>
                    </Grid>
                }
                {address?.length !== 0 && addresses?.length !== 0 && address &&
                    <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                        <Box>
                            <Box>
                                <CustomSelect
                                    fullWidth
                                    id="address"
                                    name="address"
                                    label="Select Address"
                                    placeholder="Select Address"
                                    onChange={(e) => {
                                        setAddress(e.target.value);
                                    }}
                                    value={address}>
                                    {addresses &&
                                        addresses.map((address, index) => (
                                            <MenuItem value={address.id} key={index}>
                                                <strong>{address.title} </strong>&nbsp; {address.address}
                                            </MenuItem>
                                        ))}
                                </CustomSelect>
                            </Box>
                        </Box>

                        <Typography variant='h3' sx={{
                            fontSize: '22px',
                            marginBottom: '10px',
                            marginTop: '10px'
                        }}>{isCustomPickup ? "Custom Pickup Address" : "PickUp Address"}</Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                {isCustomPickup ? <Box sx={{ display: "flex", alignItems: "center", border: "1px solid #dbdbeb61", padding: "10px", borderRadius: "5px" }}>
                                    <Box>
                                        {customPickupAddress.title}
                                        {customPickupAddress.address} <br />
                                        {customPickupAddress.city} <br />
                                        {customPickupAddress.postcode} <br />
                                        {customPickupAddress.note ? `Note : ${customPickupAddress.note}` : null}
                                    </Box>
                                    <Box sx={{
                                        "& svg": {
                                            fill: "#ff3e1d"
                                        }
                                    }}><IconButton onClick={() => {
                                        setIsCustomPickup(false)
                                        setCustomPickupAddress({})
                                    }}><DeleteIcon fill='red' /></IconButton></Box>
                                </Box> : addresses && address && addresses?.map((add, index) => {
                                    if (add.id === address) {
                                        return (
                                            <Box key={index}>
                                                {add.title} <br />
                                                {add.address} <br />
                                                {add.city} <br />
                                                {add.postcode}
                                            </Box>
                                        )
                                    }
                                })}
                            </Grid>
                        </Grid>
                    </Grid>}
                {addresses && <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                    <Box sx={{
                        '& button': {
                            padding: '14px',
                            width: '100%'
                        }
                    }}>
                        <CustomButton
                            onClick={handleCustomPickupClick}
                            buttonName="Custom Pickup Address" color="primary" outline />
                    </Box>
                </Grid>}
                {selectedMerchant?.id && <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <CustomButton
                        color="primary"
                        disabled={isSubmiting}
                        buttonName={isEditing ? "Update" : "Submit"}
                        isLoading={isSubmiting}
                        onClick={handleNextClicked}
                    />
                </Grid>}
            </Grid>



            <CustomModal
                maxWidth="md"
                title={isEmptyCustomPickupAddress ? "Add Address" : "Edit Address"}
                isShowActionButton={false}
                isModalOpen={isCustomPickupOpen} handleClose={() => {
                    setIsCustomPickupOpen((prevState) => !prevState)
                }}>
                <CustomPickupAddress
                    isEdit={isEmptyCustomPickupAddress ? false : true}
                    editData={customPickupAddress}
                    onSubmit={handleCustomAddressSubmit}
                    handleClose={() => {
                        setIsCustomPickupOpen((prevState) => !prevState)
                    }}
                />
            </CustomModal>
        </CustomPaperBox >
    </>
    )
}

export default CreateOrder