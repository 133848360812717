import { Box, Grid, Typography } from "@mui/material";
import styled from "@emotion/styled";
import walletIcon from '../../assets/dashboard-image/stats-vertical-wallet.png'
import TabTitle from "../../shared/TabTitle";
import { useGetUserDashboardQuery } from "../../store/dashboard/userDashboardApiSlice";
import OkIcon from "../../assets/dashboard-image/icons8-tick-box.svg";
import deliveryCar from "../../assets/dashboard-image/icons8-delivery-64.png"
import proofIcon from "../../assets/dashboard-image/icons8-proof-60.png"
import CustomButton from "../../theme/style-components/CustomButton";
import CustomIconBox from "../../theme/style-components/CustomIconBox";
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import PaymentsIcon from '@mui/icons-material/Payments';
import CheckIcon from '@mui/icons-material/Check';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import ComponentTitle from "../../shared/ComponentTitle";

const Card = styled(Box)(({ theme }) => ({
    display: 'flex',
    backgroundColor: '#2b2c40',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 10px 0px',
    padding: '14px',
    borderRadius: '8px',
    height: "200px",
    color: '#dbdbeb99',
    overflow: 'auto',
    "& img": {
        width: '42px',
        marginBottom: '16px',
    },
    "& p": {
        fontWeight: '500',
        marginBottom: '2px'
    },
    "& h5": {
        fontWeight: "500",
        color: '#dbdbebde',
        [theme.breakpoints.up('xs')]: {
            fontSize: '18px'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '20px'
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '24px'
        },
    }
}));



const Dashboard = () => {
    const { data: userDashboardData, isLoading } = useGetUserDashboardQuery();
    const remainingDelivery = userDashboardData?.data?.current_subscriptions?.length
        && userDashboardData?.data?.current_subscriptions.reduce((acc, curr) => acc + curr?.deliveries_left, 0);

    const breadcrumbs = [
        <Typography key="2" variant="body1">
            Dashboard
        </Typography>,
    ];


    // Sample array of objects
    var data = [
        { order_id: 1, name: "Item 1" },
        { order_id: 2, name: "Item 2" },
        { order_id: 1, name: "Item 3" },
        { order_id: 3, name: "Item 4" },
        { order_id: 2, name: "Item 5" },
    ];

    // Function to generate a random color
    function getRandomColor() {
        var letters = "0123456789ABCDEF";
        var color = "#";
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    // Create an object to store colors based on order_id
    var colorMap = {};

    // Loop through the array and add color_key based on order_id
    data.forEach(function (item) {
        var order_id = item.order_id;
        if (!colorMap[order_id]) {
            colorMap[order_id] = getRandomColor();
        }
        item.color_key = colorMap[order_id];
    });

    return (
        <Box>
            <Box sx={{ marginBottom: '20px' }}>
                <ComponentTitle breadcrumbs={breadcrumbs} />
            </Box>
            <TabTitle title="Dashboard" />
            <Grid container spacing={2}>
                <Grid item lg={2} xs={6}>
                    <Card>
                        <Box>
                            {/* <img src={deliveryCar} alt='img' /> */}
                            <CustomIconBox color="primary">
                                <AirportShuttleIcon />
                            </CustomIconBox>
                            <Typography>Deliveries remaining this month</Typography>
                            <Typography variant="h5">{remainingDelivery ? remainingDelivery : 0}</Typography>
                        </Box>
                    </Card>
                </Grid>
                <Grid item lg={2} xs={6}>
                    <Card>
                        <Box>
                            <CustomIconBox color="success"><CheckIcon /></CustomIconBox>
                            <Typography>Deliveries consumed this month</Typography>
                            <Typography variant="h5">{userDashboardData?.data?.total_deliveries_this_month}</Typography>
                        </Box>
                    </Card>
                </Grid>
                <Grid item lg={2} xs={6}>
                    <Card>
                        <Box>
                            {/* <img src={walletIcon} alt='img' /> */}
                            <CustomIconBox color="warning"><PaymentsIcon /></CustomIconBox>
                            <Typography>Total deliveries till date</Typography>
                            <Typography variant="h5">{userDashboardData?.data?.total_deliveries}</Typography>
                        </Box>
                    </Card>
                </Grid>
                <Grid item lg={2} xs={6}>
                    <Card>
                        <Box >
                            {/* <img src={proofIcon} alt='img' /> */}
                            <CustomIconBox color="info"><ImageSearchIcon /></CustomIconBox>
                            <Typography sx={{ wordBreak: "break-all" }}>Proof of deliveries<br />(Block-chain)</Typography>
                            <Typography variant="h5">{userDashboardData?.data?.total_qr_deliveries}</Typography>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
};

export default Dashboard;