import { useEffect, useRef, useState } from "react";
import { useLazyGetBrangerLastLocQuery, useLazyGetBrangerTasksQuery } from "../../store/tasks/taskApiSlice";
import { Circle, GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react";
import axios from "axios";
import { Box, CircularProgress, Typography } from "@mui/material";
import deliveryVan from "../../assets/driver-van.png";
import redIcon from "../../assets/red_marker_map.svg";
import greenIcon from "../../assets/green_marker_map.svg";
import blueIcon from "../../assets/blue_map_marker.svg";
import { decryptFn } from "../../shared/helperFunction";


export const MapContainer = (props) => {
  const google = window.google;
  const [mapRef, setMapRef] = useState(null);
  const [myKey, setMyKey] = useState(Math.random());
  const [startMarker, setStartMarker] = useState([]);
  const [endMarker, setEndMarker] = useState([]);
  const [previousDirectionsRenderer, setPreviousDirectionsRenderer] =
    useState(null);

  const [isLoading, setIsLoading] = useState(false);
  //instantiate directions service and directions renderer
  const directionsService = new google.maps.DirectionsService();
  let directionsRenderer = new google.maps.DirectionsRenderer({
    map: mapRef,
    suppressMarkers: true,
  });
  const [previousPoints, setPreviousPoints] = useState(null);
  const brangers = props.freeBusyBranger;
  const allDeliveries = props.allDeliveries;


  const [currentLocation, setCurrentLocation] = useState({
    lat: 37.7749,
    lng: -122.4194
  });

  const token = decryptFn(localStorage.getItem("accessToken"));

  const request = {
    method: 'SUBSCRIBE',
    params: ["ids"],
    ids: brangers?.length > 0 ? brangers?.map((item) => item.id) : [],
  };

  useEffect(() => {
    if (brangers?.length > 0) {

      const wsClient = new WebSocket(`${process.env.REACT_APP_WEBSOCKET}/ws/branger_location/?token=${token}`);
      wsClient.onopen = () => {
        wsClient.send(JSON.stringify(request));
      };

      wsClient.onmessage = (evt) => {
        const brangersData = JSON.parse(evt.data);
        if (brangersData?.data) {
          setBrangerMarkers(brangersData?.data)
        }
      };

      // wsClient.onclose = () => console.log('ws closed');
      return () => {
        wsClient.close();
      };
    }
  }, [brangers]);

  //state variables
  let coords = [];
  let waypoints = [];

  const [
    getBrangerLastLoc,
    {
      isLoading: brangerLastLocLoading,
      data: brangerLastLocReturned,
      isSuccess: isBrangerLastLocSuccess,
      isError: isBrangerLastLocError,
      error: brangerLastLocError,
    },
  ] = useLazyGetBrangerLastLocQuery();

  const [brangersMarker, setBrangerMarkers] = useState([]);

  useEffect(() => {
    if (props.points !== null && props.points !== previousPoints) {
      // compare with the previous points value
      setPreviousPoints(props.points); // update the previous points value
      const coords = [];
      const waypoints = [];
      props.points.forEach((point) => {
        if (point.lat && point.long) {
          coords.push({
            lat: point.lat,
            lng: point.long,
          });
        }
      });

      //Getting the first coordinate in the array as the start/origin
      let start = { lat: coords[0]?.lat, lng: coords[0]?.lng };
      //Getting the last coordinate in the array as the end/destination
      let end = {
        lat: coords[coords.length - 1]?.lat,
        lng: coords[coords.length - 1]?.lng,
      };

      //putting all the coordinates between the first and last coordinate from the array as the waypoints
      for (let i = 1; i < coords.length - 1; i++) {
        waypoints.push({
          location: { lat: coords[i].lat, lng: coords[i].lng },
          stopover: true,
        });
      }

      // directions requests
      let request = {
        origin: start,
        waypoints: waypoints,
        destination: end,
        travelMode: "DRIVING",
      };

      // const directionsRenderer = new window.google.maps.DirectionsRenderer({
      //   suppressMarkers: true,
      //   map: mapRef,
      // });

      setPreviousDirectionsRenderer(directionsRenderer);
      //
      // // show results in the directionsrenderer
      // directionsRenderer.setDirections(null);

      //show results in the directionsrenderer
      directionsService.route(request, function (result, status) {
        if (result?.status == "OK") {
          directionsRenderer.setDirections(result);

          const endLocations = result.routes[0].legs.map(
            (leg) => leg.end_location
          );
          const markers = endLocations.map((location, index) => (
            <Marker
              key={index}
              position={location}
              label={{
                text: endLocations.length === 1 ? "D" : `D${index + 1}`,
                color: "#FFFFFF",
                fontSize: "16px",
              }}
            />
          ));

          if (coords.length === 1) {
            const startLocation = result.routes[0].legs[0].start_location;
            const startMark = (
              <Marker
                position={{ lat: coords[0]?.lat, lng: coords[0]?.lng }}
                icon={{
                  url: deliveryVan,
                  fillColor: '#EB00FF',
                  scale: 7,
                }}
              />
            );
            if (startMark) {
              setStartMarker((pre) => [...pre, startMark]);
            }
          } else if (markers && endLocations.length !== 0) {
            setEndMarker((pre) => [...pre, markers]);
            const startLocation = result.routes[0].legs[0].start_location;
            const startMark = (
              <Marker
                position={startLocation}
                label={{ text: "P", color: "#FFFFFF", fontSize: "16px" }}
              />
            );
            if (startMark) {
              setStartMarker((pre) => [...pre, startMark]);
            }
          }
        }
      });
    }
  }, [props.points]);

  useEffect(() => {
    if (directionsRenderer) {
      directionsRenderer.setMap(null); // remove the renderer from the map
      directionsRenderer.setDirections({}); // remove the directions from the renderer
      setPreviousPoints(null); // clear the previousPoints state variable
      setStartMarker([]); // clear the startMarker state variable
      setEndMarker([]); // clear the endMarker state variable
      setMyKey(Math.random()); // re-render the Map component
      props.setClearMap(false);
    }
  }, [props.clearMap]);

  useEffect(() => {
    if (props.points === null) {
      directionsRenderer.setMap(null); // remove the renderer from the map
      directionsRenderer.setDirections({}); // remove the directions from the renderer
      setPreviousPoints(null); // clear the previousPoints state variable
      setStartMarker([]); // clear the startMarker state variable
      setEndMarker([]); // clear the endMarker state variable
      setMyKey(Math.random()); // re-render the Map component
    }
  }, [props.points])

  const onMapReady = (mapProps, map) => {
    setMapRef(map);
  };

  //if (!this.props.loaded) return <div>Loading...</div>;
  const [activeMarker, setActiveMarker] = useState([]);
  const [selectedMarkerPositon, setSelectedMarkerPosition] = useState(null);
  const [selectedBrangerDetails, setSelectedBrangerDetails] = useState(null);
  const [showInfoWindow, setShowInfoWindow] = useState(false);

  const [getBrangerTask, {
    isLoading: areBrangerTasksLoading,
    data: brangerTasksReturned,
    isSuccess: isBrangerTaskSuccess,
    isError: isBrangerTaskError,
    error: brangerTaskError,
  }] = useLazyGetBrangerTasksQuery();

  const callGetBrangerTask = async (brangerID) => {
    try {
      const response = await getBrangerTask({
        brangerId: brangerID,
        task_status_arr: ["ASSIGNED", "STARTED", "PICKEDUP"],
      }).unwrap()

      if (response) {
        setActiveMarker(response?.data)
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const fetchBrangerAddress = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDp8kshAwl2VeZe4lSseBVFhYVNQpVXyt0`
      );

      if (response.data.results.length > 0) {
        return (response.data.results[0].formatted_address);
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };
  const handleMarkerClick = async (prop, marker) => {
    setActiveMarker([]);
    setIsLoading(true);
    setShowInfoWindow(true);
    setSelectedMarkerPosition({ lat: marker?.data?.lat, lng: marker?.data?.long, id: Number(marker?.data?.user_id) });
    props.openBrangerDetails(Number(marker?.data?.user_id))
    if (marker?.data?.id) {
      const address = await fetchBrangerAddress(marker?.data?.lat, marker?.data?.long)
      if (address) {
        const selected = brangers.filter((item) => item.id === Number(marker?.data?.user_id)).map((brang) => ({ ...brang, address: address }))[0]
        if (selected) {
          setSelectedBrangerDetails(selected)
        }
      }
      //   callGetBrangerTask(marker?.data?.id);
    }
  };

  const [selectedPickupDropMarkerPosition, setSelectedPickupDropMarkerPosition] = useState(null);
  const [showPickupDropInfoWindow, setShowPickupDropInfoWindow] = useState(false);
  const [selectedMarkerETA, setSelectedMarkerETA] = useState('');
  const [selectedpickupDropDetails, setSelectedpickupDropDetails] = useState({});

  const getETA = (driverLocation, pickupLocation) => {
    const distanceService = new google.maps.DistanceMatrixService();

    // Prepare request parameters
    const request = {
      origins: [driverLocation],
      destinations: [pickupLocation],
      travelMode: google.maps.TravelMode.DRIVING // Use the appropriate travel mode
    };

    // Call the Distance Matrix service to get the ETA
    distanceService.getDistanceMatrix(request, (response, status) => {
      if (status === 'OK') {
        const duration = response.rows[0].elements[0].duration;
        const eta = duration?.text;

        const distance = response.rows[0].elements[0].distance;
        const distanceText = distance?.text;

        if (eta && distanceText) {
          setSelectedMarkerETA(`${eta} (${distanceText})`);
        }

      }
    });
  };

  const handlePickupMarkerClick = async (props, marker) => {
    setSelectedMarkerETA('')
    setShowPickupDropInfoWindow(true);
    const brangerID = marker.data.tasks[0].branger
    const brangerLoc = brangersMarker.filter((brang) => Number(brang.user_id) === brangerID)[0]
    const driverLocation = { lat: Number(brangerLoc?.lat), lng: Number(brangerLoc?.long) };
    const pickupLocation = { lat: Number(marker.data.pickup_address_details.lat), lng: Number(marker.data.pickup_address_details.long) };
    const brangerDetails = brangers.filter((brang) => brang.id === brangerID)[0]?.first_name
    const allDetails = { ...marker.data, branger_first_name: brangerDetails }
    setSelectedPickupDropMarkerPosition(pickupLocation);
    setSelectedpickupDropDetails(allDetails);
    if (driverLocation && pickupLocation) {
      getETA(driverLocation, pickupLocation);
    }
  };

  const handleDropMarkerClick = async (props, marker) => {
    setSelectedMarkerETA('')
    setShowPickupDropInfoWindow(true);

    setSelectedPickupDropMarkerPosition({ lat: Number(marker?.data?.drop_address?.lat), lng: Number(marker?.data?.drop_address?.long) });

    let allDetails = []

    allDeliveries.forEach((item) => {
      item.tasks.forEach((task) => {
        if (task.id === marker.data.id) {
          allDetails.push(item)
        }
      })
    })

    if (allDetails[0]) {
      const lastDetails = { ...marker.data, order_id: allDetails[0].id, task_id: marker.data.id, branger_first_name: marker.data.branger_name }
      setSelectedpickupDropDetails(lastDetails);
    }

    const brangerID = marker.data.branger
    const brangerLoc = brangersMarker.filter((brang) => Number(brang.user_id) === brangerID)[0]

    const pickupLocation = { lat: Number(marker?.data?.drop_address?.lat), lng: Number(marker?.data?.drop_address?.long) };
    const driverLocation = { lat: Number(brangerLoc?.lat), lng: Number(brangerLoc?.long) };

    if (driverLocation && pickupLocation) {
      getETA(driverLocation, pickupLocation);
    }
  };

  return (
    <Box>
      <Map
        style={{ height: "100%", width: "100%" }}
        google={google}
        zoom={14}
        key={myKey}
        onReady={onMapReady}
        initialCenter={{ lat: -37.82296, lng: 144.979401 }}
        ref={mapRef}>
        {startMarker}
        {endMarker}
        {!props.points && allDeliveries?.length > 0 &&
          allDeliveries.map((delivery, index) => {
            if (delivery) {
              return (
                <Marker
                  position={{ lat: Number(delivery?.pickup_address_details?.lat), lng: Number(delivery?.pickup_address_details?.long) }}
                  label={{
                    text: `P`, color: "#FFFFFF", fontSize: "16px",
                  }}
                  data={delivery}
                  icon={{
                    url: delivery?.task_status === "UNASSIGNED" ? redIcon : delivery?.task_status !== "DELIVERED" ? blueIcon : greenIcon,
                    // Assume we're working with an icon size of 40x40
                    scaledSize: new google.maps.Size(40, 40),
                    // Set the anchor to the middle of your icon image
                    anchor: new google.maps.Point(20, 20),
                  }}
                  onClick={handlePickupMarkerClick}
                />
              )
            }
          })
        }
        {!props.points && allDeliveries?.length > 0 &&
          allDeliveries.map((delivery, index) => {
            return delivery?.tasks?.map((task) => { // Add return statement here
              return (
                <Marker
                  position={{ lat: Number(task?.drop_address?.lat), lng: Number(task?.drop_address?.long) }}
                  label={{
                    text: `D`, color: "#FFFFFF", fontSize: "16px",
                    labelOrigin: new google.maps.Point(0, 0)
                  }}
                  data={task}
                  icon={{
                    url: task?.task_status === "UNASSIGNED" ? redIcon : task?.task_status !== "DELIVERED" ? blueIcon : greenIcon,
                    // Assume we're working with an icon size of 40x40
                    scaledSize: new google.maps.Size(40, 40),
                    // Set the anchor to the middle of your icon image
                    anchor: new google.maps.Point(20, 20),
                  }}
                  onClick={handleDropMarkerClick}
                />
              )
            })
          })
        }
        {brangersMarker?.length && brangersMarker.map((loc, index) => {
          return (
            <Marker
              icon={{
                url: deliveryVan,
                fillColor: '#EB00FF',
                scale: 7,
              }}
              position={{ lat: loc?.lat, lng: loc?.long }}
              data={loc}
              onClick={handleMarkerClick}
            />
          )
        })}

        <InfoWindow position={selectedPickupDropMarkerPosition}
          onClose={() => {
            setSelectedPickupDropMarkerPosition(null);
            setShowPickupDropInfoWindow(!showPickupDropInfoWindow);
          }}
          visible={showPickupDropInfoWindow}>
          {selectedMarkerETA && <Box>
            <Typography variant="body1">{`${selectedMarkerETA ? "ETA:" : ""} ${selectedMarkerETA}`}</Typography>
          </Box>}
          {
            selectedpickupDropDetails &&
            <Box>
              <Typography variant="body1">Address: {selectedpickupDropDetails?.pickup_address_details?.address}{selectedpickupDropDetails?.drop_address?.address}</Typography>
              <Typography variant="body1">Order ID: #{selectedpickupDropDetails?.order_id ? selectedpickupDropDetails?.order_id : selectedpickupDropDetails?.id}</Typography>
              {selectedpickupDropDetails?.task_id && <Typography variant="body1">Task ID: #{selectedpickupDropDetails?.task_id}</Typography>}
              <Typography variant="body1">{selectedpickupDropDetails?.order_status ? "Order Status:" : "Task Status:"} {selectedpickupDropDetails?.order_status ? selectedpickupDropDetails?.order_status : selectedpickupDropDetails?.task_status}</Typography>
              <Typography variant="body1">Branger: {selectedpickupDropDetails?.branger_first_name}</Typography>
            </Box>
          }
        </InfoWindow>
        <InfoWindow position={selectedMarkerPositon}
          onClose={() => {
            setSelectedMarkerPosition(null);
            setShowInfoWindow(!showInfoWindow);
            props.setIsBrangerDetailsOpen(false);
          }}
          visible={showInfoWindow}>
          <Box>
            {
              selectedBrangerDetails ?
                <>
                  <Box>{selectedBrangerDetails?.first_name} {selectedBrangerDetails?.last_name}</Box>
                  <Box>{selectedBrangerDetails?.address}</Box>
                </>
                :
                <Box>{isLoading && "Loading..."}</Box>
            }
          </Box>
        </InfoWindow>
      </Map>
    </Box>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDp8kshAwl2VeZe4lSseBVFhYVNQpVXyt0'
})(MapContainer);