import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import CustomTable from '../../theme/style-components/CustomTable';
import LottieLoader from '../loader/LottieLoader';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import ComponentTitle from '../../shared/ComponentTitle';
import TabTitle from '../../shared/TabTitle';
import { selectExternalTransactions, setExternalTransactions } from '../../store/external-transaction/externalTransationsSlice';
import { useDeleteExternalTransactionMutation, useGetExternalTransactionsQuery } from '../../store/external-transaction/externalTransactionsApiSlice';

const ExternalTransactions = () => {
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [count, setCount] = useState();
    const [transactionsToDisplay, setTransactionsToDisplay] = useState();
    const [selectedExternalTransactions, setSelectedExternalTransactions] = useState();
    const [isModalOpen, setIsModalOpen] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [query, setQuery] = useState("");
    const [searchText, setSearchText] = useState("");

    const transaction = useSelector(selectExternalTransactions);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [deleteExternalTransaction, { isDeleteLoading }] = useDeleteExternalTransactionMutation();

    const {
        isLoading,
        data: dataReturned,
        isSuccess,
        isError,
        error,
        refetch: refetchTransactions
    } = useGetExternalTransactionsQuery({ page: page, query: query });

    //hooks
    useEffect(() => {
        if (!isLoading) {
            dispatch(setExternalTransactions({
                externalTransactions: dataReturned?.data,
                totalRecord: dataReturned?.page_details?.count
            }));
        };
    }, [isLoading, page, dataReturned?.data, query]);

    //hooks
    useEffect(() => {
        if (transaction) {
            setTransactionsToDisplay(transaction?.externalTransactions);
            setCount(transaction?.totalRecord)
        }
    }, [transaction]);


    const setSelectedData = (item) => {
        setSelectedExternalTransactions(item)
    };

    //handle page function
    const onPageChange = (value) => {
        setPage(value)
    };

    //handle row page function
    const onRowsPerPageChange = (value) => {
        setRowsPerPage(value)
    };

    const columns = [
        { id: 'id', label: 'ID' },
        { id: 'purpose', label: 'Purpose' },
        { id: 'completed_using', label: 'Completed Using' },
        { id: 'amount', label: 'Amount' },
        { id: 'note', label: 'Note' },
        { id: 'action', label: 'Action' },
    ];

    const externalTransactionsData = transactionsToDisplay?.length > 0 ? transactionsToDisplay.map((data) => ({
        id: data.id,
        purpose: data.purpose,
        completed_using: data.completed_using,
        amount: `AUD ${(data?.amount_cents / 100).toFixed(2)}`,
        note: data.note,
        action: "ACTION"
    })) : []

    const handleCreateClick = () => {
        navigate("/external-transactions/create")
    };

    const handleEditClick = () => {
        navigate(`/external-transactions/${selectedExternalTransactions?.id}`)
    };

    const handleDeleteClick = async () => {
        swal({
            title: 'Are you sure?',
            text: 'This action cannot be undone.',
            icon: 'warning',
            buttons: {
                cancel: 'Cancel',
                confirm: 'Delete',
            },
            dangerMode: true,
        }).then(async (confirmed) => {
            if (confirmed) {
                try {
                    const res = deleteExternalTransaction(selectedExternalTransactions.id).then((res) => {
                        if (res?.data?.message === "transaction deleted!") {
                            toast.success(`Transaction deleted successfully`, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                            refetchTransactions();
                        };
                    });

                } catch (err) {
                    toast.error(err.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                };
            } else {
                // User clicked the 'Cancel' button
                // Handle the cancellation
            }
        });
    };

    const handleOnSearch = (event) => {
        const newSearchText = event.target.value;
        setSearchText(newSearchText);
        setPage(1);

        let newQuery = query;

        // Remove existing search parameters from the query
        newQuery = newQuery.replace(/&search=[^&]*/g, '');

        // Add new search parameter if search text is not empty
        if (newSearchText !== '') {
            newQuery += `& search=${encodeURIComponent(newSearchText)} `;
        }

        setQuery(newQuery);
    };

    const breadcrumbs = [
        <Typography key="2" variant="body1">
            External Transactions
        </Typography>,
    ];

    return (
        <Box>
            <TabTitle title="External Transactions" />
            <ComponentTitle breadcrumbs={breadcrumbs} />
            {!isLoading && count !== undefined ? (
                <>
                    <CustomTable
                        //pagination props
                        title="Branger"
                        page={page}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        onPageChange={onPageChange}
                        onRowsPerPageChange={onRowsPerPageChange}

                        //create button props
                        handleCreateClick={handleCreateClick}
                        createButtonName="Create Transactions"

                        //search props
                        isShowSearch={true}
                        handleOnSearch={handleOnSearch}
                        searchText={searchText}
                        searchPlaceholder="Search"

                        //delete menu props
                        isShowDeleteMenu={true}
                        handleDelteClick={handleDeleteClick}

                        //edit menu props
                        isShowEditMenu={true}
                        handleEditClick={handleEditClick}


                        //action props
                        isActionVisible={true}

                        //data props
                        data={externalTransactionsData}
                        columns={columns}

                        //set Selected row data
                        setSelectedData={setSelectedData}
                    />
                </>
            ) : <Box>
                <LottieLoader />
            </Box>}
        </Box>
    )
}

export default ExternalTransactions;
