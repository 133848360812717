import React from 'react'
import CreateOrder from './CreateOrder'
import OrderPackages from './OrderPackages'

const EditOrder = () => {
    return (
        <>
            <CreateOrder />
            <OrderPackages />
        </>

    )
}

export default EditOrder
