import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Layout from "../view/layout/Layout";
import { useDispatch } from "react-redux";
import {
  setCredentials,
  setUser,
  setUserID,
  setUserType,
  logOut,
  selectCurrentUserObject,
} from "../store/auth/authSlice";
import { useGetUserProfileQuery } from "../store/users/usersApiSlice";
import { useEffect } from "react";
import { decryptFn, encryptFn } from "../shared/helperFunction";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children, permissions }) => {
  const token = decryptFn(localStorage.getItem("accessToken"));
  const userType = localStorage.getItem("userType");
  let user = useSelector(selectCurrentUserObject);
  let access = false;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  if (!userType) {
    dispatch(logOut());
    dispatch(setUserType(null));
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    navigate("/login", { state: { from: location }, replace: true });
    localStorage.clear();
  }

  if (permissions?.length > 0 && userType) {
    if (permissions?.includes(userType)) {
      access = true;
    }
  } if (permissions?.length === 0 && user.is_fredit) {
    access = true;
  }

  const { isLoading, data, isSuccess, isError, error } =
    useGetUserProfileQuery();

  useEffect(() => {
    // Dispatch actions and update local storage within this effect
    if (isLoading) return;

    dispatch(
      setCredentials({
        access: encryptFn(localStorage.getItem("accessToken")),
        refresh: encryptFn(localStorage.getItem("refreshToken")),
        email: localStorage.getItem("email"),
        first_name: localStorage.getItem("first_name"),
        last_name: localStorage.getItem("last_name"),
      })
    );

    if (isSuccess) {
      dispatch(setUserType(data?.data.user_type));
      dispatch(setUserID(data?.data.id));
      dispatch(setUser(data?.data));
      localStorage.setItem("first_name", data?.data.first_name);
      localStorage.setItem("last_name", data?.data.last_name);
    }
  }, [isLoading, isSuccess, data, dispatch]);

  return token ? (
    access ? (
      <Layout>{children}</Layout>
    ) : (
      <Navigate state={{ from: location }} to="/dashboard" replace={true} />
    )
  ) : (
    <Navigate to="/login" replace />
  );
};

export default PrivateRoute;
