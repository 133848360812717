import React, { useEffect, useState } from 'react'
import { Box, Grid, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useGetAddressBookByNameQuery } from '../../store/address-book/addressBookApiSlice';
import CustomButton from '../../theme/style-components/CustomButton';
import CustomInput from '../../theme/style-components/CustomInput';
import LottieLoader from '../loader/LottieLoader';

const AddressBookForm = (props) => {
    const { setFilledAddress, setIsAddressBookOpen } = props;
    const [addressList, setAddressList] = useState([]);
    const [searchedName, setSearchedName] = useState('');

    const {
        isLoading,
        data: dataReturned,
        isSuccess,
        isError,
        error,
        refetch: refetchAddressBook
    } = useGetAddressBookByNameQuery({ search: searchedName });

    useEffect(() => {
        if (!isLoading && isSuccess) {
            setAddressList(dataReturned?.data);
        }
    }, [dataReturned?.data, isLoading, isSuccess, searchedName]);

    return (
        <Grid item xs={12} md={12}>
            <Box>
                <CustomInput
                    value={searchedName}
                    type="search"
                    label="Search Address/Name"
                    id="standard-adornment-amount"
                    startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                    }
                    margin="normal"
                    onChange={(e) => setSearchedName(e.target.value)} />
            </Box>
            <Box sx={{
                '& table': {
                    width: '100%',
                    marginTop: '20px',
                    borderRadius: '5px',
                    borderCollapse: 'collapse',
                },
                '& th,td': {
                    border: '1px solid #dbdbeb61',
                    padding: '5px 10px',
                    color: "#e0e0e0"
                }
            }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Note</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? <TableRow sx={{ textAlign: "center" }}>
                            <TableCell sx={{ textAlign: "center" }} colSpan={12}><LottieLoader /></TableCell></TableRow> : addressList?.length > 0 ? addressList.map((item) => {
                                return (
                                    <TableRow key={item.id}>
                                        <TableCell>{item?.contact_name}</TableCell>
                                        <TableCell>{item?.contact_email}</TableCell>
                                        <TableCell>{item?.contact_phone}</TableCell>
                                        <TableCell>{item?.drop_address?.address}</TableCell>
                                        <TableCell>{item?.drop_address?.note ? item?.drop_address?.note : "NA"}</TableCell>
                                        <TableCell><CustomButton color="primary" buttonName="Use" onClick={() => {
                                            setFilledAddress(item);
                                            setIsAddressBookOpen(false);
                                        }} /></TableCell>
                                    </TableRow>
                                )
                            }) : <TableRow >
                            <TableCell sx={{ textAlign: "center" }} colSpan={12}>Not selected</TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </Box>
        </Grid>
    )
}

export default AddressBookForm
