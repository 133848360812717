import React from 'react';
import Lottie from 'react-lottie';
import animationData from './small-delivery.json';
import { Box } from "@mui/material";

const LottieLoader = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData, // Your imported Lottie animation JSON
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <Box>
            <Lottie height={300}
                width={300}
                options={defaultOptions} />
        </Box>
    );
};

export default LottieLoader;