
import {
    Box, Grid,
    Select,
    TextField,
    FormControlLabel,
    Checkbox as CheckBox,
    MenuItem,
    Button,
    InputLabel,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { useGetSubscriptionsQuery } from "../../store/payments/paymentsApiSlice";
import { usePutSubscriptionMutation } from "../../store/users/usersApiSlice";
import CustomSelect from "../../theme/style-components/CustomSelect";
import CustomCheckBox from "../../theme/style-components/CustomCheckBox";
import CustomInput from "../../theme/style-components/CustomInput";
import CustomButton from "../../theme/style-components/CustomButton";
import { showError } from "../../shared/helperFunction";

const emptySubscription = {
    id: 0,
    name: "",
};

const MerchantSubscriptions = (props) => {
    //state variables
    const [isCustomInvoice, setIsCustomInvoice] = useState(false);
    const [subscriptionType, setSubscriptionType] = useState('');
    const [isDeliveriesRest, setIsDeliveriesRest] = useState(false);
    const [deliveryBalance, setDeliveryBalance] = useState(null);
    const [error, setError] = useState(null);

    //base query hooks
    const {
        isLoading,
        data: dataReturned,
        isSuccess,
        isError,
    } = useGetSubscriptionsQuery();

    const [putSubscription, { isLoading: isPutSubscriptionLoading }] =
        usePutSubscriptionMutation();

    const validate = () => {
        let isInvalid = false;
        let errorNew = {};

        if (subscriptionType == '') {
            errorNew.subscriptionType = {
                isError: true,
                text: "Please select subscription type",
            };

            isInvalid = true;
        }

        if (isInvalid) {
            setError(errorNew);
            return true;
        } else {
            return false;
        }
    }

    //methods
    const handleSubscriptionSubmit = async () => {
        if (!validate()) {
            try {
                const data = {
                    address_id: props.addressID,
                    subscription_id: subscriptionType,
                    reset_deliveries: isDeliveriesRest,
                    delivery_balance: Number(deliveryBalance),
                    custom_invoice: isCustomInvoice,
                }

                if (deliveryBalance === null) {
                    delete data["delivery_balance"];
                };
                const response = await putSubscription(data);
                props.refetchAddress()
                props.setShowSubscriptionDialog(prev => !prev)
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } catch (error) {
                showError(error?.data)
            }
        }
    };

    return (
        <>
            <Grid item xs={12} md={6}>
                <Box sx={{
                    textAlign: 'start'
                }}>
                    {!isError && !isLoading
                        && dataReturned?.data?.length &&
                        <CustomSelect
                            id="subscriptionType"
                            name="subscriptionType"
                            value={subscriptionType}
                            onChange={(e) => {
                                setError(null)
                                setSubscriptionType(e.target.value)
                            }}
                            label="Subscription Type"
                            error={error != null && error.subscriptionType && error.subscriptionType.isError}
                            helperText={
                                error != null &&
                                error.subscriptionType &&
                                error.subscriptionType.isError &&
                                error.subscriptionType.text
                            }
                        >
                            {dataReturned.data.map((subscription) => (
                                <MenuItem value={subscription.id}>
                                    {subscription.name}
                                </MenuItem>
                            ))}
                        </CustomSelect>}
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box>
                    <CustomCheckBox
                        checked={isCustomInvoice} id="custom-invoice" onChange={() => {
                            setIsCustomInvoice((prevState) => !prevState);
                        }} name="custom_invoice" label="Custom Invoice"
                        value={isCustomInvoice} />
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box>
                    <CustomInput
                        value={deliveryBalance}
                        onChange={(e) => setDeliveryBalance(e.target.value)}
                        type="number"
                        label="Delivery Balance"
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box>
                    <CustomCheckBox
                        id="isDeliveriesRest" name="isDeliveriesRest"
                        label="Reset Deliveries"
                        value={isDeliveriesRest}
                        checked={isDeliveriesRest}
                        onChange={() => {
                            setIsDeliveriesRest((prevState) => !prevState);
                        }}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box>
                    <CustomButton buttonName="Submit" isLoading={isPutSubscriptionLoading} disabled={isPutSubscriptionLoading} type="submit" color="primary"
                        onClick={handleSubscriptionSubmit}
                    />
                </Box>
            </Grid>
        </>
    );
};
export default MerchantSubscriptions;