import { apiSlice } from "../apiSlice";

export const freditApiKeySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFreditAPIKey: builder.query({
      query: () => `/fredit/api_key/`,
      keepUnusedDataFor: 5,
    }),
    getFreditOrders: builder.query({
      query: ({ page, query }) => `/fredit/get_buffer_orders/?page=${page}${query}`,
      keepUnusedDataFor: 5,
    }),
    postFreditAPIKey: builder.mutation({
      query: (data) => ({
        url: "/fredit/api_key/",
        method: "POST",
        body: data,
      }),
    }),
    postCreateProcess: builder.mutation({
      query: (data) => ({
        url: "/fredit/process_buffer/",
        method: "POST",
        body: data,
      }),
    }),
    deleteAPIKey: builder.mutation({
      query: (id) => ({
        url: `/fredit/api_key/?id=${id}`,
        method: "DELETE",
      }),
    }),
    deleteBufferOrder: builder.mutation({
      query: (id) => ({
        url: `/fredit/process_buffer/?ids=${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useLazyGetFreditAPIKeyQuery, usePostFreditAPIKeyMutation, useDeleteAPIKeyMutation, useGetFreditOrdersQuery, useDeleteBufferOrderMutation, usePostCreateProcessMutation } =
  freditApiKeySlice;