import { apiSlice } from "../apiSlice"

export const addressBookApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAddressBooks: builder.query({
            query: ({ page, query }) => `/user/merchant_address_book/?page=${page}${query}`,
            keepUnusedDataFor: 0,
        }),
        getSingleAddressBook: builder.query({
            query: (ID) => `/user/merchant_address_book/?address_id=${ID}`,
            keepUnusedDataFor: 0,
        }),
        createAddressBook: builder.mutation({
            query: (data) => ({
                url: '/user/merchant_address_book/',
                method: 'POST',
                body: data,
            })
        }),
        updateAddressBook: builder.mutation({
            query: (data) => ({
                url: '/user/merchant_address_book/',
                method: 'PUT',
                body: data,
            })
        }),
        deleteAddressBook: builder.mutation({
            query: (id) => ({
                url: `/user/merchant_address_book/?address_id=${id}`,
                method: "DELETE",
            }),
        }),
        getAddressBookByName: builder.query({
            query: ({ search }) => `/user/merchant_address_book/?search=${search}`,
            keepUnusedDataFor: 0,
        }),
    })
});

export const {
    useGetAddressBooksQuery,
    useLazyGetSingleAddressBookQuery,
    useCreateAddressBookMutation,
    useUpdateAddressBookMutation,
    useDeleteAddressBookMutation,
    useGetAddressBookByNameQuery,
} = addressBookApiSlice;