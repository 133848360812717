import { FormControlLabel, Radio } from '@mui/material'
import React from 'react'

const CustomRadio = ({ label, value }) => {
    return (
        <FormControlLabel sx={{
            color: "#dbdbebde",
            "& span": {
                "& svg": {
                    width: '18px',
                    height: '18px',
                    fill: "rgba(219, 219, 235, 0.38)",
                },
                "&:hover": {
                    backgroundColor: "transparent",
                },
                "&.Mui-checked": {
                    "& svg": {
                        fill: "#696cff",
                        backgroundColor: '#696cff',
                        borderRadius: "100px",
                        width: '18px',
                        height: '18px',
                        '&:nth-child(2)': {
                            display: 'none'
                        }
                    },
                },
            },
        }}
            value={value}
            control={<Radio />}
            label={label}
        />
    )
}

export default CustomRadio
