import React, { useEffect, useRef, useState } from 'react'
import CustomTabs from '../../theme/style-components/CustomTabs'
import { Avatar, Box, Button, Grid, IconButton, Typography } from '@mui/material';
import CustomPaperBox from '../../theme/style-components/CustomPaperBox';
import CustomInput from '../../theme/style-components/CustomInput';
import { useFormik } from "formik";
import * as yup from "yup";
import CustomButton from '../../theme/style-components/CustomButton';
import { usePostUsersMutation, usePutUsersMutation } from '../../store/users/usersApiSlice';
import { useDeleteMerchantAddressMutation, useGetMerchantAddressWitUserIdQuery, useOnBoardMerchantMutation } from '../../store/merchant/merchantApiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUserID, selectCurrentUserObject, selectCurrentUserType, setUser } from '../../store/auth/authSlice';
import { toast } from 'react-toastify';
import { deleteAddressFromState } from '../../store/merchant/merchantSlice';
import AddressList from './AddressList';
import MerchantAddress from './MerchantAddress';
import CustomModal from '../../theme/style-components/CustomModal';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TabTitle from '../../shared/TabTitle';
import { useDeleteAPIKeyMutation, useLazyGetFreditAPIKeyQuery, usePostFreditAPIKeyMutation } from '../../store/api-keys-fredit/freditApiKeySlice';
import moment from 'moment';
import swal from 'sweetalert';
import CustomTable from '../../theme/style-components/CustomTable';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';

const phoneRegex =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const validationSchema = yup.object({
    firstName: yup
        .string("Enter first name")
        .min(3, "First name must contain 3 characters minimum")
        .required("First Name is required")
        .max(50, "First name can contain a maximum of 50 characters"),
    lastName: yup
        .string("Enter last name")
        .min(3, "Last name must contain 3 characters minimum")
        .required("Last Name is required")
        .max(50, "Last name can contain a maximum of 50 characters"),
    email: yup
        .string("Enter mail")
        .email("Enter a valid email")
        .required("Email address is required"),
    phoneNumber: yup
        .string("Enter a phone number")
        .matches(phoneRegex, "Enter a valid phone number")
        .min(10, "Too short")
        .required("Phone number is required"),
});

const validationSchemaAPIKeys = yup.object({
    api_key_name: yup
        .string("Enter API key name")
        .min(3, "API key name must contain 3 characters minimum")
        .required("API key name is required")
        .max(50, "API key name can contain a maximum of 50 characters"),
});


const Profile = () => {
    const merchantID = useSelector(selectCurrentUserID);
    let user = useSelector(selectCurrentUserObject);
    const userType = useSelector(selectCurrentUserType);
    //state variables
    const [activeTab, setActiveTab] = useState(0);
    const [avatarPreview, setAvatarPreview] = useState(null);
    const [ID, setID] = useState(merchantID ? merchantID : null);
    const [tabsData, setTabsData] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const [addressID, setAddressID] = useState(0);
    const [showMerchantDialog, setShowMerchantDialog] = useState(false);
    const [isShowCreateAPIDialog, setIsShowCreateDialog] = useState(false);
    const [createdAPIkeyData, setCreatedAPIkeyData] = useState({});
    const [query, setQuery] = useState("");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [count, setCount] = useState();
    const [APIKeysData, setAPIKeysData] = useState([])
    const [selectedAPIKey, setSelectedAPIKey] = useState({});

    const [deleteAPIKey] = useDeleteAPIKeyMutation();
    const [getFreditAPIKey, {
        isLoading: isGetAPIKeyLoading,
        data: getAPIKeyReturned,
    }] = useLazyGetFreditAPIKeyQuery();

    //hook
    const dispatch = useDispatch();

    //ref variables
    const inputRef = useRef(null);

    useEffect(() => {
        if (merchantID) {
            setID(merchantID)
        }
    }, [merchantID])

    //variables
    let newUserID = 0;

    useEffect(() => {
        if (user != null) {
            formik.setFieldValue("firstName", user.first_name);
            formik.setFieldValue("lastName", user.last_name);
            formik.setFieldValue("phoneNumber", user.phone);
            formik.setFieldValue("email", user.email);
            formik.setFieldValue("businessName", user?.merchant_details?.business_name);
            setAvatarPreview(user.dp)
        }
    }, [user]);

    useEffect(() => {
        const getAPIkeys = async () => {
            try {
                const response = await getFreditAPIKey().unwrap().then((res) => {
                    setAPIKeysData(res?.data);
                    setCount(res?.data?.length);
                    setRowsPerPage(res?.data?.length);
                }).catch((err) => {
                    console.log(err);
                })
            } catch (err) {
                toast.error("Something went wrong!!", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        };

        if (userType !== "ADMIN") {
            getAPIkeys();
        }
    }, [userType, query]);

    const getAPIkeys = async () => {
        try {
            const response = await getFreditAPIKey().unwrap().then((res) => {
                setAPIKeysData(res?.data);
                setCount(res?.data?.length);
                setRowsPerPage(res?.data?.length);
            }).catch((err) => {
                console.log(err);
            })
        } catch (err) {
            toast.error("Something went wrong!!", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    };


    const { isLoading: isAddressLoading, data: addressData, isError: isAddressError, refetch: refetchAddress } =
        useGetMerchantAddressWitUserIdQuery({ userID: merchantID });

    const [postUsers, { isLoading: isPostUserLoading }] = usePostUsersMutation();
    const [onBoardMerchant, { isOnboardLoading }] = useOnBoardMerchantMutation();
    const [putUsers, { isLoading: isPutUserLoading }] = usePutUsersMutation();
    const [deleteAddress, { isDeleteMerchantAddressLoading }] =
        useDeleteMerchantAddressMutation();

    const [postFreditAPIKey, { isLoading: isCreateAPILoading }] = usePostFreditAPIKeyMutation();

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    //formik variables
    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            dp: ""
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            let formData = new FormData();
            let user = {
                user_type: userType === "ADMIN" ? "ADMIN" : "MERCHANT",
                email: values.email,
                first_name: values.firstName,
                last_name: values.lastName,
                phone: values.phoneNumber,
                // password: values.password,
                dp: values.dp,
            };
            Object.keys(user).forEach((key) => {
                formData.append(key, user[key]);
            });
            for (var key of formData.entries()) {
                console.log(key[0] + ", " + key[1]);
            }

            if (ID === null) {
                try {
                    const response = await postUsers(formData);
                    setID(response.data.data.id);
                    newUserID = response.data.data.id;
                    toast.success(`User Created Successfully! with ID ${newUserID}`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } catch (error) {
                    toast.error(`${error}`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            } else {
                formData.append("user_id", ID);
                try {
                    const response = await putUsers(formData).unwrap().then((res) => {
                        if (res.data) {
                            dispatch(setUser(res.data));
                            toast.success(`User Updated Successfully! with ID ${ID}`, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }
                    }).catch((err) => {
                        if (err) {
                            toast.error(`Something went wrong!`, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }
                    })
                } catch (error) {
                    toast.error(`${error}`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    console.log(error);
                }
            }
        },
    });

    //methods
    const handleFileChange = (event) => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
            if (fileReader.readyState === 2) {
                setAvatarPreview(fileReader.result);
                formik.setFieldValue("dp", event.target.files[0])
            }
        };
        fileReader.readAsDataURL(event.target.files[0]);
    };

    const formikSecondary = useFormik({
        initialValues: {
            businessName: user?.merchant_details?.business_name ? user?.merchant_details?.business_name : "",
            abn_number: user?.merchant_details?.abn_number ? user?.merchant_details?.abn_number : "",
        },
        enableReinitialize: true,
        onSubmit: async (values) => {
            const merchant = {
                user_id: ID,
                business_name: values.businessName,
            };
            try {
                await onBoardMerchant(merchant).unwrap();
                toast.success("Merchant Onboarded Successfully!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } catch (error) {
                console.log(error, "error");
                if (error) {
                    toast.error(error.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            }
        },
    });

    const formikAPIkeys = useFormik({
        initialValues: {
            api_key_name: "",
        },
        validationSchema: validationSchemaAPIKeys,
        enableReinitialize: true,
        onSubmit: async (values) => {
            const data = {
                name: values.api_key_name,
            };

            try {
                await postFreditAPIKey(data).unwrap().then((res) => {
                    if (res?.data) {
                        setCreatedAPIkeyData(res?.data);
                        toast.success("API key created Successfully!", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setIsShowCreateDialog(false);
                        getAPIkeys();
                    }
                })

            } catch (error) {
                if (error) {
                    if (error?.data?.message) {
                        toast.error(error?.data?.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                    if (error?.error) {
                        toast.error(error?.error, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }
            }
        },
    });

    useEffect(() => {
        if (userType === "ADMIN") {
            const tabsData = [
                { label: 'Profile' },
            ];
            setTabsData(tabsData)
        } else {
            const tabsData = [
                { label: 'Profile' },
                { label: 'Merchant Details' },
                { label: 'Shops' },
                { label: 'API keys' },
                // Add more tabs as needed
            ];
            setTabsData(tabsData)
        }
    }, [userType]);

    const editMerchantAddress = (addressID) => {
        setAddressID(addressID);
        setShowMerchantDialog(true);
    };

    const deleteMerchantAddress = async (deleteID) => {
        var answer = window.confirm(
            "Are you sure you want to delete the selected address?"
        );
        if (answer) {
            try {
                const response = await deleteAddress(deleteID).unwrap().then((res) => {
                    if (res) {
                        setIsOpen(false)
                        dispatch(deleteAddressFromState(deleteID))
                    }
                }).catch((err) => {
                    if (err) {
                        toast.error(`${err?.data?.message}`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                })
            } catch (error) {
                console.log(error);
            }
        } else {
            //some code
        }
    };

    const handleClose = () => {
        setShowMerchantDialog(false);
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(createdAPIkeyData?.key)
            .then(() => {
                toast.success(`Copied to clipboard!`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
            .catch((err) => {
                console.error('Failed to copy: ', err);
            });
    };

    const tableAPIKeysData = APIKeysData?.length > 0 ? APIKeysData.map((data) => ({
        id: data.id,
        created_at: moment.utc(data.expiry_date).format('DD MMMM YYYY HH:mm'),
        name: data.name,
        key_last5: data.key_last5,
        expiry_date: moment.utc(data.expiry_date).format('DD MMMM YYYY HH:mm'),
        delete: <CustomButton buttonName="Delete" color="error" onClick={() => handleDelteClick(data.id)} />
    })) : [];

    const columns = [
        { id: 'created_at', label: 'Created At' },
        { id: 'name', label: 'Name' },
        { id: 'key_last5', label: 'Key' },
        { id: 'expiry_date', label: 'Expiry Date' },
        { id: 'delete', label: 'Actions' },
    ];

    //handle page function
    const onPageChange = (value) => {
        setPage(value)
    };

    //handle row page function
    const onRowsPerPageChange = (value) => {
        setRowsPerPage(value)
    };

    const setSelectedData = (item) => {
        setSelectedAPIKey(item)
    };

    const handleDelteClick = (selectedID) => {
        console.log(selectedID, "selectedID");
        const id = selectedID
        swal({
            title: 'Are you sure?',
            text: 'This action cannot be undone.',
            icon: 'warning',
            buttons: {
                cancel: 'Cancel',
                confirm: 'Delete',
            },
            dangerMode: true,
        }).then(async (confirmed) => {
            if (confirmed) {
                const response = await deleteAPIKey(id).unwrap().then((res) => {
                    getAPIkeys();
                    res && toast.success("API key deleted!!", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }).catch((err) => {
                    if (err) {
                        if (err?.data?.message) {
                            toast.error(err?.data?.message, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }
                        if (err?.error) {
                            toast.error(err?.error, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }
                    }
                });
            } else {
                // User clicked the 'Cancel' button
                // Handle the cancellation
            }
        });
    };

    return (
        <Box>
            <TabTitle title="Profile" />
            <CustomTabs tabsData={tabsData}
                activeTab={activeTab}
                onChange={handleTabChange} >
                {/* tab 1 */}
                <CustomPaperBox>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Button
                                onClick={() => {
                                    inputRef.current.click();
                                }}
                            >
                                <Avatar
                                    sx={{
                                        height: "80px", width: "80px", marginBottom: '20px',
                                        "& img": {
                                            objectFit: "contain"
                                        }
                                    }}
                                    src={avatarPreview ? avatarPreview : null}
                                />
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <input
                                name="dp"
                                ref={inputRef}
                                style={{ display: "none" }}
                                accept="image/*"
                                id="contained-button-file"
                                type="file"
                                onChange={handleFileChange}
                            />
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Box>
                                <CustomInput label="First Name"
                                    name="firstName"
                                    error={
                                        formik.touched.firstName &&
                                        Boolean(formik.errors.firstName)
                                    }
                                    helperText={
                                        formik.touched.firstName && formik.errors.firstName
                                    }
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.firstName} />
                            </Box>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Box>
                                <Box>
                                    <CustomInput label="Last Name"
                                        name="lastName"
                                        error={
                                            formik.touched.lastName &&
                                            Boolean(formik.errors.lastName)
                                        }
                                        helperText={
                                            formik.touched.lastName && formik.errors.lastName
                                        }
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.lastName} />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Box>
                                <Box>
                                    <CustomInput label="Phone Number"
                                        name="phoneNumber"
                                        error={
                                            formik.touched.phoneNumber &&
                                            Boolean(formik.errors.phoneNumber)
                                        }
                                        helperText={
                                            formik.touched.phoneNumber && formik.errors.phoneNumber
                                        }
                                        disabled={userType === "ADMIN" ? true : false}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.phoneNumber} />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Box>
                                <Box>
                                    <CustomInput label="Email"
                                        name="email"
                                        error={
                                            formik.touched.email &&
                                            Boolean(formik.errors.email)
                                        }
                                        helperText={
                                            formik.touched.email && formik.errors.email
                                        }
                                        disabled={true}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.email} />
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Box sx={{ textAlign: "start" }}>
                                <CustomButton buttonName="Submit" isLoading={isPutUserLoading || isPostUserLoading} disabled={isPutUserLoading || isPostUserLoading} color="primary" onClick={formik.handleSubmit} />
                            </Box>
                        </Grid>
                    </Grid>
                </CustomPaperBox>

                {/* tab 2 */}
                <CustomPaperBox>
                    <Grid container spacing={2}>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Box>
                                <CustomInput label="Bussiness Name"
                                    name="businessName"
                                    disabled={userType === "MERCHANT" ? true : false}
                                    error={
                                        formikSecondary.touched.businessName &&
                                        Boolean(formikSecondary.errors.businessName)
                                    }
                                    helperText={
                                        formikSecondary.touched.businessName && formikSecondary.errors.businessName
                                    }
                                    onBlur={formikSecondary.handleBlur}
                                    onChange={formikSecondary.handleChange}
                                    value={formikSecondary.values.businessName} />
                            </Box>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Box>
                                <CustomInput label="ABN/ACN Number"
                                    name="abn_number"
                                    error={
                                        formikSecondary.touched.abn_number &&
                                        Boolean(formikSecondary.errors.abn_number)
                                    }
                                    helperText={
                                        formikSecondary.touched.abn_number && formikSecondary.errors.abn_number
                                    }
                                    disabled={userType === "MERCHANT" ? true : false}
                                    onBlur={formikSecondary.handleBlur}
                                    onChange={formikSecondary.handleChange}
                                    value={formikSecondary.values.abn_number} />
                            </Box>
                        </Grid>
                        {userType !== "MERCHANT" && <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Box sx={{ textAlign: "start" }}>
                                <CustomButton buttonName="Submit" color="primary" onClick={formikSecondary.handleSubmit} />
                            </Box>
                        </Grid>}
                    </Grid>
                </CustomPaperBox>

                {/*tab 3 */}
                <CustomPaperBox>
                    <Box sx={{ justifyContent: "center", display: "flex" }}>
                        <IconButton
                            sx={{
                                color: "#696cff"
                            }}
                            onClick={() => {
                                setAddressID(0);
                                setShowMerchantDialog((prevState) => !prevState);
                            }}
                        >
                            <AddCircleIcon fontSize="large" />
                        </IconButton>
                    </Box>
                    <AddressList
                        merchantID={ID}
                        isAddressLoading={isAddressLoading}
                        addressData={addressData}
                        isAddressError={isAddressError}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        refetchAddress={refetchAddress}
                        editMerchantAddress={editMerchantAddress}
                        deleteMerchantAddress={deleteMerchantAddress} />
                    <CustomModal handleClose={handleClose} isShowActionButton={false} isModalOpen={showMerchantDialog} title={addressID === 0 ? "Add Address" : "Edit Address"}>
                        <MerchantAddress
                            title={addressID === 0 ? "Add Address" : "Edit Address"}
                            userEditing={false}
                            merchantID={ID}
                            setIsOpen={setIsOpen}
                            refetchAddress={refetchAddress}
                            addressID={addressID}
                            setShowMerchantDialog={setShowMerchantDialog}
                        />
                    </CustomModal>
                </CustomPaperBox>

                <Box>
                    {createdAPIkeyData?.key &&
                        <Box sx={{
                            background: "#343148",
                            padding: "10px 20px 10px 10px",
                            color: "white",
                            borderRadius: "6px",
                            marginTop: "30px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "end"
                        }}>
                            <Box>
                                <Typography sx={{
                                    padding: "5px",
                                    color: "#eb505a",
                                }}>Please note that this key is visible only once. We kindly advise you to copy it or securely store it for future reference.</Typography>
                                <Typography sx={{
                                    padding: "5px", wordBreak: "break-all"
                                }}>API Key: {createdAPIkeyData?.key}</Typography>
                            </Box>
                            <Box>
                                <IconButton onClick={handleCopy} sx={{ color: "#fff" }}><ContentCopyIcon /></IconButton>{/* <CustomButton color="primary" onClick={handleCopy} buttonName={`${isCopied ? "Copied!" : "Copy Key"}`} startIcon={isCopied ? <DoneIcon /> : <ContentCopyIcon />} /> */}
                            </Box>
                        </Box>
                    }
                    <>
                        <CustomTable
                            //pagination props
                            title="API keys"
                            page={page}
                            count={count}
                            rowsPerPage={rowsPerPage}
                            onPageChange={onPageChange}
                            onRowsPerPageChange={onRowsPerPageChange}

                            //create button props
                            handleCreateClick={() => {
                                setIsShowCreateDialog(true);
                                formikAPIkeys.setFieldValue("api_key_name", "")
                            }}
                            createButtonName="Create API Key"

                            //action props
                            isActionVisible={false}

                            //data props
                            data={tableAPIKeysData}
                            columns={columns}

                            //search props
                            isShowSearch={false}

                            //set Selected row data
                            setSelectedData={setSelectedData}
                        />
                    </>
                    <CustomModal handleClose={() => {
                        setIsShowCreateDialog(false); formikAPIkeys.setFieldValue("api_key_name", "")
                    }} onCancel={() => {
                        setIsShowCreateDialog(false);
                        formikAPIkeys.setFieldValue("api_key_name", "")
                    }} onSubmit={formikAPIkeys.handleSubmit} isShowActionButton={true} isModalOpen={isShowCreateAPIDialog} title='Create API key'>
                        <CustomInput label="API key name"
                            name="api_key_name"
                            error={
                                formikAPIkeys.touched.api_key_name &&
                                Boolean(formikAPIkeys.errors.api_key_name)
                            }
                            helperText={
                                formikAPIkeys.touched.api_key_name && formikAPIkeys.errors.api_key_name
                            }
                            onBlur={formikAPIkeys.handleBlur}
                            onChange={formikAPIkeys.handleChange}
                            value={formikAPIkeys.values.api_key_name} />
                    </CustomModal>
                </Box>
            </CustomTabs>
        </Box>
    )
}

export default Profile;