import { useDispatch, useSelector } from "react-redux";
import { selectAddresses, setAddresses } from "../../store/merchant/merchantSlice";
import { selectCurrentUserType } from "../../store/auth/authSlice";
import { Box, Button, Dialog, DialogContent, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import MerchantSubscriptions from "./MerchantSubscriptions";
import { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomModal from "../../theme/style-components/CustomModal";
import CustomButton from "../../theme/style-components/CustomButton";
import moment from "moment";

const AddressList = (props) => {
    //redux logic
    let addresses = useSelector(selectAddresses);
    const dispatch = useDispatch();
    const userType = useSelector(selectCurrentUserType);


    //state variables
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false);

    const handleClose = () => {
        setShowSubscriptionDialog((prevState) => !prevState);
    }

    useEffect(() => {
        if (!props.isAddressLoading && props.addressData?.data) {
            dispatch(setAddresses(props.addressData.data));
        }
    }, [props.isAddressLoading, props.addressData])

    if (!props.isAddressError) {
        return (
            <>
                {props.isAddressLoading ? <div>Loading...</div> :
                    addresses.length
                        ? addresses.map((address, index) => (
                            <Box
                                key={index}
                                sx={{
                                    border: "1px solid #dbdbeb99",
                                    borderRadius: "10px",
                                    marginTop: "15px",
                                    padding: "10px"
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <h4>{address.title}</h4>
                                        <p>{address.address}</p>
                                        <p>{address.city}</p>
                                        <p>Note: {address.note}</p>
                                    </Grid>
                                    <Grid
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                        item
                                        xs={12}
                                        md={4}
                                    >
                                        <Box
                                            sx={{
                                                justifyContent: "center",
                                                alignItems: "center",
                                                display: "flex",
                                                flexDirection: "row",
                                            }}
                                        >
                                            Subscription Type:
                                            {address.subscription_name ? <> {address.subscription_name} (20 $ / mon)</> : ' No Active Subscription Found'}
                                            <br />
                                            Deliveries Left: {address.deliveries_left}
                                            <br />
                                            Expiry date: {address.subscription_expiry_at ? moment.utc(address.subscription_expiry_at).format("DD/MM/YYYY h:mm") : "N/A"}
                                        </Box>
                                    </Grid>
                                    <Grid
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                        item
                                        xs={12}
                                        md={3}
                                    >
                                        {userType !== "MERCHANT" && <CustomButton
                                            color="primary"
                                            outline
                                            onClick={() => {
                                                setSelectedAddress(address);
                                                setShowSubscriptionDialog(true);
                                            }}
                                            buttonName="Subscription"
                                        />}
                                    </Grid>
                                    <Grid
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                        item
                                        xs={12}
                                        md={1}
                                    >
                                        <IconButton
                                            sx={{
                                                color: "#dbdbeb99"
                                            }}
                                            onClick={(e) => {
                                                setSelectedAddress(address);
                                                setMenuAnchor(e.currentTarget);
                                                // setIsMenuOpen(true);
                                                props.setIsOpen(true);
                                            }}
                                        >
                                            <MoreHorizIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Box >
                        ))
                        : "No Addresses Found"}

                {
                    selectedAddress && <Menu
                        anchorEl={menuAnchor}
                        anchorOrigin={{ vertical: "top", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                        open={props.isOpen}
                        onClose={() => {
                            setMenuAnchor(null);
                            props.setIsOpen(false)
                        }}
                        sx={{
                            '& .MuiMenu-paper': {
                                minWidth: "130px",
                                backgroundColor: 'rgb(43, 44, 64)',
                                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 6px -3px, rgba(0, 0, 0, 0.14) 0px 9px 12px 1px, rgba(0, 0, 0, 0.12) 0px 3px 16px 2px',
                                color: "#dbdbebde",
                            }
                        }}

                        MenuListProps={{
                            'aria-labelledby': 'lock-button',
                            role: 'listbox',
                        }}
                        keepMounted
                    >
                        <MenuItem
                            // className={classes.menuText}
                            onClick={() => {
                                props.editMerchantAddress(selectedAddress.id);
                            }}
                        >
                            Edit
                            <EditIcon
                                style={{ cursor: "pointer", marginLeft: "auto" }}
                            />
                        </MenuItem>
                        <MenuItem
                            // className={classes.menuText}
                            onClick={() => {
                                props.deleteMerchantAddress(selectedAddress.id);
                            }}
                        >
                            Delete
                            <DeleteIcon
                                style={{ cursor: "pointer", marginLeft: "auto" }}
                            />
                        </MenuItem>
                    </Menu>
                }
                <CustomModal isShowActionButton={false} isModalOpen={showSubscriptionDialog}
                    title="Merchant Subscription" handleClose={handleClose}>
                    <MerchantSubscriptions setShowSubscriptionDialog={setShowSubscriptionDialog} refetchAddress={props.refetchAddress} addressID={selectedAddress !== null ? selectedAddress.id : 0} />
                </CustomModal>
            </>
        );
    } else {
        return <>No Addresses Found</>;
    }
};

export default AddressList;