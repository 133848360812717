import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUserType } from "../../store/auth/authSlice";
import { useGetSupportedDimensionsQuery, usePostPackageMutation, usePutPackageMutation } from "../../store/orders/orderApiSlice";
import { useEffect, useState } from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { Box, FormControl, FormControlLabel, FormHelperText, Grid, Input, InputAdornment, InputLabel, Radio, RadioGroup, Typography } from "@mui/material";
import CustomInput from "../../theme/style-components/CustomInput";
import { CheckBox, Label } from "@mui/icons-material";
import CustomButton from "../../theme/style-components/CustomButton";
import { isValidNumber } from 'libphonenumber-js';
import { toast } from "react-toastify";
import MuiPhoneNumber from "material-ui-phone-number";
import CustomRadio from "../../theme/style-components/CustomRadio";
import CustomCheckBox from "../../theme/style-components/CustomCheckBox";
import CustomAutoComplete from "../../theme/style-components/CustomAutoComplete";
import CustomModal from "../../theme/style-components/CustomModal";
import AddressBookForm from "./AddressBookForm";
import { showError } from "../../shared/helperFunction";

const CreatePackage = (props) => {
    const addressBooks = props.addressBooksData;

    //redux logic
    const dispatch = useDispatch();
    const userType = useSelector(selectCurrentUserType);

    //base query hooks
    const {
        isLoading,
        data: dimensions,
        isSuccess,
        isError,
        error,
    } = useGetSupportedDimensionsQuery();

    const [postPackage, { isPostPackageLoading }] = usePostPackageMutation();
    const [putPackage, { isPutPackageLoading }] = usePutPackageMutation();

    //state variables
    const [consigneeName, setConsigneeName] = useState('');
    const [consigneePhone, setConsigneePhone] = useState('');
    const [consigneeEmail, setConsigneeEmail] = useState('');
    const [consigneeNote, setConsigneeNote] = useState('');
    const [autocompleteAddress, setAutocompleteAddress] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [postcode, setPostcode] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [placeID, setPlaceID] = useState("");
    const [note, setNote] = useState("");
    const [selectedDimension, setSelectedDimension] = useState(1);
    const [isQrRequired, setIsQrRequired] = useState(true);
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [isValidMobileNumber, setIsValidMobileNumber] = useState(true)
    const [addressBooksID, setAddressBooksID] = useState('');
    const [filledAddress, setFilledAddress] = useState({});
    const [isAddressBookOpen, setIsAddressBookOpen] = useState(false);

    useEffect(() => {
        if (!isLoading) {
            console.log(dimensions.data);
        }
    }, [isLoading, dimensions]);

    useEffect(() => {
        if (props.isPackageEditing) {
            prePopulateForm(props.selectedPackage);
        }
    }, [props.isPackageEditing])


    //methods
    const handleAddressSelect = async (value) => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        let index = 0;
        const addressLength = results[0].address_components.length;
        let postCode = {};
        let cityObject = {};
        let countryObject = {};
        setAutocompleteAddress(value);
        setAddress(value);
        let stateExists = false;
        const myFunction = (item, index, array) => {
            if (array[index].types[0] == "postal_code") {
                postCode = item;
            }
            if (array[index].types[0] == "country") {
                setCountry(item.long_name);
            }
            if (array[index].types[0] == "administrative_area_level_2") {
                stateExists = true;
            }

            if (!stateExists) {
                if (array[index].types[0] == "administrative_area_level_1") {
                    cityObject = item;
                }
            } else {
                if (array[index].types[0] == "administrative_area_level_2") {
                    cityObject = item;
                }
            }
        };
        results[0].address_components.forEach(myFunction);

        setPostcode(postCode.long_name);
        setCity(cityObject.long_name);
        setLatitude(latLng.lat);
        setLongitude(latLng.lng);
        setPlaceID(results[0].place_id);
    };

    const [errors, setErrors] = useState({ contact_phone: '', contact_name: "", contact_email: "", address: "" });
    const handleValidation = (formValue) => {
        let isValid = false;
        const inputValue = formValue['contact_phone'];
        const inputName = formValue['contact_name'];
        const inputEmail = formValue['contact_email'];

        // Initialize the 'error' object if not already done.
        let error = {};

        // Check if the phone number is valid
        const phoneNumberObj = isValidNumber(inputValue);
        if (!inputValue) {
            isValid = false;
            error.contact_phone = 'This Field is required';
        } else if (!phoneNumberObj) {
            isValid = false;
            error.contact_phone = 'Invalid phone number';
        }

        if (!inputName) {
            isValid = false;
            error.contact_name = 'This Field is required';
        }
        if (!address) {
            isValid = false;
            error.address = 'This Field is required';
        }
        if (!inputEmail) {
            isValid = false;
            error.contact_email = 'This Field is required';
        } else {
            isValid = true;
        }
        setErrors(error); // Set all errors together in the 'error' state.

        return Object.keys(error).length === 0;
    };

    const [isSubmiting, setIsSubmiting] = useState(false);

    const handleAddPackageClicked = async () => {
        //validate package here
        const newPackage = {
            order_id: props.orderID,
            contact_name: consigneeName,
            contact_email: consigneeEmail,
            contact_phone: consigneePhone.replace(/[()\s-]/g, ""),
            contact_note: consigneeNote,
            drop_address: {
                address: address,
                city: city,
                country: country,
                postcode: postcode,
                lat: latitude,
                long: longitude,
                place_id: placeID,
                note: note
            },
            dimension_id: selectedDimension, is_qr_required: isQrRequired,
        }
        const isValid = handleValidation(newPackage);
        try {
            if (!props.isPackageEditing && isValid) {
                setIsSubmiting(true);
                const response = await postPackage(newPackage).unwrap().then((res) => {
                    if (res.data) {
                        setIsSubmiting(false);
                        // dispatch(addToPackages(res.data));
                        props.setShowPayButton(false);
                        props.setQuoteData({});
                        toast.success(`Package added successfully  with ID ${res.data.id}`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                    props.resetOrderID();
                    props.setShowAddPackageDialog(false);

                }).catch((err) => {
                    showError(err?.data)
                    setIsSubmiting(false);
                })
            }
            else {
                if (isValid) {
                    newPackage.package_id = props.selectedPackage.id
                    setIsSubmiting(true);
                    if (props.orderStatus === "SCHEDULED") {
                        delete newPackage.drop_address
                    }
                    const response = await putPackage(newPackage).unwrap().then((res) => {
                        if (res.data) {
                            setIsSubmiting(false);
                            // dispatch(editPackages(res.data));
                            props.setIsMenuOpen(false);
                            props.setQuoteData({});
                            props.setShowPayButton(false);
                            toast.success(`Package updated successfully  with ID ${res.data.id}`, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                            props.setShowAddPackageDialog(false);
                        }
                    }).catch((err) => {
                        setIsSubmiting(false);
                        showError(err?.data)
                    });
                }
            }
        }
        catch (error) {
            console.log(error);
            setIsSubmiting(false);
        }
    }

    const prePopulateForm = (selectedPackage) => {
        setConsigneeName(selectedPackage.contact_name);
        setConsigneeEmail(selectedPackage.contact_email);
        setConsigneePhone(selectedPackage.contact_phone);
        setConsigneeNote(selectedPackage.contact_note);
        setAddress(selectedPackage.drop_address.address);
        setNote(selectedPackage.drop_address.note);
        setCity(selectedPackage.drop_address.city);
        setCountry(selectedPackage.drop_address.country);
        setPostcode(selectedPackage.drop_address.postcode);
        setLatitude(selectedPackage.drop_address.lat);
        setLongitude(selectedPackage.drop_address.long);
        setPlaceID(selectedPackage.drop_address.place_id);
        setSelectedDimension(selectedPackage.dimension)
        setIsQrRequired(selectedPackage.is_qr_required)
    }
    const searchOptions = {
        // location: new google.maps.LatLng(-34, 151),
        // radius: 2000,
        // types: ['address']
        componentRestrictions: { country: "au" }
    }
    const handleDimensionChange = (event) => {
        setSelectedDimension(Number(event.target.value));
    };

    const handleQrChange = (event) => {
        setIsQrRequired(event.target.checked);
    };


    // const handleMobileNumberBlur = (event) => {
    //   const inputValue = event.target.value;
    //   // Regular expression pattern to validate mobile numbers with 10 digits
    //   const mobileNumberPattern =  /^\+(?:[0-9] ?){6,14}[0-9]$/;
    //   if (mobileNumberPattern.test(inputValue)) {
    //     setIsValidMobileNumber(true);
    //   } else {
    //     setIsValidMobileNumber(false);
    //   }
    // };

    function handleOnChange(value) {
        setConsigneePhone(value)
        setErrors({ ...errors, contact_phone: "" })
    };

    const MenuProps = {
        PaperProps: {
            style: {
                width: 250,
                '& .MuiMenuItem-root': {
                    whiteSpace: 'inherit !important',
                },
            },

        },
    };

    const MenuItemProps = {
        PaperProps: {
            style: {
                whiteSpace: 'inherit !important',
            },
        },
    };


    useEffect(() => {
        if (filledAddress?.contact_name) {
            setErrors({})
            setConsigneeName(filledAddress?.contact_name);
            setConsigneeEmail(filledAddress?.contact_email);
            setConsigneePhone(filledAddress?.contact_phone);
            setAddress(filledAddress?.drop_address?.address)
            setCity(filledAddress?.drop_address?.city);
            setCountry(filledAddress?.drop_address?.country);
            setPostcode(filledAddress?.drop_address?.postcode)
            setLatitude(filledAddress?.drop_address?.lat);
            setLongitude(filledAddress?.drop_address?.long);
            setPlaceID(filledAddress?.drop_address?.place_id);
            setConsigneeNote(filledAddress.drop_address.note ? filledAddress.drop_address.note : "");
        }
    }, [filledAddress]);

    const handleClear = () => {
        setConsigneeName("");
        setConsigneeEmail("");
        setConsigneePhone("");
        setAddress("")
        setCity("");
        setCountry("");
        setPostcode("")
        setLatitude("");
        setLongitude("");
        setPlaceID("");
        setAutocompleteAddress("");
        setConsigneeNote("");
    };


    return (
        <>

            {/* Left side of content */}
            <Grid item xs={12} md={12} lg={6} sm={12}>
                <Box>
                    <FormControl component="fieldset"> Package Weight
                        <RadioGroup style={{ display: 'block' }} aria-label="options" name="options" value={selectedDimension} onChange={handleDimensionChange}>
                            {!isLoading ? dimensions.data.map((parameter, index) => {
                                return (
                                    <CustomRadio value={parameter.id} key={index} label={parameter.name} />
                                )
                            }) : "Loading"
                            }
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Box sx={{ marginTop: "20px" }}>
                    <CustomInput
                        id="name"
                        name="name"
                        label="Name"
                        value={consigneeName}
                        error={errors["contact_name"]}
                        helperText={
                            errors["contact_name"] ? errors["contact_name"] : ""
                        }
                        onChange={(e) => {
                            setConsigneeName(e.target.value)
                            setErrors({ ...errors, contact_name: "" })
                        }}
                    />
                </Box>
                <Box sx={{ marginTop: "20px" }}>
                    <MuiPhoneNumber sx={{
                        '& .MuiInput-root': {
                            border: '1px solid #dbdbeb61',
                            borderRadius: '3px',
                            padding: '10px',
                            margin: '0',
                            '&::after': {
                                display: 'none',
                            },
                            '&::before': {
                                display: 'none',
                            },

                        },
                        '& .Mui-error': {
                            borderColor: '#d32f2f',
                        },
                        '& .MuiInputLabel-root': {
                            position: 'absolute',
                            top: '-7px',
                            left: '7px',
                            background: '#2b2c40',
                            zIndex: '1',
                            width: 'fit-content',
                            padding: '0 5px',
                        },
                        "& input": {
                            color: "#dbdbebde",
                            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                                '-webkit-appearance': 'none',
                                margin: 0,
                            },
                            '&[type=number]': {
                                '-moz-appearance': 'textfield',
                            },
                        },
                        "& .Mui-disabled": {
                            WebkitTextFillColor: "#dbdbeb61 !important",
                            '& fieldset': {
                                borderColor: '#dbdbeb61 !important',
                            },
                        },
                        '& fieldset': {
                            borderColor: '#dbdbeb61'
                        },
                        '& .MuiInputBase-root,label': {
                            color: '#dbdbeb61'
                        },
                        '& svg': {
                            fill: '#dbdbeb61'
                        },
                        '& .MuiMenu-list': {
                            backgroundColor: 'rgb(43, 44, 64) !important'
                        },
                        "& fieldset:hover": {
                            borderColor: "white !important"
                        },
                        '& .MuiOutlinedInput-root': {
                            '&:hover': {
                                '& fieldset': {
                                    borderColor: '#dbdbeb61',
                                },
                            },
                        },
                        '& .Mui-focused': {
                            borderWidth: '2px',
                            borderColor: '#696cff',
                        },
                        '& .MuiFormLabel-root': {
                            '&.Mui-focused': {
                                color: 'rgb(105, 108, 255) !important',
                            },
                        },

                    }} label="Mobile Number"
                        value={consigneePhone}
                        onChange={handleOnChange}
                        defaultCountry="au"
                        disableAreaCodes={true}
                        inputProps={{
                            name: 'Mobile Number',
                            required: true,
                        }}
                        // onBlur={handleMobileNumberBlur}
                        error={errors["contact_phone"]}
                        helperText={
                            errors["contact_phone"] ? errors["contact_phone"] : ""
                        }
                        fullWidth
                    />
                </Box>
                <Box sx={{ marginTop: "20px" }}>
                    <CustomInput
                        id="email"
                        fullWidth
                        style={{ padding: "1px", marginBottom: '10px', }}
                        name="email"
                        label="Email"
                        error={errors["contact_email"]}
                        helperText={
                            errors["contact_email"] ? errors["contact_email"] : ""
                        }
                        value={consigneeEmail}
                        onChange={(e) => {
                            setConsigneeEmail(e.target.value)
                            setErrors({ ...errors, contact_email: "" })
                        }}
                    />
                </Box>
                <Box sx={{ marginTop: "20px" }}>
                    <CustomInput
                        id="consigneeNote"
                        fullWidth
                        name="consigneeNote"
                        label="Note"
                        value={consigneeNote}
                        onChange={(e) => setConsigneeNote(e.target.value)}
                    />
                </Box>
            </Grid>

            <Grid item xs={12} md={6} sm={12} lg={6}>
                <Box sx={{ marginBottom: "20px" }}>
                    <CustomButton fullWidth={true} onClick={() => setIsAddressBookOpen(!isAddressBookOpen)} color="primary" buttonName="Fill From Address Book" outline={true} />
                </Box>
                <PlacesAutocomplete
                    value={autocompleteAddress}
                    onChange={(e) => {
                        setAutocompleteAddress(e);
                    }}
                    searchOptions={searchOptions}
                    onSelect={(e) => {
                        handleAddressSelect(e)
                        setErrors({ ...errors, address: "" })
                    }}
                >
                    {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                    }) => (
                        <div>
                            <CustomInput
                                error={errors["address"]}
                                fullWidth
                                label="Search Address"
                                variant="outlined"
                                id="standard-adornment-amount"
                                startAdornment={
                                    <InputAdornment position="start"></InputAdornment>
                                }
                                {...getInputProps({ placeholder: "" })}
                            ></CustomInput>
                            <FormHelperText error={errors["address"]}>{
                                errors["address"] ? errors["address"] : ""
                            }</FormHelperText>
                            <div
                                style={
                                    suggestions.length > 0
                                        ? {
                                            position: "absolute",
                                            zIndex: 9999,
                                            backgroundColor: "#2b2c40",
                                            marginRight: "15px",
                                            padding: "15px",
                                            border: "1px solid #2b2c40",
                                            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 6px 4px, rgba(0, 0, 0, 0.14) 0px 9px 12px 1px, rgba(0, 0, 0, 0.12) 0px 3px 16px 2px',
                                            minWidth: "200px",
                                        }
                                        : { display: "none" }
                                }
                            >
                                {loading ? <div>...loading</div> : null}

                                {suggestions.map((suggestion, index) => {
                                    const style = {
                                        backgroundColor: suggestion.active
                                            ? "#dbdbeb0a"
                                            : "#2b2c40",
                                        padding: "10px 10px",
                                    };

                                    return (
                                        <div key={index}
                                            {...getSuggestionItemProps(suggestion, { style })}
                                        >
                                            {suggestion.description}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
                <Box sx={{ marginTop: "20px" }}><Typography variant="h5">Drop Address</Typography>
                    <Box>
                        <Typography>{address}</Typography>
                    </Box>
                </Box>
                <Box sx={{ marginTop: "20px" }}>
                    <CustomCheckBox
                        checked={isQrRequired} id="isQR" onChange={handleQrChange}
                        name="Required - QR scan" label="Required - QR scan" value={isQrRequired} />
                </Box>
                <Box sx={{ marginTop: "20px", display: "flex", gap: "10px" }}>
                    <CustomButton buttonName="Clear" color="secondary" onClick={handleClear} />
                    {
                        <CustomButton
                            onClick={handleAddPackageClicked}
                            disabled={isSubmiting}
                            isLoading={isSubmiting}
                            color="primary"
                            type="submit"
                            buttonName={props.isPackageEditing ? "Update" : "Submit"}
                        />}
                </Box>

                <CustomModal maxWidth="md" title="Address Book"
                    isModalOpen={isAddressBookOpen}
                    isShowActionButton={false}
                    handleClose={() => setIsAddressBookOpen(false)} >
                    <AddressBookForm setIsAddressBookOpen={setIsAddressBookOpen} filledAddress={filledAddress} setFilledAddress={setFilledAddress} />
                </CustomModal>
            </Grid>
        </>
    );
};

export default CreatePackage;