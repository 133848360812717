import { apiSlice } from "../apiSlice";


export const walletApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getWalletTransactions: builder.query({
            query: (filter) => `/brangchain/wallet/transactions/?page=${filter.page}&page_size=${filter.rowsPerPage}`,
            keepUnusedDataFor: 0,
        }),
        getWalletStripeTransactions: builder.query({
            query: (filter) => `/brangchain/wallet/stripe/transactions/?page=${filter.page}&page_size=${filter.rowsPerPage}`,
            keepUnusedDataFor: 0,
        }),
    }),
});

export const {
    useLazyGetWalletTransactionsQuery,
    useLazyGetWalletStripeTransactionsQuery
} = walletApiSlice;