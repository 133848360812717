import { Box, Typography } from '@mui/material';
import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

const DraggableList = ({ brangerTaskSortList }) => {
    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: 20 * 2,
        margin: `0 0 ${23}px 0`,
        borderRadius: "10px",

        // change background colour if dragging
        background: isDragging ? "#2b2c40" : "#2b2c40",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "#696cff" : "white",
        padding: "20px",
        borderRadius: "10px"
    });

    return (
        <Droppable droppableId="droppable">
            {(provided, snapshot) => (
                <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                >
                    {brangerTaskSortList.map((item, index) => (
                        <Draggable key={item.id} draggableId={(item.id).toString()} index={index}>
                            {(provided, snapshot) => (
                                < Box sx={{
                                    boxShadow: '#2b2c40 5px 4px 6px',
                                    borderRadius: '5px',
                                    padding: '10px',
                                    backgroundColor: item.color_key,
                                    marginBottom: "10px"
                                }}
                                    style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                    )}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                >
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',

                                        '& p': {
                                            color: '#dbdbeb',
                                        }
                                    }}>
                                        <Typography sx={{
                                            fontWeight: 'bold', marginRight: '5px'
                                        }}>Task ID:</Typography>
                                        <Typography sx={{
                                            fontSize: '14px'
                                        }}>#{item.task_id}</Typography>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',

                                        '& p': {
                                            color: '#dbdbeb',
                                        }
                                    }}>
                                        <Typography sx={{
                                            fontWeight: 'bold', marginRight: '5px'
                                        }}>Task Type:</Typography>
                                        <Typography sx={{
                                            fontSize: '14px'
                                        }}>{item.task_type}</Typography>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',

                                        '& p': {
                                            color: '#dbdbeb',
                                        }
                                    }}>
                                        <Typography sx={{
                                            fontWeight: 'bold', marginRight: '5px'
                                        }}>Address:</Typography>
                                        <Typography sx={{
                                            fontSize: '14px'
                                        }}>{item?.address?.address}</Typography>
                                    </Box>
                                </Box>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </div>
            )
            }
        </Droppable >
    );
};

export default DraggableList;
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// const getItems = (count) =>
//     Array.from({ length: count }, (v, k) => k).map((k) => ({
//         id: `item-${k}`,
//         content: `item ${k}`,
//     }));

// const reorder = (list, startIndex, endIndex) => {
//     const result = Array.from(list);
//     const [removed] = result.splice(startIndex, 1);
//     result.splice(endIndex, 0, removed);
//     return result;
// };

// const grid = 8;

// const getItemStyle = (isDragging, draggableStyle) => ({
//     userSelect: "none",
//     padding: grid * 2,
//     margin: `0 0 ${grid}px 0`,
//     background: isDragging ? "lightgreen" : "grey",
//     ...draggableStyle,
// });

// const getListStyle = (isDraggingOver) => ({
//     background: isDraggingOver ? "lightblue" : "lightgrey",
//     padding: grid,
//     width: 250,
// });

// const DraggableList = () => {
//     const [items, setItems] = useState(getItems(10));

//     const onDragEnd = (result) => {
//         if (!result.destination) {
//             return;
//         }

//         const reorderedItems = reorder(
//             items,
//             result.source.index,
//             result.destination.index
//         );

//         setItems(reorderedItems);
//     };

//     return (
//         <DragDropContext onDragEnd={onDragEnd}>
//             <Droppable droppableId="droppable">
//                 {(provided, snapshot) => (
//                     <div
//                         {...provided.droppableProps}
//                         ref={provided.innerRef}
//                         style={getListStyle(snapshot.isDraggingOver)}
//                     >
//                         {items.map((item, index) => (
//                             <Draggable key={item.id} draggableId={item.id} index={index}>
//                                 {(provided, snapshot) => (
//                                     <div
//                                         ref={provided.innerRef}
//                                         {...provided.draggableProps}
//                                         {...provided.dragHandleProps}
//                                         style={getItemStyle(
//                                             snapshot.isDragging,
//                                             provided.draggableProps.style
//                                         )}
//                                     >
//                                         {item.content}
//                                     </div>
//                                 )}
//                             </Draggable>
//                         ))}
//                         {provided.placeholder}
//                     </div>
//                 )}
//             </Droppable>
//         </DragDropContext>
//     );
// };

// export default DraggableList;

