import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectBrangers } from "../../store/brangers/brangerSlice";
import { useAssignTaskMutation } from "../../store/tasks/taskApiSlice";
import { useGetBrangersByNameQuery } from "../../store/brangers/brangerApiSlice";
import { Autocomplete, Box, Button, Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import { toast } from "react-toastify";
import CustomAutoComplete from "../../theme/style-components/CustomAutoComplete";
import CustomCheckBox from "../../theme/style-components/CustomCheckBox";
import CustomButton from "../../theme/style-components/CustomButton";

const emptyBranger = {
    user_type: 'BRANGER',
    email: "",
    first_name: '',
    last_name: '',
    phone: '',
    password: '',
    dp: "",
    is_superuser: false,
    is_active: false,
    is_email_verified: false,
    is_phone_verified: false,
    is_kyc_verified: false,
}

const AssignBranger = (props) => {
    //creating classes from styles

    //redux logic
    const dispatch = useDispatch();
    let brangers = useSelector(selectBrangers);

    //state variables
    const [forcedDelivery, setIsForcedDelivery] = useState(false);
    const [brangerID, setBrangerID] = useState(0);
    const [brangersToDisplay, setBrangersToDisplay] = useState(['foo']);
    const [selectedBranger, setSelectedBranger] = useState('');
    const [searchedName, setSearchedName] = useState('');

    //base query logic
    const [assignTask, { isLoading }] = useAssignTaskMutation();
    // const {
    //   isLoading:isGetBrangerLoading,data:brangersReturned,isSuccess, isError, error}=useGetBrangersQuery();

    const {
        isLoading: isBrangerSearchedByNameLoading,
        data: brangerSearchedByName,
        isSuccess: isBrangerSearchedByNameSuccess,
        isError: isBrangerSearchedByNameError,
        error: brangerSearchedByNameError
    } = useGetBrangersByNameQuery(searchedName);

    useEffect(() => {
        if (!isBrangerSearchedByNameLoading && isBrangerSearchedByNameSuccess) {
            let tempBrangers = brangerSearchedByName.data.map((branger) => {
                return {
                    label: branger.first_name + " " + branger.last_name,
                    id: branger.id,
                };
            });

            setBrangersToDisplay(tempBrangers);
        }
    }, [brangerSearchedByName?.data, isBrangerSearchedByNameLoading, searchedName, isBrangerSearchedByNameSuccess])

    return (<>
        <Grid item xs={12} md={12}>
            <Box>
                <h2>Brangers:</h2>
                <CustomAutoComplete
                    value={selectedBranger}
                    onChange={(event, newValue) => {
                        setSelectedBranger(newValue);
                    }}
                    onChangeText={(e) => {
                        setSearchedName(e.target.value)
                    }}
                    onInputChange={(event, newInputValue) => {
                        if (newInputValue === '') {
                            setSelectedBranger(null);
                        }
                        setSearchedName(newInputValue);
                    }}
                    label="Search Branger" options={brangersToDisplay} />
            </Box>
        </Grid>
        <Grid item xs={12} md={12}>
            <Box>
                <CustomCheckBox
                    label="Force Delivery?"
                    checked={forcedDelivery}
                    onChange={() => {
                        setIsForcedDelivery((prevState) => !prevState);
                    }}
                />
            </Box>
        </Grid>
        <Grid item xs={12} md={12}>
            <Box sx={{ textAlign: "center" }}>
                <CustomButton
                    onClick={async () => {
                        if (!props.assignTask) {
                            try {
                                const response = await assignTask({
                                    order_id: props.orderID,
                                    branger_id: selectedBranger?.id,
                                    force_delivery: forcedDelivery,

                                }).unwrap();
                                toast.success(
                                    `Branger ${selectedBranger?.id} assigned to Order Number ${props.orderID}`,
                                    {
                                        position: toast.POSITION.TOP_RIGHT,
                                    }
                                );
                                if (response) {
                                    props.handleRefetch();
                                    props.setIsAssignBrangerModalOpen(false)
                                }
                            } catch (error) {
                                toast.error(`${error.data.errors.non_field_errors[0]}`, {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                            }
                        }
                        else {
                            try {
                                const response = await assignTask({
                                    task_id: props.taskID,
                                    branger_id: selectedBranger?.id,
                                    force_delivery: forcedDelivery,

                                }).unwrap();
                                toast.success(
                                    `Branger ${selectedBranger?.id} assigned to Task Number ${props.taskID}`,
                                    {
                                        position: toast.POSITION.TOP_RIGHT,
                                    }
                                );
                                if (response) {
                                    props.handleRefetch();
                                    props.setIsAssignBrangerModalOpen(false)
                                }
                            } catch (error) {
                                toast.error(`${error.data.errors.non_field_errors[0]}`, {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                            }
                        }
                    }}
                    buttonName="Assign Branger"
                    color="primary"
                />
            </Box>
        </Grid>
    </>
    );
};

export default AssignBranger;