import React, { useEffect, useRef, useState } from 'react';
import { Map, GoogleApiWrapper, Polyline, InfoWindow, Marker } from 'google-maps-react';
import deliveryVan from "../../assets/driver-van.png"
import { Box, Grid, Paper, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useLazyGetPackageTrackingQuery } from '../../store/tasks/taskApiSlice';
import BrangerDetailsCard from '../tracking-page/BrangerDetailsCard';
import driverVan from "../../assets/driver-van.png";
import redIcon from "../../assets/red_marker_map.svg";
import greenIcon from "../../assets/green_marker_map.svg";
import blueIcon from "../../assets/blue_map_marker.svg";


function MapWithPolylines() {
  const [mapRef, setMapRef] = useState(null);
  const google = window.google;
  const [startMarker, setStartMarker] = useState([]);
  const [endMarker, setEndMarker] = useState([]);
  const [points, setPoints] = useState([]);
  const { hash } = useParams();
  const [responseMessage, setResponseMessage] = useState(null);
  const [multiBranger, setMultiBranger] = useState([]);
  const [driversMarker, setDriversMarker] = useState([]);
  const [getPackageTracking, { isLoading, data: trackingData }] = useLazyGetPackageTrackingQuery();
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedMarkerAddress, setSelectedMarkerAddress] = useState('');
  const [selectedMarkerETA, setSelectedMarkerETA] = useState('');
  const [showInfoWindow, setShowInfoWindow] = useState('');
  const [ETA, setETA] = useState([]);
  const [previousPoints, setPreviousPoints] = useState(null);
  const [isBrangerDetailsNull, setIsBrangerDetailsNull] = useState(false);
  const directionsService = new google.maps.DirectionsService();
  let directionsRenderer = new google.maps.DirectionsRenderer({
    map: mapRef,
    suppressMarkers: true,
  });

  useEffect(() => {
    fetchMapData()
  }, [])

  const fetchMapData = async () => {
    try {
      const response = await getPackageTracking(hash).unwrap();
      const { data, message } = response;

      if (message) {
        setResponseMessage(message)
      };

      if (data && data?.pickup) {

        // Specify the property you want to check (e.g., 'city')
        const propertyToCheck = 'branger_details';

        // Check if the specified property is null in at least one object
        const isBrangerNotAssigned = data?.packages.some(obj => obj[propertyToCheck] === null);

        setIsBrangerDetailsNull(data?.packages.some(obj => obj[propertyToCheck] === null));

        if (!isBrangerNotAssigned) {
          let temp = data?.packages;
          let pickup = data?.pickup;
          let temp_array = [];
          temp.map((item) => {
            let branger_id = temp_array.find((obj) => item?.branger_details?.id === obj?.branger_id)
            if (branger_id === undefined) {
              let temp_obj = {
                branger_id: item?.branger_details?.id,
                branger_email: item?.branger_details?.branger_email,
                branger_first_name: item?.branger_details?.branger_first_name,
                branger_last_name: item?.branger_details?.branger_last_name,
                branger_phone: item?.branger_details?.branger_phone,
                pickup: { lat: Number(data?.pickup?.lat), lng: Number(data?.pickup?.long) },
                lat: item?.branger_details.lat,
                long: item?.branger_details.long,
                business_name: item?.business_name,
                customer_email: item?.customer_email,
                customer_name: item?.customer_name,
                customer_phone: item?.customer_phone,
                task_status: item?.task_status,
                drop_address: {
                  address: item?.drop_address?.address,
                },
                package: [item],
                pickup_address: data?.pickup.address
              }
              temp_array.push(temp_obj)
            }
            else {
              branger_id.package.push(item)
            }
          })

          if (pickup) {
            setPoints((prev) => [...prev, { lat: Number(pickup?.lat), lng: Number(pickup?.long) }]);
          };
          if (temp?.length > 0) {
            temp?.map((pack) => {
              setPoints((prev) => [...prev, { lat: Number(pack?.drop_address?.lat), lng: Number(pack?.drop_address?.long), package: pack }]);
            })
          }
          setMultiBranger(temp_array);
        }
      }
      if (message) {
        setResponseMessage(message)
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleMarkerClick = async (markerLocation) => {
    try {
      let address = '';
      let eta = '';
      let distance = '';
      const origin = driversMarker[0];

      // Calculate ETA and distance from the origin to the clicked marker
      const service = new google.maps.DistanceMatrixService();
      const destinations = [markerLocation];

      const matrixOptions = {
        origins: [origin],
        destinations,
        travelMode: google.maps.TravelMode.DRIVING,
      };

      const response = await new Promise((resolve, reject) => {
        service.getDistanceMatrix(matrixOptions, (result, status) => {
          if (status === google.maps.DistanceMatrixStatus.OK) {
            resolve(result);
          } else {
            reject(status);
          }
        });
      });

      if (response.rows.length > 0 && response.rows[0].elements.length > 0) {
        eta = response.rows[0].elements[0].duration.text;
        distance = response.rows[0].elements[0].distance.text;
      }

      // Fetch address for the clicked marker
      const geocoder = new google.maps.Geocoder();
      const geocodeResult = await new Promise((resolve, reject) => {
        geocoder.geocode({ location: markerLocation }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            resolve(results);
          } else {
            reject(status);
          }
        });
      });

      address = geocodeResult[0].formatted_address;

      // Update selected marker and its address/ETA/distance
      setSelectedMarker(markerLocation);
      setSelectedMarkerAddress(address);
      setSelectedMarkerETA(`${eta} (${distance})`);
      setShowInfoWindow(true);
    } catch (error) {
      console.error('Error fetching address, ETA, and distance:', error);
    }
  };

  useEffect(() => {
    if (points !== null && points?.length > 0 && points) {
      setPreviousPoints(points); // update the previous points value

      const coords = [];
      const waypoints = [];
      const MyLocations = [];

      if (points?.length > 0) {
        points.forEach((point, index) => {
          if (point.lat && point.lng) {
            if (index !== 0) {
              MyLocations.push({
                lat: point.lat,
                lng: point.lng,
                package: point?.package
              });
            }
          }
        });
      }
      if (points?.length > 0) {
        points.forEach((point) => {
          if (point.lat && point.lng) {
            coords.push({
              lat: point.lat,
              lng: point.lng,
              package: point?.package
            });
          }
        });
      }

      //Getting the first coordinate in the array as the start/origin
      let start = { lat: coords[0]?.lat, lng: coords[0]?.lng, package: coords[0]?.package };

      //Getting the last coordinate in the array as the end/destination
      let end = {
        lat: coords[coords.length - 1]?.lat,
        lng: coords[coords.length - 1]?.lng,
        package: coords[coords.length - 1]?.package,
      };

      //putting all the coordinates between the first and last coordinate from the array as the waypoints
      for (let i = 1; i < coords.length - 1; i++) {
        waypoints.push({
          location: { lat: coords[i].lat, lng: coords[i].lng, package: coords[i].package },
          stopover: true,
        });
      }

      // directions requests
      let request = {
        origin: start,
        waypoints: waypoints,
        destination: end,
        travelMode: "DRIVING",
      };

      // show results in the directionsrenderer
      directionsRenderer.setDirections(null);

      //show results in the directionsrenderer
      directionsService.route(request, function (result, status) {
        if (result?.status == "OK") {

          // Create an array to store polyline coordinates
          const polylineCoordinates = [];

          // Loop through the legs of the route and add their coordinates to the polylineCoordinates array
          result.routes[0].legs.forEach((leg) => {
            leg.steps.forEach((step) => {
              step.path.forEach((point) => {
                polylineCoordinates.push({ lat: point.lat(), lng: point.lng() });
              });
            });
          });

          // Create a Polyline object using the polylineCoordinates array
          const polyline = new google.maps.Polyline({
            path: polylineCoordinates,
            geodesic: true,  // Adjust this option as needed
            strokeColor: "#0046f7",  // Adjust the color as needed
            strokeOpacity: 1.0,  // Adjust the opacity as needed
            strokeWeight: 3,  // Adjust the line thickness as needed
          });


          // Set the polyline on your map
          polyline.setMap(mapRef);
          const endLocations = result.routes[0].legs.map(
            (leg) => leg.end_location
          );

          const markers = MyLocations.map((location, index) => (
            <Marker
              key={index}
              position={{ lat: location.lat, lng: location?.lng }}
              label={{
                text: MyLocations.length === 1 ? "D" : `D${index + 1}`,
                color: "#FFFFFF", fontSize: "16px",
              }}
              icon={{
                url: location?.task_status === "UNASSIGNED" ? redIcon : location?.task_status !== "DELIVERED" ? blueIcon : greenIcon,
                // Assume we're working with an icon size of 40x40
                scaledSize: new google.maps.Size(40, 40),
                // Set the anchor to the middle of your icon image
                anchor: new google.maps.Point(20, 20),
              }}
              // icon={customMarkerIcon}
              onClick={() => handleMarkerClick(location)}
            />
          ));

          if (coords.length === 1) {
            const startLocation = result.routes[0].legs[0].start_location;
            const startMark = (
              <Marker
                position={{ lat: coords[0]?.lat, lng: coords[0]?.lng }}
                icon={{
                  url: deliveryVan,
                  fillColor: '#EB00FF',
                  scale: 7,
                }}
              />
            );
            if (startMark) {
              setStartMarker((pre) => [...pre, startMark]);
            }
          } else if (markers && endLocations.length !== 0) {
            setEndMarker((pre) => [...pre, markers]);
            const startLocation = result.routes[0].legs[0].start_location;
            const startMark = (
              <Marker
                position={start}
                label={{ text: "P", color: "#FFFFFF", fontSize: "16px" }}
                icon={{
                  url: start?.package?.task_status === "UNASSIGNED" ? redIcon : start?.package?.task_status !== "DELIVERED" ? blueIcon : greenIcon,
                  // Assume we're working with an icon size of 40x40
                  scaledSize: new google.maps.Size(40, 40),
                  // Set the anchor to the middle of your icon image
                  anchor: new google.maps.Point(20, 20),
                }}
                onClick={() => handleMarkerClick({ lat: start?.lat, lng: start?.lng })}
              />
            );
            if (startMark) {
              setStartMarker((pre) => [...pre, startMark]);
            }
          }
        }
      });
    }
  }, [points, driversMarker?.length]);



  useEffect(() => {
    const request = {
      method: 'SUBSCRIBE',
      params: ["tracking_hash"],
      tracking_hash: hash,
    };

    if (!isLoading && trackingData) {

      const wsClient = new WebSocket(`${process.env.REACT_APP_WEBSOCKET}/ws/tracking/?tracking_hash=${hash}`);
      wsClient.onopen = () => {
        wsClient.send(JSON.stringify(request));
      };

      wsClient.onmessage = (evt) => {
        const brangersData = JSON.parse(evt.data);
        if (brangersData?.data) {
          const driverLoc = []
          if (brangersData?.data?.length) {
            brangersData?.data.map((item) => {
              driverLoc.push({ lat: Number(item.lat), lng: Number(item.long) })
            })
          };
          console.log("driverLoc", driverLoc);
          setDriversMarker(driverLoc);
        }
      };

      // wsClient.onclose = () => console.log('ws closed');
      return () => {
        wsClient.close();
      };
    }
  }, [trackingData, isLoading]);

  console.log("driversMarker", driversMarker);

  const onMapReady = (mapProps, map) => {
    setMapRef(map);
  };

  return (
    <Box>
      {isBrangerDetailsNull ? <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: '100vh' }}
      >
        <Grid item>
          <Paper elevation={3}
            style={{ padding: '1rem 10rem', background: 'red', color: 'white' }}>
            <Typography variant="h5" align="center" style={{ fontSize: "14px" }}>
              Branger not assigned in some task
            </Typography>
          </Paper>
        </Grid>
      </Grid> : null}
      {responseMessage === "order COMPLETED!" && !isBrangerDetailsNull ?
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: '100vh' }}
        >
          <Grid item>
            <Paper elevation={3}
              style={{ padding: '1rem 10rem', background: 'green', color: 'white' }}>
              <Typography variant="h5" align="center" style={{ fontSize: "14px" }}>
                Order Delivered Successfully.
              </Typography>
            </Paper>
          </Grid>
        </Grid> :
        <>
          <Box sx={{
            height: '400px',
            marginBottom: '20px',
            '&>div': {
              height: '400px !important',
            },
          }}>
            <Map
              style={{ height: "100%", width: "100%" }}
              google={google}
              zoom={14}
              onReady={onMapReady}
              initialCenter={{ lat: -37.82296, lng: 144.979401 }}
              ref={mapRef}>
              {startMarker}
              {endMarker}
              {driversMarker?.length > 0 ? driversMarker?.map((driver) => {
                return <Marker
                  position={driver}
                  icon={{
                    url: driverVan,
                    fillColor: '#EB00FF',
                    scale: 7,
                  }}
                />
              })
                : null}

              {selectedMarker && (
                <InfoWindow
                  position={selectedMarker}
                  onCloseClick={() => {
                    setSelectedMarker(null);
                    showInfoWindow(!showInfoWindow)
                  }}
                  visible={showInfoWindow}
                >
                  <Box p={2}>
                    <Typography variant="subtitle1">Address: {selectedMarkerAddress}</Typography>
                    <Typography variant="subtitle1">{`${selectedMarkerETA ? "ETA:" : ""} ${selectedMarkerETA}`}</Typography>
                  </Box>
                </InfoWindow>
              )}
            </Map>
          </Box>
          <BrangerDetailsCard isLoading={isLoading} multiBranger={multiBranger} />
        </>
      }
    </Box>
  );
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDp8kshAwl2VeZe4lSseBVFhYVNQpVXyt0',
})(MapWithPolylines);
