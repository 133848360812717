import { createSlice } from "@reduxjs/toolkit";


const merchantSlice = createSlice({
  name: "merchant",
  initialState: { address: null, merchants: null, merchantAddresses: [{}] },
  reducers: {
    setMerchants: (state, action) => {
      state.merchants = action.payload;
    },
    setAddresses: (state, action) => {
      state.merchantAddresses = action.payload;
    },
    addToAddress: (state, action) => {
      state.merchantAddresses.push(action.payload);
    },
    editAddress: (state, action) => {
      // let index = current(state.merchantAddresses.indexOf(action.payload.addressToEdit));
      //   alert('index of address that has been edited: ', index);
      state.merchantAddresses.splice(action.payload.indexToEdit, 1, action.payload.editedAddress);
    },
    deleteAddressFromState: (state, action) => {
      state.merchantAddresses = state.merchantAddresses.filter((add) => add.id !== action.payload);
    },
  },
});

export const { setMerchants, addToAddress, setAddresses, editAddress, deleteAddressFromState } =
  merchantSlice.actions;

export default merchantSlice.reducer;

export const selectAddresses = (state) => state.merchant.merchantAddresses;
export const selectMerchants = (state) => state.merchant.merchants;