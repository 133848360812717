import React, { useState } from 'react'
import CustomInput from '../../theme/style-components/CustomInput'
import { Box, Grid } from '@mui/material'
import CustomButton from '../../theme/style-components/CustomButton'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const AddOtherFees = (props) => {
    const { handleAddOtherFees, handleClose, payOtherFeesLoading } = props;
    const [otherFees, setOtherFees] = useState('')
    const [otherFeesComment, setOtherFeesComment] = useState('');
    const [otherFeesError, setOtherFeesError] = useState(false);
    const [otherFeesCommentError, setOtherFeesCommentError] = useState(false);

    const onSubmit = () => {
        if (!otherFees) {
            setOtherFeesError(true);
        } else if (!otherFeesComment) {
            setOtherFeesCommentError(true);
        } else {
            handleAddOtherFees({
                otherFees: otherFees,
                otherFeesComment: otherFeesComment
            })
        }
    }

    return (
        <>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ marginTop: "10px" }}>
                    <Box sx={{
                        position: 'relative'
                    }}><CustomInput
                            value={otherFees}
                            label="Other Fees"
                            type="number"
                            id="other-fees"
                            name="other-fees"
                            error={otherFeesError}
                            helperText={
                                otherFeesError ? "This field is required" : ""
                            }
                            onChange={(e) => {
                                setOtherFees(e.target.value);
                                setOtherFeesError(false);
                            }}
                        />
                        <AttachMoneyIcon sx={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                        }} />
                    </Box>
                </Box>
            </Grid>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ marginTop: "10px" }}>
                    <Box><CustomInput
                        value={otherFeesComment} label="Comment"
                        id="other-fees-comment"
                        name="other-fees-comment"
                        error={otherFeesCommentError}
                        helperText={
                            otherFeesCommentError ? "This field is required" : ""
                        }
                        onChange={(e) => {
                            setOtherFeesComment(e.target.value);
                            setOtherFeesCommentError(false);
                        }} /></Box>
                </Box>
            </Grid>

            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ marginTop: "10px" }}>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "10px"
                    }}>
                        <Box><CustomButton buttonName="Submit" isLoading={payOtherFeesLoading} disabled={payOtherFeesLoading} color="primary" onClick={onSubmit} /></Box>
                        <Box><CustomButton buttonName="Cancel" color="secondary" onClick={handleClose} /></Box>
                    </Box>
                </Box>
            </Grid>
        </>
    )
}

export default AddOtherFees;
