import { apiSlice } from "../apiSlice";


export const dashboardApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getUserDashboard: builder.query({
            query: () => `/order/order_dashboard/`,
            keepUnusedDataFor: 0,
        }),
    }),
});

export const {
    useGetUserDashboardQuery,
} = dashboardApiSlice;