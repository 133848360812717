import { apiSlice } from "../apiSlice";

export const paymentsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSubscriptions: builder.query({
            query: () => "/payments/subscriptions/",
            keepUnusedDataFor: 5,
        }),
        getQuote: builder.query({
            query: ({ order }) => `/operations/quote/?order_id=${order}`,
            keepUnusedDataFor: 5
        }),
        stripeIntent: builder.mutation({
            query: (order) => ({
                url: '/payments/stripe_intent/',
                method: "POST",
                body: order
            })
        }),
        getSavedCard: builder.query({
            query: (id) => `/payments/saved_cards/?user_id=${id}`,
            keepUnusedDataFor: 5,
        }),
        stripeIntentWallet: builder.mutation({
            query: (detail) => ({
                url: '/brangchain/wallet/recharge/',
                method: "POST",
                body: detail
            })
        }),
        payWithWallet: builder.mutation({
            query: (Order_id) => ({
                url: '/brangchain/order/checkout/',
                method: "POST",
                body: Order_id
            })
        }),
        payOtherFees: builder.mutation({
            query: (detail) => ({
                url: '/order/order_fees/',
                method: "PUT",
                body: detail
            })
        }),
    }),
});

export const { useGetSubscriptionsQuery, useLazyGetQuoteQuery, useStripeIntentMutation, useLazyGetSavedCardQuery, useStripeIntentWalletMutation, usePayWithWalletMutation, usePayOtherFeesMutation } = paymentsApiSlice;