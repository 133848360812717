import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from "yup";
import { useGetSupportedDimensionsQuery } from '../../store/orders/orderApiSlice';
import { useLazyGetMerchantAddressQuery } from '../../store/merchant/merchantApiSlice';
import CustomModal from '../../theme/style-components/CustomModal';
import { Backdrop, Box, FormControl, Grid, MenuItem, RadioGroup } from '@mui/material';
import CustomDatePicker from '../../theme/style-components/CustomDatePicker';
import CustomSelect from '../../theme/style-components/CustomSelect';
import CustomRadio from '../../theme/style-components/CustomRadio';
import CustomCheckBox from '../../theme/style-components/CustomCheckBox';
import moment from 'moment';
import { usePostCreateProcessMutation } from '../../store/api-keys-fredit/freditApiKeySlice';
import { toast } from 'react-toastify';
import { showError } from '../../shared/helperFunction';
import LottieLoader from '../loader/LottieLoader';

const CreateProcessModal = ({ setPage,resetSelectedRowsIds, setIsOpenProcessModal, isOpenProcessModal, selectedRowsIds, refetchFreditOrders }) => {
    const [selectedDimension, setSelectedDimension] = useState(1);
    const [selectedDate, setSelectedDate] = useState(null);
    const [startMinDate, setStartDate] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState();
    const [selectedTimeOptions, setSelectedTimeOptions] = useState();
    const [addresses, setAddresses] = useState();
    const [address, setAddress] = useState();
    const [time, setTime] = useState();
    const [hasUpdatedStartMinDate, setHasUpdatedStartMinDate] = useState(false);
    const [isSubmiting, setIsSubmiting] = useState(false);

    const [postCreateProcess, { isLoading: isPostCreateProcessLoading }] = usePostCreateProcessMutation();

    const authState = useSelector(state => state.auth)
    const { userType, userID } = authState || {}

    const validationSchema = yup.object({
        buff_ids:
            yup.array().min(1, 'At least one item is required'),
    });

    const formik = useFormik({
        initialValues: {
            buff_ids: selectedRowsIds || [],
            pickup_address_id: "",
            scheduled_dt: "",
            is_qr: false,
            dimension_id: selectedDimension ? selectedDimension : null
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (values) {
                try {
                    const response = await postCreateProcess(values).unwrap();
                    toast.success(response?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    if (response) {
                        setPage(1);
                        resetSelectedRowsIds();
                        refetchFreditOrders();
                        setIsSubmiting(false);
                        setIsOpenProcessModal(false);
                    }
                } catch (err) {
                    if (err) {
                        setIsSubmiting(false);
                    }
                    if (err?.error) {
                        toast.error(err?.error, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    } else {
                        showError(err?.data)
                    }
                }
            }
        }
    });

    const {
        isLoading: isDimensionsLoading,
        data: dimensions,
        isSuccess: isDimensionsSuccess,
        isError: isDimensionsError,
        error,
    } = useGetSupportedDimensionsQuery();

    const [getMerchantAddress, {
        isLoading: isMerchantAddressLoading,
        data: dataReturned,
        isSuccess: isMerchantAddressSuccess,
        isError: isMerchantError,
        error: merchantError,
    }] = useLazyGetMerchantAddressQuery();

    const getAddress = async (newValue, orderData) => {
        try {
            const Addressdata = getMerchantAddress({ userID: userType === "MERCHANT" ? userID : newValue?.id ? newValue.id : newValue, subscribedOnly: true }).unwrap().then((res) => {
                if (res) {
                    if (res?.data) {
                        if (orderData) {
                            const editTimeDate = moment.utc(orderData.scheduled_dt);
                            const formattedTime = editTimeDate.format('HH:mm:ss');
                            const preSelectedTimeID = res?.data.filter((add) => add.id === orderData.merchant_address_details.id)[0].assigned_time_slots.filter((slot) => slot.start_time === formattedTime)[0].id
                            setSelectedTime(preSelectedTimeID)
                        }

                        res.data.forEach((address) => {
                            if (address.id === orderData?.merchant_address_details.id) {
                                setAddress(address.id);
                                const selectedTimeSlot = orderData?.merchant_address_details?.assigned_time_slots?.map((slot) => slot)
                            }
                        })
                    }
                }
            })
        } catch (err) {
            console.log(err, "err");
        }
    };

    useEffect(() => {
        if (userType === "MERCHANT") {
            getAddress()
        }
    }, [userType]);

    useEffect(() => {
        if (!isMerchantAddressLoading && dataReturned?.data) {
            setAddresses(dataReturned.data);
        }
    }, [isMerchantAddressLoading, dataReturned?.data, addresses]);

    useEffect(() => {
        if (addresses?.length) {
            addresses.map((add) => {
                if (add.id === address) {
                    if (add?.assigned_time_slots) {
                        setSelectedTimeOptions(add?.assigned_time_slots)
                    }
                }
            })
        } else {
            setSelectedTimeOptions(undefined)
        }
    }, [address, address?.length, addresses?.length, addresses])

    useEffect(() => {
        if (addresses?.length) {
            setAddress(addresses[addresses?.length - 1]?.id)
            formik.setFieldValue("pickup_address_id", addresses[addresses?.length - 1]?.id)
            const preSelectedTime = addresses[addresses?.length - 1]?.assigned_time_slots?.map((time) => time);
            setSelectedTime(preSelectedTime[0]?.id);
        }
    }, [addresses]);

    useEffect(() => {
        if (selectedTimeOptions?.length && selectedTime) {
            const timeD = selectedTimeOptions.filter((option) => option.id === selectedTime)[0]?.start_time
            setTime(timeD)
        }
    }, [selectedTime, selectedTimeOptions]);

    useEffect(() => {
        if (selectedTimeOptions?.length && selectedTime) {
            const exactTime = selectedTimeOptions.find(item => item.id === selectedTime)?.start_time;
            // Assume the API returns a string value for the time in "hh:mm:ss" format
            const apiTime = exactTime;
            if (apiTime) {
                // Get the current date and time
                const currentTime = new Date();

                // Split the API time into hours, minutes, and seconds
                const [hours, minutes, seconds] = apiTime && apiTime.split(':');

                // Set the time part of the current time to the API time
                currentTime.setHours(hours);
                currentTime.setMinutes(minutes);
                currentTime.setSeconds(seconds);

                // Subtract 30 minutes from the current time
                const thirtyMinutesBefore = new Date(currentTime.getTime() - 30 * 60 * 1000);

                // Check if the calculated time is less than or equal to the threshold time
                if (new Date() <= thirtyMinutesBefore) {
                    console.log('The current time is less than or equal to the calculated time.');
                    setSelectedDate(startMinDate);
                } else {
                    if (!hasUpdatedStartMinDate) {
                        startMinDate.setDate(startMinDate.getDate() + 1);
                        setSelectedDate(startMinDate);
                        setHasUpdatedStartMinDate(true);
                    }
                }
            }
        }
    }, [selectedTime, selectedTimeOptions]);

    useEffect(() => {
        var dateObj = new Date(selectedDate);

        const year = dateObj ? dateObj.getFullYear().toString() : null;
        const month = dateObj ? (dateObj.getMonth() + 1).toString() : null;
        const day = dateObj ? dateObj.getDate().toString() : null;

        const latestD = `${year}-${month}-${day} ${time}`
        formik.setFieldValue("scheduled_dt", latestD);

    }, [selectedDate, time])

    const handleDimensionChange = (event) => {
        setSelectedDimension(Number(event.target.value));
        formik.setFieldValue("dimension_id", Number(event.target.value));
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleTimeChange = (event) => {
        setSelectedTime(Number(event.target.value));
    };

    return (
        <Box>
            <CustomModal maxWidth="md" handleClose={() => {
                setIsOpenProcessModal(false);
                formik.resetForm();
            }} disabled={isPostCreateProcessLoading} onCancel={() => {
                setIsOpenProcessModal(false);
                formik.resetForm();
            }} onSubmit={formik.handleSubmit} isShowActionButton={true} isModalOpen={isOpenProcessModal} title='Process'>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isDimensionsLoading || isMerchantAddressLoading}
                >
                    <LottieLoader />
                </Backdrop>
                <Grid container spacing={2}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Box>
                            <CustomDatePicker
                                selectedDate={selectedDate}
                                onChange={handleDateChange}
                                minDate={startMinDate}
                            />
                        </Box>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Box>
                            {addresses?.length !== 0 && address && <CustomSelect value={selectedTime} label="Time" onChange={handleTimeChange}>
                                {selectedTimeOptions?.map((time, index) => {
                                    return (
                                        <MenuItem key={index} value={time.id}>{time.start_time} - {time.end_time}</MenuItem>
                                    )
                                })}
                            </CustomSelect>}
                        </Box>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Box>
                            <FormControl component="fieldset"> Package Weight
                                <RadioGroup style={{ display: 'block' }} aria-label="options" name="options" value={selectedDimension} onChange={handleDimensionChange}>
                                    {!isDimensionsLoading ? dimensions.data.map((parameter, index) => {
                                        return (
                                            <CustomRadio value={parameter.id} key={index} label={parameter.name} />
                                        )
                                    }) : "Loading"
                                    }
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Box sx={{ marginTop: "20px" }}>
                            <CustomCheckBox
                                checked={formik.values.is_qr} id="isQR" onChange={formik.handleChange}
                                name="is_qr" label="Required - QR scan" value={formik.values.is_qr} />
                        </Box>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        {address?.length !== 0 && addresses?.length !== 0 && address &&
                            <Box>
                                <Box>
                                    <CustomSelect
                                        fullWidth
                                        id="address"
                                        name="address"
                                        label="Select Address"
                                        placeholder="Select Address"
                                        onChange={(e) => {
                                            setAddress(e.target.value);
                                            formik.setFieldValue("pickup_address_id", e.target.value);
                                        }}
                                        value={address}>
                                        {addresses &&
                                            addresses.map((address, index) => (
                                                <MenuItem value={address.id} key={index}>
                                                    <strong>{address.title} </strong>&nbsp; {address.address}
                                                </MenuItem>
                                            ))}
                                    </CustomSelect>
                                </Box>
                                {addresses && address && addresses?.map((add, index) => {
                                    if (add.id === address) {
                                        return (
                                            <Box key={index}>
                                                {add.title} <br />
                                                {add.address} <br />
                                                {add.city} <br />
                                                {add.postcode}
                                            </Box>
                                        )
                                    }
                                })}
                            </Box>}
                    </Grid>
                </Grid>
            </CustomModal>
        </Box>
    )
}

export default CreateProcessModal;
