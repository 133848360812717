import { apiSlice } from "../apiSlice";

export const ordersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getOrders: builder.query({
            query: ({ page, query }) => `/order/get_orders/?page=${page}${query}`,
            keepUnusedDataFor: 0,
        }),
        getOrdersBySearch: builder.query({
            query: (search) => `/order/get_orders/?order_search=${search}`,
            keepUnusedDataFor: 0,
        }),
        getOrder: builder.query({
            query: (order) => `/order/get_orders/?order_id=${order}`,
            keepUnusedDataFor: 0,
        }),
        getMyOrder: builder.query({
            query: ({ page, query }) => `/order/my_orders/?page=${page}${query}`,
            keepUnusedDataFor: 0,
        }),
        getSupportedDimensions: builder.query({
            query: () => "/order/supported_dimensions/",
            keepUnusedDataFor: 60,
        }),
        postOrder: builder.mutation({
            query: (newOrder) => ({
                url: "/order/order/",
                method: "POST",
                body: { ...newOrder },
            }),
        }),
        putOrder: builder.mutation({
            query: (newOrder) => ({
                url: "/order/order/",
                method: "PUT",
                body: { ...newOrder },
            }),
        }),
        postPackage: builder.mutation({
            query: (newPackage) => ({
                url: "/order/package/",
                method: "POST",
                body: { ...newPackage },
            }),
        }),
        putPackage: builder.mutation({
            query: (newPackage) => ({
                url: "/order/package/",
                method: "PUT",
                body: { ...newPackage },
            }),
        }),
        deletePackage: builder.mutation({
            query: (id) => ({
                url: `/order/package/?package_id=${id.package_id}`,
                method: "DELETE",
            }),
        }),
        exportOrder: builder.query({
            query: () => "/order/export_orders/",
            keepUnusedDataFor: 60,
        }),
        getOrderPdf: builder.query({
            query: (id) => ({
                url: `/order/print_order/?order_id=${id}`,
                method: "GET",
                responseHandler: async (response) => {
                    if (response.ok) {
                        return response.blob();
                    } else {
                        const errorData = await response.json(); // Parse the error response
                        return errorData;
                    }
                },
            }),
        }),
        postPackageCSV: builder.mutation({
            query: (csvFormData) => ({
                url: `/order/import_package/`,
                method: "POST",
                body: csvFormData,
            }),
        }),
        getAllOrderPdf: builder.query({
            query: ({ idFilter, merchantIDFilter, orderStatus, nameFilter, startDate, endDate, order_search }) => {
                return ({
                    url: `/order/export_orders/?${idFilter ? `order_id=${idFilter}` : ""}${merchantIDFilter ? `&merchant_id=${merchantIDFilter}` : ""}${orderStatus ? `&order_status=${orderStatus}` : ""}${nameFilter ? `&order_search=${nameFilter}` : ""}${startDate ? `&from_d=${startDate}` : ""}${endDate ? `&to_d=${endDate}` : ""}${order_search ? `&order_search=${order_search}` : ""}`,
                    method: "GET",
                    responseHandler: async (response) => {
                        if (response.ok) {
                            return response.blob();
                        } else {
                            const errorData = await response.json(); // Parse the error response
                            return errorData;
                        }
                    },
                })
            },
        }),
        getOrderCSVTemplate: builder.query({
            query: () => {
                return ({
                    url: `/order/csv_template/`,
                    method: "GET",
                    responseHandler: async (response) => {
                        if (response.ok) {
                            return response.blob();
                        } else {
                            const errorData = await response.json(); // Parse the error response
                            return errorData;
                        }
                    },
                })
            },
        }),
        getTransaction: builder.query({
            query: ({ page, orderId }) => `/brangchain/transactions/?order_id=${orderId}&page=${page}`,
            keepUnusedDataFor: 0,
        }),
        deleteOrder: builder.mutation({
            query: (id) => ({
                url: `/order/order/?order_id=${id}`,
                method: "DELETE",
            }),
        }),
        getAddressBook: builder.query({
            query: () => "/user/merchant_address_book/",
        }),
        getInvoicePrint: builder.query({
            query: (id) => ({
                url: `/order/print_order_invoice/?order_id=${id}`,
                method: "GET",
                responseHandler: async (response) => {
                    if (response.ok) {
                        return response.blob();
                    } else {
                        const errorData = await response.json(); // Parse the error response
                        return errorData;
                    }
                },
            }),
        }),
    }),
});

export const {
    useGetOrdersQuery,
    useGetSupportedDimensionsQuery,
    usePostOrderMutation,
    usePostPackageMutation,
    useGetOrderQuery,
    usePutOrderMutation,
    usePutPackageMutation,
    useExportOrderQuery,
    useGetMyOrderQuery,
    useLazyGetOrderPdfQuery,
    usePostPackageCSVMutation,
    useLazyGetAllOrderPdfQuery,
    useLazyGetMyOrderQuery,
    useDeletePackageMutation,
    useLazyGetOrderCSVTemplateQuery,
    useLazyGetOrderQuery,
    useGetTransactionQuery,
    useDeleteOrderMutation,
    useGetAddressBookQuery,
    useGetOrdersBySearchQuery,
    useLazyGetInvoicePrintQuery,
} = ordersApiSlice; 