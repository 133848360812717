import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useResetPasswordMutation } from "../../store/auth/authApiSlice";
import CustomInput from "../../theme/style-components/CustomInput";
import CustomButton from "../../theme/style-components/CustomButton";
import { Box, Typography } from "@mui/material";
import brangLogo from "../../assets/logos/brangChainWhite.png";
import { showError } from "../../shared/helperFunction";

const ResetPassword = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const validationSchema = yup.object().shape({
    password: yup
      .string("Password")
      .required("Password is required")
      .min(8, "Password should be minimum 8 characters")
      .max(128, "Password should be maximum 128 characters"),
    confirm_password: yup
      .string("Password")
      .required("Confirm password is required")
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .min(8, "Confirm password should be minimum 8 characters")
      .max(128, "Confirm password should be maximum 128 characters")
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let passwordData = {
        password: values.password,
        confirm_password: values.confirm_password,
        vhash: id
      };
      if (passwordData) {
        try {
          const resetPasswordData = await resetPassword(passwordData).unwrap();
          resetPasswordData && toast.success("Password updated successfully!", {
            position: toast.POSITION.TOP_RIGHT
          });
          navigate('/login', { replace: true });
        } catch (error) {
          showError(error?.data)
        }
      }
    },
  });


  return (
    <Box sx={{
      width: '100%',
      maxWidth: { lg: '50%', md: '50%', sm: '100%', xs: '100%', },
      margin: '0 auto',
      padding: '20px',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <Box>
        <Box sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          "& img": {
            maxWidth: "200px",
          }
        }}><img width="100%" height="100%" src={brangLogo} /></Box>
        <Typography variant='h2' sx={{
          color: "#dbdbebde",
          marginBottom: "10px"
        }}>Reset Password</Typography>
        <Typography sx={{
          color: "#dbdbeb99", marginBottom: "20px"
        }}>Please enter your new password.</Typography>
        <form>
          <Box>
            <Box sx={{
              marginBottom: "10px"
            }}>
              <CustomInput
                id='Password'
                name="password"
                type="password"
                variant='outlined'
                margin='normal'
                label="Password"
                fullWidth
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password &&
                  Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Box>
            <Box sx={{
              marginBottom: "20px"
            }}>
              <CustomInput
                id='confirm_password'
                type="password"
                name="confirm_password"
                variant='outlined'
                margin='normal'
                label="Confirm Password"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.confirm_password}
                error={
                  formik.touched.confirm_password &&
                  Boolean(formik.errors.confirm_password)
                }
                helperText={formik.touched.confirm_password && formik.errors.confirm_password}
              />
            </Box>
            <Box>
              <CustomButton buttonName="Reset Password"
                fullWidth disabled={isLoading} isLoading={isLoading} onClick={formik.handleSubmit} variant='contained' size='large' color='primary' type='submit' />
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  )
};

export default ResetPassword;