import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import styled from "@emotion/styled";
import { FormHelperText, MenuItem, Paper } from '@mui/material';

const StyledSelect = styled(Select)`
  && {
    background-color: rgb(43, 44, 64) !important;
    color: #dbdbeb99;

    &:hover {
      box-shadow: none !important;
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    &:hover {
      background-color: rgba(219, 219, 235, 0.04) !important;
    }

    &.Mui-selected {
      background-color: #696cff29 !important;
    }
  }
`;

const StyledPaper = styled(Paper)`
  && {
    background-color: rgb(43, 44, 64) !important;
    color: #dbdbeb99 !important;
  }
`;

const CustomSelect = ({ value, label, onChange, children, name, error, helperText }) => {
    return (
        <FormControl fullWidth sx={{
            '& fieldset': {
                borderColor: '#dbdbeb61'
            },


            '& .MuiInputBase-root,label': {
                color: '#dbdbeb61'
            },
            '& svg': {
                fill: error ? "#d32f2f" : "#dbdbeb61"
            },
            '& .MuiMenu-list': {
                backgroundColor: 'rgb(43, 44, 64) !important'
            },
            "& fieldset:hover": {
                borderColor: "white !important"
            },
            '& .MuiOutlinedInput-root': {
                '&:hover': {
                    '& fieldset': {
                        borderColor: '#dbdbeb61',
                    },
                },
            },
            '& .Mui-focused': {
                '& fieldset': {
                    borderColor: 'rgb(105, 108, 255) !important',
                },
            },
            '& .MuiFormLabel-root': {
                '&.Mui-focused': {
                    color: 'rgb(105, 108, 255) !important',
                },
            },
        }}>
            <InputLabel id="demo-simple-select-label" error={error}>{label}</InputLabel>
            <StyledSelect
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                label={label}
                name={name}
                error={error}
                onChange={onChange}
                MenuProps={{
                    PaperProps: {
                        component: StyledPaper,
                    },
                }}
            >
                {React.Children.map(children, (child) => (
                    <StyledMenuItem value={child.props.value}>
                        {child.props.children}
                    </StyledMenuItem>
                ))}
            </StyledSelect>
            <FormHelperText error={error}>{error && helperText}</FormHelperText>
        </FormControl>
    );
};

export default CustomSelect;
