import { Box, TextField } from "@mui/material";
import ReactDatePicker from 'react-datepicker';
import rightArrow from "../../assets/dashboard-image/right-arrow.svg";
import leftArrow from "../../assets/dashboard-image/left-arrow.svg";
import React from "react";

const CustomDateRangePicker = ({ dateRange, onChange, label }) => {
    return (
        <Box sx={{
            '& input': {
                padding: '15px ',
                color: '#6c6d7f',
                backgroundColor: 'transparent',
                // border: '1px solid #525266',
                borderRadius: '5px',
                outline: 'none',
                fontWeight: '600',
            },
            '& .react-datepicker': {
                backgroundColor: 'rgb(43, 44, 64)',
                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 5px -2px, rgba(0, 0, 0, 0.14) 0px 7px 10px 1px, rgba(0, 0, 0, 0.12) 0px 2px 16px 1px',
                border: 'none',

            },
            '& .react-datepicker__day,.react-datepicker__day-name': {
                color: 'rgba(219, 219, 235, 0.87)',
                width: { xl: "2rem", lg: '2rem', md: '2rem', sm: '2rem', xs: '28px' }
            },
            '& .react-datepicker__day': {
                margin: '0px',
                borderRadius: '6px',
                width: '2.125rem',
                fontSize: '0.875rem',
                lineHeight: '2.125rem',
                '&:hover': {
                    backgroundColor: 'rgba(219, 219, 235, 0.04)',
                },
            },
            '& .react-datepicker__current-month': {
                color: 'rgba(219, 219, 235, 0.87)',
                fontWeight: '500',
                marginTop: '6px',
            },
            '& .react-datepicker__header': {
                backgroundColor: 'transparent',
                border: 'none'
            },
            '& .react-datepicker__triangle': {
                display: 'none'
            },
            '& .react-datepicker__navigation--next,.react-datepicker__navigation--previous': {
                width: '24px',
                height: '24px',
                backgroundColor: '#dbdbeb14',
                right: '12px',
                top: '12px',
                borderRadius: '3px',
                backgroundImage: `url(${rightArrow})`
            },
            '& .react-datepicker__navigation--previous': {
                left: '12px',
                backgroundImage: `url(${leftArrow})`
            },
            '& .react-datepicker__day-names': {
                marginTop: '10px',
                marginBottom: '-10px'
            },
            '& .react-datepicker__navigation-icon--previous::before ,.react-datepicker__navigation-icon--next::before': {
                display: 'none'
            },
            '& .react-datepicker__day--selected': {
                backgroundColor: '#696cff'
            },
            '& .react-datepicker__day--outside-month': {
                color: 'rgba(219, 219, 235, 0.38)'
            },
            '& .react-datepicker .react-datepicker__day--keyboard-selected:not(.react-datepicker__day--in-range)': {
                backgroundColor: 'rgba(219, 219, 235, 0.06)'
            },
            '& fieldset,label': {
                borderColor: '#dbdbeb61',
                color: "#dbdbeb61",
            },
            '& .react-datepicker-popper': {
                zIndex: '999'
            },
            '& .react-datepicker__day--in-range': {
                background: '#2e2f4b',
                color: '#696cff',
                borderRadius: 0,
                '&:hover': {
                    background: '#2e2f4b',
                },
            },
            '& .react-datepicker__day--in-selecting-range': {
                background: '#2e2f4b',
                color: '#696cff',
                borderRadius: 0,
                '&:hover': {
                    background: '#2e2f4b',
                },
            },
            '& .react-datepicker__day--range-start': {
                background: '#696cff',
                color: '#fff',
                borderTopLeftRadius: '6px',
                borderBottomLeftRadius: '6px',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                '&:hover': {
                    background: '#696cff',
                },
            },
            '& .react-datepicker__day--range-end': {
                background: '#696cff',
                color: '#fff',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderTopRightRadius: '6px',
                borderBottomRightRadius: '6px',
                '&:hover': {
                    background: '#696cff',
                },
            },
            '& .react-datepicker__day--today': {
                color: '#fff',
                // border: '1px solid #696cff',
                borderRadius: '6px',
            },
            '& .react-datepicker__day--selecting-range-start': {
                background: '#696cff',
                color: '#fff',
                borderTopLeftRadius: '6px',
                borderBottomLeftRadius: '6px',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                '&:hover': {
                    background: '#696cff',
                },
            },
            '& .react-datepicker__day.react-datepicker__day--range-end.react-datepicker__day--in-range': {
                background: '#696cff',
                color: '#fff',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderTopRightRadius: '6px',
                borderBottomRightRadius: '6px',
            },
            '& .react-datepicker__day.react-datepicker__day--range-start.react-datepicker__day--in-range': {
                background: '#696cff',
                color: '#fff',
                borderTopLeftRadius: '6px',
                borderBottomLeftRadius: '6px',
                borderTopRightRadius: '0',
                borderBottomRightRadius: '0',
            },
            '& .react-datepicker__close-icon': {
                right: '5px',
                '&:after': {
                    width: 'unset',
                    height: 'unset',
                    fontSize: '1.5rem',
                    color: '#fff',
                    backgroundColor: 'transparent !important',
                },
            },
            '& .MuiOutlinedInput-root': {
                '&:hover': {
                    '& fieldset': {
                        borderColor: '#dbdbeb61',
                    },
                },
            },
            '& .Mui-focused': {
                '& fieldset': {
                    borderColor: 'rgb(105, 108, 255) !important',
                },
            },
            '& .MuiFormLabel-root': {
                '&.Mui-focused': {
                    color: 'rgb(105, 108, 255) !important',
                },
            },
            '& .react-datepicker-wrapper,.react-datepicker__input-container': {
                display: 'inline-flex',
                width: '100%',
                '& .MuiTextField-root': {
                    width: '100%',
                }
            }
        }}>
            <ReactDatePicker
                selected={dateRange.startDate}
                onChange={onChange}
                startDate={dateRange.startDate}
                endDate={dateRange.endDate}
                selectsRange
                dateFormat="dd/MM/yyyy"
                monthsShown={2}
                customInput={<TextField label={label} />}
                isClearable={true}
            />
        </Box>
    )
};

export default CustomDateRangePicker;