import { Box } from '@mui/material'
import React from 'react'

const CustomPaperBox = ({ children }) => {
    return (
        <Box sx={{
            backgroundColor: '#2b2c40',
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 10px 0px',
            padding: '20px',
            borderRadius: '8px',
            marginTop: '20px',
            color: '#dbdbeb99'

        }}
        >{children}</Box>
    )
}

export default CustomPaperBox
