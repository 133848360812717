import React from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import CustomCheckBox from './style-components/CustomCheckBox';
import './style-components/customautocompletecheckbox.css'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CustomAutoCompleteCheckBox = ({
    options,
    value,
    onChange,
    label,
    placeholder,
    onTextFieldChange,
    multiple = true,
}) => {
    return (
        <Autocomplete
            sx={{
                '& .MuiAutocomplete-poper': {
                    backgroundColor: 'red !important'
                },
                '& fieldset': {
                    borderColor: '#dbdbeb61'
                },
                '& .MuiInputBase-root,label': {
                    color: '#dbdbeb61'
                },
                '& svg': {
                    fill: '#dbdbeb61'
                },
                '& .MuiMenu-list': {
                    // backgroundColor: 'rgb(43, 44, 64) !important'
                },
                "& fieldset:hover": {
                    borderColor: "white !important"
                },
                '& .MuiOutlinedInput-root': {
                    '&:hover': {
                        '& fieldset': {
                            borderColor: '#dbdbeb61',
                        },
                    },
                },
                '& .Mui-focused': {
                    '& fieldset': {
                        borderColor: 'rgb(105, 108, 255) !important',
                    },
                },
                '& .MuiFormLabel-root': {
                    '&.Mui-focused': {
                        color: 'rgb(105, 108, 255) !important',
                    },
                },
                '& .MuiAutocomplete-tag': {
                    backgroundColor: '#696cff',
                    color: '#fff',
                    '& svg': {
                        fill: "#fff"
                    }
                },
                // '& .MuiSvgIcon-root': {
                //     fill: true ? "#d32f2f" : "#dbdbeb61"
                // },
            }}
            multiple={multiple}
            id="checkboxes-tags-demo"
            options={options}
            value={value}
            onChange={(event, newValue) => {
                onChange(newValue);
            }}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option, { selected }) => {
                console.log(selected, "seleted");
                return (
                    <li {...props}>
                        <CustomCheckBox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.label}
                    </li>
                )
            }}

            renderInput={(params) => (
                <TextField {...params} label={label} onChange={onTextFieldChange} placeholder={placeholder} />
            )}
        />
    );
};

export default CustomAutoCompleteCheckBox;
