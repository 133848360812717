import './App.css';
import { Box } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useEffect, useState } from "react";
import LottieLoader from "./view/loader/LottieLoader";
import RouteWrapper from './routes/RouteWrapper';
import CssBaseline from '@mui/material/CssBaseline';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { decryptFn, encryptFn } from './shared/helperFunction';
import { Helmet } from 'react-helmet';

function App() {
    const [showLoader, setShowLoader] = useState(true); // Initialize with an empty string or null

    if (window !== window.top) {
        window.top.location = window.location;
    }

    useEffect(() => {
        // Simulate loading for 2 seconds
        const timer = setTimeout(() => {
            setShowLoader(false);
        }, 1000);

        // Clean up the timer when the component unmounts
        return () => clearTimeout(timer);
    }, []);

    if (showLoader) {
        return (
            <Box sx={{ height: "100vh", backgroundColor: "rgb(35, 35, 51)", textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <LottieLoader />
            </Box>
        )
    };

    return (
        <Box>
            <Helmet>
                <meta http-equiv="Content-Security-Policy" content="frame-ancestors 'none';" />
                <meta http-equiv="X-Frame-Options" content="DENY" />
            </Helmet>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                <CssBaseline>
                    <RouteWrapper />
                    <ToastContainer />
                </CssBaseline>
            </GoogleOAuthProvider>
        </Box>

    );
}

export default App;