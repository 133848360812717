import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Checkbox, Chip, Grid, Menu, MenuItem, TablePagination } from '@mui/material';
import { useState } from 'react';
import CustomInput from './CustomInput';
import CustomButton from './CustomButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CustomChips from './CustomChips';
import SmsIcon from '@mui/icons-material/Sms';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { useEffect } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import CustomTooltip from './CustomTooltip';

function Row(props) {
    const { row, handleAction, collapseTitle, isSelectioEnable, isSelected, handleClick, isCollapse, innerTableData, innerTableHeaders, isActionVisible, columns, index, setSelectedData } = props;
    const [open, setOpen] = useState(false);

    const isRowSelected = isSelected(row.id);

    return (
        <React.Fragment>
            <TableRow key={row.id} sx={{ '& > *': { borderBottom: 'unset' } }}
                selected={isSelectioEnable ? isRowSelected : false}
            >
                {isSelectioEnable && <TableCell sx={{ borderBottom: 0 }} component="th" scope="row" padding="checkbox">
                    <Checkbox onClick={(event) => isSelectioEnable ? handleClick(event, row.id) : null} checked={isRowSelected} />
                </TableCell>}
                {isCollapse && <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>}
                {columns.map((column, index) => {
                    if (column.isChips) {
                        if (column.tableName === "MERCHANT") {
                            return (
                                <TableCell key={index} component="th" scope="row">
                                    <CustomChips label={row[column.id]} color={row[column.id] === "Enable" ? "primary" : "error"} labelColor="primary" />
                                </TableCell>
                            )
                        }
                    } else {
                        return (
                            <>
                                {row[column.id] === "ACTION" ? isActionVisible &&
                                    <TableCell component="th" scope="row" >
                                        <MoreVertIcon onClick={(e) => {
                                            handleAction(e)
                                            setSelectedData(row)
                                        }} sx={{
                                            cursor: 'pointer'
                                        }} />
                                    </TableCell> : <TableCell component="th" scope="row">
                                    {row[column.id]}
                                </TableCell>}
                            </>

                        )
                    }
                })}
            </TableRow >
            {
                isCollapse && <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, padding: 0, backgroundColor: '#36374d' }} colSpan={12}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="h6" gutterBottom component="div" sx={{
                                    textAlign: 'start',
                                    paddingLeft: '15px'
                                }}>
                                    {collapseTitle}
                                </Typography>
                                <Table aria-label="purchases" sx={{

                                }}>
                                    <TableHead>
                                        <TableRow sx={{
                                            '& th:last-child': {
                                                minWidth: { xl: 'auto', lg: 'auto', md: 'auto', sm: 'auto', xs: '150px' },
                                            },
                                            '& th:first-child': {
                                               textAlign: 'left' 
                                            }
                                        }}>
                                            {
                                                innerTableHeaders.map((header) => {
                                                    return (
                                                        <TableCell key={header.id}>{header.label}</TableCell>
                                                    )
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody
                                        sx={{
                                            '& tr:last-child td': {
                                                border: 'none'
                                            },
                                            '& tr': {
                                                '& td:last-child': {
                                                    minWidth: { xl: 'auto', lg: 'auto', md: 'auto', sm: 'auto', xs: '150px' },
                                                }
                                            }
                                        }}

                                    >
                                        {innerTableData[index]?.map((rowData, rowIndex) => (
                                            <TableRow key={rowIndex}>
                                                {innerTableHeaders.map((header) => (
                                                    <TableCell key={header.id}>
                                                        {rowData[header.id]}
                                                    </TableCell>
                                                ))}

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            }
        </React.Fragment >
    );
}

function CustomTable({ isShowRefreshButton, selected, isShowCreateButton = true, setSelected, selectAll, setSelectAll, isShowAllDeleteBtn, handleAllDelete, handleRefreshClick, onSelect, isSelectioEnable = false, searchText, collapseTitle, innerTableHeaders, data, handlePrintInvoiceClick, isShowPrintInvoiceMenu, innerTableData, isShowExportCSV, handleExport, title, setSelectedData, isShowEditMenu, handleEditClick, isShowDeleteMenu, handleDelteClick, isShowChatMenu, handleChatClick, handleOnSearch, searchPlaceholder, createButtonName, columns, balance, isShowBalance, rowsPerPageOptions = [15, 25, 50], isActionVisible = true, isCollapse, isShowSearch = true, handleCreateClick, count, page, rowsPerPage, onRowsPerPageChange, onPageChange, handlePrintClick, isShowPrintMenu, isShowAssignBranger, handleAssignBrangerClick }) {
    const [anchorEl, setAnchorEl] = useState(false);
    const isMenuOpen = anchorEl;

    const handleAction = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangePage = (event, newPage) => {
        onPageChange(newPage + 1);
    };

    const handleChangeRowsPerPage = (event) => {
        onRowsPerPageChange(parseInt(event.target.value, 10))
        onPageChange(1)
    };

    const [displayedData, setDisplayedData] = useState([]);

    useEffect(() => {
        if (data) {
            setDisplayedData(data)
        }
    }, [data]);

    useEffect(() => {
        if(isSelectioEnable){
            if (selected.length === displayedData.length) {
                setSelectAll(true);
            } else {
                setSelectAll(false);
            }
        }
    }, [selected, displayedData, isSelectioEnable]);

    useEffect(() => {
        if(isSelectioEnable){
            setSelected([]);
            setSelectAll(false);
        }
    }, [displayedData?.length,isSelectioEnable])

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = displayedData.map((item) => item.id);
            onSelect(newSelecteds);
            setSelected(newSelecteds);
            setSelectAll(true);
            return;
        }
        setSelected([]);
        setSelectAll(false);
        onSelect([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected?.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
        onSelect(newSelected);
    };

    const isSelected = (id) => selected?.indexOf(id) !== -1;

    return (
        <Box sx={{
            backgroundColor: '#2b2c40',
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 10px 0px',
            borderRadius: '8px',
            marginTop: '30px',

        }}>
            <Box sx={{
                padding: '25px'
            }}>
                <Grid container spacing={2} sx={{
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    {isShowSearch && <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
                        <Box>
                            <CustomInput type="search" value={searchText} placeholder={searchPlaceholder} onChange={handleOnSearch} />
                        </Box>
                    </Grid>}
                    {isShowBalance && <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Box>
                            <Chip
                                sx={{
                                    "& MuiChip-root": {
                                        padding: "17px 0px !important"
                                    },
                                    backgroundColor: "#696cff29",
                                    padding: '22px 0px',
                                    borderRadius: '4px',
                                    marginBottom: "5px",
                                    fontSize: "20px",
                                    '& span': {
                                        padding: '7px 10px',
                                        color: "#696cff",
                                    }
                                }}
                                label={`Balance : ${balance}`} />
                        </Box>
                    </Grid>}

                    <Grid item xl={isShowSearch ? 6 : 12} lg={isShowSearch ? 6 : 12} md={isShowSearch ? 6 : 12} sm={12} xs={12}>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "end",
                            gap: "10px"
                        }}>
                            {isShowExportCSV && <CustomButton style={{ marginRight: "10px" }} buttonName="Export" color="secondary" outline onClick={handleExport} />}
                            {isShowRefreshButton && <CustomTooltip title="Refresh records"><IconButton
                                sx={{
                                    "& svg": { fill: "#fff !important" },
                                    background: "#357a38",
                                    '&:hover': {
                                        backgroundColor: "#357a38 !important"
                                    }
                                }} onClick={handleRefreshClick}><RefreshIcon /></IconButton></CustomTooltip>}
                            {isShowCreateButton && <CustomButton buttonName={createButtonName} color="primary" onClick={handleCreateClick} />}
                            {isShowAllDeleteBtn && <CustomButton buttonName="Delete" color="error" onClick={handleAllDelete} />}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{
                overflow: 'auto'
            }}>
                <Table>
                    <TableHead sx={{
                        '& th': {
                            borderBottomColor: '#dbdbeb1f',
                            textAlign: 'center',
                            color: '#dbdbebde',
                            borderTop: "1px solid #dbdbeb1f"
                        }
                    }}>
                        <TableRow>
                            {isSelectioEnable && displayedData?.length > 0 && <TableCell>
                                <Checkbox
                                    component="th" scope="row"
                                    indeterminate={selected.length > 0 && selected.length < data.length}
                                    checked={selectAll}
                                    onChange={handleSelectAllClick}
                                />
                            </TableCell>}
                            {isCollapse && <TableCell />}
                            {columns.map((column) => (
                                <TableCell key={column.id}>{column.label}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{
                        '& td,th': {
                            borderBottomColor: '#dbdbeb1f',
                            color: '#dbdbeb99',
                            textAlign: 'center',
                            '& svg': {
                                fill: '#dbdbeb99'
                            },
                            // '&:first-of-type': {
                            //     width: '100px',
                            // }
                        },
                    }}>
                        {displayedData?.length === 0 ?
                            <TableRow>
                                <TableCell colSpan={columns?.length + 1}>
                                    <Typography variant="body1" align="center">
                                        No data available.
                                    </Typography>
                                </TableCell>
                            </TableRow> :
                            displayedData.map((row, index) => (
                                <Row isSelectioEnable={isSelectioEnable} isSelected={isSelected} handleClick={handleClick} collapseTitle={collapseTitle} innerTableHeaders={innerTableHeaders} innerTableData={innerTableData} index={index} key={index} setSelectedData={setSelectedData} isActionVisible={isActionVisible} columns={columns} handleAction={handleAction} isCollapse={isCollapse} row={row} />
                            ))
                        }
                    </TableBody>
                </Table>
            </Box>
            <TablePagination
                sx={{
                    color: '#dbdbebde',
                    '& .MuiTablePagination-selectIcon': {
                        fill: "#dbdbebde"
                    }
                }}
                rowsPerPageOptions={false}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={isMenuOpen}
                onClose={handleClose}
                sx={{
                    '& .MuiMenu-paper': {
                        minWidth: "130px",
                        backgroundColor: 'rgb(43, 44, 64)',
                        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 6px -3px, rgba(0, 0, 0, 0.14) 0px 9px 12px 1px, rgba(0, 0, 0, 0.12) 0px 3px 16px 2px',
                        color: "#dbdbebde",
                    }
                }}

                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
            >
                {isShowChatMenu && <MenuItem onClick={handleChatClick}>
                    <SmsIcon sx={{ width: '22px', marginRight: "8px" }} />
                    Chat
                </MenuItem>}

                {isShowEditMenu && <MenuItem onClick={handleEditClick}>
                    <EditIcon sx={{ width: '22px', marginRight: "8px" }} />
                    Edit
                </MenuItem>}

                {isShowPrintMenu && <MenuItem onClick={handlePrintClick}>
                    <PrintIcon sx={{ width: '22px', marginRight: "8px" }} />
                    Print Label
                </MenuItem>}

                {isShowAssignBranger && <MenuItem onClick={handleAssignBrangerClick}>
                    <AssignmentIndIcon sx={{ width: '22px', marginRight: "8px" }} />
                    Assign Branger
                </MenuItem>}

                {isShowPrintInvoiceMenu && <MenuItem onClick={handlePrintInvoiceClick}>
                    <ReceiptIcon sx={{ width: '22px', marginRight: "8px" }} />
                    Print Invoice
                </MenuItem>}

                {isShowDeleteMenu && <MenuItem onClick={handleDelteClick}>
                    <DeleteIcon sx={{ width: '22px', marginRight: "8px" }} />
                    Delete
                </MenuItem>}
            </Menu>
        </Box >
    );
}

export default CustomTable;