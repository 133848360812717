import { Box, FormControl, Grid, InputAdornment } from "@mui/material";
import CustomInput from "../../theme/style-components/CustomInput";
import CustomButton from "../../theme/style-components/CustomButton";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useEffect, useState } from "react";

const CustomPickupAddress = (props) => {
    const { editData, onSubmit, isEdit, handleClose } = props;
    const searchOptions = {
        componentRestrictions: { country: "au" }
    }

    const [error, setError] = useState(null);
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [postcode, setPostcode] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [placeID, setPlaceID] = useState("");
    const [note, setNote] = useState("");
    const [autocompleteAddress, setAutocompleteAddress] = useState("");

    const validate = () => {
        let isInvalid = false;
        let errorNew = {};

        if (address.trim().length === 0) {
            errorNew.address = {
                isError: true,
                text: "Address cannot be empty",
            };

            isInvalid = true;
        }
        if (city.trim().length === 0) {
            errorNew.city = {
                isError: true,
                text: "City cannot be empty",
            };

            isInvalid = true;
        }

        if (country.trim().length === 0) {
            errorNew.country = {
                isError: true,
                text: "Country cannot be empty",
            };

            isInvalid = true;
        }

        if (postcode.trim().length === 0) {
            errorNew.postcode = {
                isError: true,
                text: "Postcode cannot be empty",
            };

            isInvalid = true;
        }

        if (isInvalid) {
            setError(errorNew);
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validate()) {
            let newMerchantAddress = {
                address: address,
                city: city,
                country: country,
                postcode: postcode,
                lat: latitude,
                long: longitude,
                place_id: placeID,
                note: note,
            };
            onSubmit(newMerchantAddress);
        }
    };

    const handleAddressSelect = async (value) => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        let index = 0;
        const addressLength = results[0].address_components.length;
        let postCode = {};
        let cityObject = {};
        let countryObject = {};
        setAutocompleteAddress(value);
        setAddress(value);
        let stateExists = false;
        const myFunction = (item, index, array) => {
            if (array[index].types[0] == "postal_code") {
                postCode = item;
            }
            if (array[index].types[0] == "country") {
                setCountry(item.long_name);
            }
            if (array[index].types[0] == "administrative_area_level_2") {
                stateExists = true;
            }

            if (!stateExists) {
                if (array[index].types[0] == "administrative_area_level_1") {
                    cityObject = item;
                }
            } else {
                if (array[index].types[0] == "administrative_area_level_2") {
                    cityObject = item;
                }
            }
        };
        results[0].address_components.forEach(myFunction);
        setPostcode(postCode.long_name);
        setCity(cityObject.long_name);
        setLatitude(latLng.lat);
        setLongitude(latLng.lng);
        setPlaceID(results[0].place_id);
    };

    //hooks
    useEffect(() => {
        if (isEdit) {
            if (editData) {
                setAddress(editData.address);
                setCity(editData.city);
                setPostcode(editData.postcode);
                setNote(editData.note);
                setLatitude(editData.lat);
                setLongitude(editData.long);
                setPlaceID(editData.place_id);
                setCountry(editData.country);
            }
        }
    }, [isEdit]);

    return (
        <>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <PlacesAutocomplete
                    value={autocompleteAddress}
                    onChange={(e) => {
                        setAutocompleteAddress(e);
                    }}
                    searchOptions={searchOptions}
                    onSelect={(e) => handleAddressSelect(e)}
                >
                    {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                    }) => (
                        <Box>
                            <CustomInput
                                fullWidth
                                label="Search Address"
                                variant="outlined"
                                id="standard-adornment-amount"
                                startAdornment={
                                    <InputAdornment position="start"></InputAdornment>
                                }
                                {...getInputProps({ placeholder: "" })}
                            ></CustomInput>
                            <Box
                                style={
                                    suggestions.length > 0
                                        ? {
                                            position: "absolute",
                                            zIndex: 9999,
                                            backgroundColor: "#2b2c40",
                                            marginRight: "15px",
                                            padding: "15px",
                                            border: "1px solid #2b2c40",
                                            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 6px 4px, rgba(0, 0, 0, 0.14) 0px 9px 12px 1px, rgba(0, 0, 0, 0.12) 0px 3px 16px 2px',
                                            minWidth: "200px",
                                        }
                                        : { display: "none" }
                                }
                            >
                                {loading ? <div>...loading</div> : null}

                                {suggestions.map((suggestion, index) => {
                                    const style = {
                                        backgroundColor: suggestion.active
                                            ? "#dbdbeb0a"
                                            : "#2b2c40",
                                        padding: "10px 10px",
                                    };

                                    return (
                                        <Box key={index}
                                            {...getSuggestionItemProps(suggestion, { style })}
                                        >
                                            {suggestion.description}
                                        </Box>
                                    );
                                })}
                            </Box>
                        </Box>
                    )}
                </PlacesAutocomplete>
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <CustomInput
                    multiline
                    maxRows={4}
                    value={address}
                    label="Address"
                    id="standard-adornment-amount"
                    startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                    }
                    margin="normal"
                    error={error != null && error.address && error.address.isError}
                    helperText={
                        error != null &&
                        error.address &&
                        error.address.isError &&
                        error.address.text
                    }
                    onChange={(e) => {
                        setError(null);
                        setAddress(e.target.value);
                    }}
                />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <CustomInput
                    name="City"
                    label="City"
                    value={city}
                    id="standard-adornment-amount"
                    error={error != null && error.city && error.city.isError}
                    helperText={
                        error != null &&
                        error.city &&
                        error.city.isError &&
                        error.city.text
                    }
                    onChange={(e) => {
                        setError(null);
                        setPostcode(e.target.value);
                    }}
                />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <CustomInput
                    name="Postcode"
                    label="Postcode"
                    type="number"
                    value={postcode}
                    id="standard-adornment-amount"
                    margin="normal"
                    error={
                        error != null && error.postcode && error.postcode.isError
                    }
                    helperText={
                        error != null &&
                        error.postcode &&
                        error.postcode.isError &&
                        error.postcode.text
                    }
                    onChange={(e) => {
                        setError(null);
                        setPostcode(e.target.value);
                    }}
                />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <CustomInput
                    type="text"
                    value={note}
                    name="note"
                    label="Note"
                    id="standard-adornment-amount"
                    startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                    }
                    onChange={(e) => {
                        setNote(e.target.value);
                    }}
                />
            </Grid>

            <Grid item xs={12} gap={2} md={12} >
                <Box sx={{ justifyContent: "end", display: "flex", marginTop: "20px", gap: "10px" }}>
                    <CustomButton
                        onClick={handleSubmit}
                        type="submit"
                        color="primary"
                        buttonName={isEdit ? "Update Address" : "Add Address"}
                    />
                    <CustomButton buttonName="Cancel" color="secondary" onClick={handleClose} />
                </Box >
            </Grid>
        </>
    );
};

export default CustomPickupAddress;