import { toast } from "react-toastify";
import CryptoJS from 'crypto-js';


export const showError = (errors) => {
    console.log("got error", errors);
    if (errors?.errors) {
        const errorProperty = Object.keys(errors?.errors)[0];
        if (errors?.errors[errorProperty].length > 0) {
            const firstError = errors?.errors[errorProperty][0];
            toast.error(`${errorProperty}: ${firstError}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            console.log("No errors found.");
        }
    }
    if (!errors?.message && !errors?.errors) {
        const errorProperty = Object.keys(errors)[0];
        const firstError = errors[errorProperty]?.[0]; // Use optional chaining here
        if (errorProperty && firstError) {
            toast.error(`${errorProperty}: ${firstError}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }
    if (errors?.message) {
        toast.error(errors?.message, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }

}



export const encryptFn = (text) => {
    try {
        if (text) {
            const encrypted = CryptoJS.AES.encrypt(text, process.env.REACT_APP_ENCRYPT_KEY).toString();
            return encrypted;
        } else {
            return '';
        }
    } catch (error) {
        console.error('Encryption error:', error);
        return '';
    }
}

export const decryptFn = (text) => {
    try {
        if (text) {
            // Decryption
            const decryptedBytes = CryptoJS.AES.decrypt(text, process.env.REACT_APP_ENCRYPT_KEY);
            const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);
            return decryptedString;
        } else {
            return '';
        }
    } catch (error) {
        console.error('Decryption error:', error);
        return '';
    }
};

export const removeRepeatedWordsWithCommas = (str) => {
   // Split by commas and spaces, keeping them as separate parts
   const parts = str.split(/(\s*,\s*|\s+)/);
   const seen = new Set();
   const result = [];
   let currentPart = '';
 
   for (const part of parts) {
     // Check if the part is a delimiter or non-empty
     if (part.match(/[\s,]/)) {
       if (currentPart.trim()) {
         result.push(currentPart.trim());
         currentPart = '';
       }
       result.push(part);
     } else {
       // Normalize and check for duplicates
       const normalizedPart = part.toLowerCase();
       if (!seen.has(normalizedPart)) {
         seen.add(normalizedPart);
         currentPart += part; // Accumulate non-delimiter parts
       }
     }
   }
 
   // Add any remaining part
   if (currentPart.trim()) {
     result.push(currentPart.trim());
   }
 
   // Join the result and clean up extra commas or spaces
   let cleanedResult = result.join('');
   
   // Remove extra commas
   cleanedResult = cleanedResult.replace(/,\s*,+/g, ','); // Remove consecutive commas
   cleanedResult = cleanedResult.replace(/,\s*$/, ''); // Remove trailing comma
 
   // Clean up extra spaces
   cleanedResult = cleanedResult.replace(/\s{2,}/g, ' ').trim();
 
   return cleanedResult;
  }
