import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { deletePackages, selectPackages, setPackages } from "../../store/orders/orderSlice";
import { selectCurrentUserType } from "../../store/auth/authSlice";
import { useDeletePackageMutation, useGetAddressBookQuery, useGetMyOrderQuery, useGetOrderQuery, useLazyGetMyOrderQuery, useLazyGetOrderCSVTemplateQuery, usePostPackageCSVMutation } from "../../store/orders/orderApiSlice";
import { useLazyGetQuoteQuery, usePayOtherFeesMutation } from "../../store/payments/paymentsApiSlice";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Box, Grid, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import CustomPaperBox from "../../theme/style-components/CustomPaperBox";
import { AddCircle } from "@mui/icons-material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomButton from "../../theme/style-components/CustomButton";
import CustomModal from "../../theme/style-components/CustomModal";
import CreatePackage from "./CreatePackage";
import { showError } from "../../shared/helperFunction";

const Packages = (props) => {
    //route parameters
    let { orderID } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    //redux logic
    const packages = useSelector(state => state.orders.packages);

    const dispatch = useDispatch();
    const userType = useSelector(selectCurrentUserType);
    //state variables
    const [showAddPackageDialog, setShowAddPackageDialog] = useState(false);
    const [variableOrderID, setVariableOrderID] = useState(0);
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isPackageEditing, setIsPackageEditing] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState({});
    const [quoteData, setQuoteData] = useState({})
    const [showPayButton, setShowPayButton] = useState(false);
    const [otherFees, setOtherFees] = useState('')
    const [otherFeesComment, setOtherFeesComment] = useState('');

    const [addressBooksData, setAddressBooksData] = useState([]);


    const {
        data: addressBooks,
        isLoading: isGetAddressBookLoading,
        isSuccess: isGetAddressBookSuccess,
        isError: isGetAddressBookError
    } = useGetAddressBookQuery();

    useEffect(() => {
        if (addressBooks?.data?.length > 0) {
            setAddressBooksData(addressBooks?.data)
        }
    }, [addressBooks])

    //base query hooks
    const [getQuote, {
        isLoading: isQuoteLoading, data: quote, isSuccess: isQuoteSuccess, isError, error
    }] = useLazyGetQuoteQuery();

    const {
        isLoading: isOrderLoading, data: orders, isSuccess: isOrderSuccess, isError: isOrderError, error: orderError, refetch: refetchOrder
    } = useGetOrderQuery(orderID);

    const {
        isLoading: isMyOrderLoading,
        data: myOrderReturned,
        isSuccess: isMyOrderSuccess,
        isError: isMyOrderError,
        error: myOrderError, refetch: refetchMyOrder,
    } = useGetMyOrderQuery({ page: 1, query: `&order_id=${orderID}` });

    useEffect(() => {
        if (quoteData) {
            if (userType === "ADMIN") {
                refetchOrder()
            } else {
                refetchMyOrder()
            };
        };
    }, [quote, quoteData, userType])

    useEffect(() => {
        if (packages?.length) {
            setVariableOrderID(0)
        }
    }, [packages])

    useEffect(() => {
        if (userType === "MERCHANT") {
            if (!isMyOrderLoading && !isMyOrderError && myOrderReturned.data[0]?.packages) {
                dispatch(setPackages(myOrderReturned.data[0]?.packages));
                return;
            }
        }
        if (userType === "ADMIN" && orders?.data[0]?.packages) {
            dispatch(setPackages([...orders?.data[0]?.packages]));
        }

        if (userType === "ADMIN") {
            if (!isOrderLoading && orders?.data[0]?.packages) {
                dispatch(setPackages(orders?.data[0]?.packages));
                return;
            }
        }
    }, [isOrderLoading, orders, isMyOrderLoading, myOrderReturned, isMyOrderError, userType]);


    //methods
    const handleGetQuote = async () => {
        const quoteResponse = await getQuote({ order: orderID }).unwrap().then((res) => {
            if (res) {
                setQuoteData(res)
                setShowPayButton(true);
            }
        }).catch((err) => {
            if (err) {
                if (err) {
                    setShowPayButton(false);
                    showError(err?.data)
                }

            }
        })
    };

    const handlePaymentClicked = () => {
        navigate(`/orders/packages/pay/${orderID}`);
    };

    const [postPackageCSV] = usePostPackageCSVMutation();
    const [deletePackage] = useDeletePackageMutation();
    const [getMyOrder] = useLazyGetMyOrderQuery();
    const [getOrderCSVTemplate, { isFetching: isCsvLoading }] = useLazyGetOrderCSVTemplateQuery();

    const handleImportCSV = async (e) => {
        if (e.target.files && e.target.files[0]) {
            let payload = new FormData();
            payload.append("order_id", orderID);
            payload.append("csv_packages", e.target.files[0]);
            try {
                const result = await postPackageCSV(payload).unwrap().then((res) => {
                    if (res) getMyOrder({ page: 1, query: `&order_id=${orderID}` }).unwrap().then((res) => {
                        refetchOrder();
                        if (res && res?.data[0]?.packages) dispatch(setPackages([...res?.data[0]?.packages]));
                    })
                });
                toast.success("Packages added!!", {
                    position: toast.POSITION.TOP_RIGHT
                });
            } catch (error) {
                toast.error(`Something went wrong!!`, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    };

    const callDeletePackage = async (id) => {
        const response = await deletePackage({ package_id: id }).unwrap().then((res) => {
            if (res) {
                dispatch(deletePackages(id));
                setShowPayButton(false);
                setQuoteData({});
            }
            res && toast.success("Packages deleted!!", {
                position: toast.POSITION.TOP_RIGHT
            });
            setMenuAnchor(null);
            setIsMenuOpen(false);
        }).catch((err) => {
            err && toast.error(err.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        });
    }

    const TotalAmountWithoutGST = packages?.length > 0 && packages?.reduce((acc, curr) => acc + curr?.quote_distribution?.amount, 0);

    const handleDownloadCSVTemplate = async () => {
        try {
            const templateData = getOrderCSVTemplate().unwrap().then((res) => {
                const blob = new Blob([res], { type: 'text/csv' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = "order-template.csv";
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
            }).catch((err) => {
                err && toast.error("Something went wrong", {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
        } catch (err) {
            err && toast.error("Something went wrong", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    };

    const [otherFeesError, setOtherFeesError] = useState(false);
    const [otherFeesCommentError, setOtherFeesCommentError] = useState(false);
    const [payOtherFees, { isLoading: payOtherFeesLoading }] = usePayOtherFeesMutation();

    const handleAddOtherFees = async () => {
        if (!otherFees) {
            setOtherFeesError(true);
        } else if (!otherFeesComment) {
            setOtherFeesCommentError(true);
        } else {
            let response = await payOtherFees({ order_id: orderID, other_fees_cents: otherFees * 100, comment: otherFeesComment }).unwrap().then((res) => {
                if (res) {
                    toast.success(`${res.message}`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }).catch((err) => {
                if (err) {
                    let errorMessage;
                    for (const key in err.data.errors) {
                        if (Array.isArray(err.data.errors[key]) && err.data.errors[key].length > 0) {
                            errorMessage = err.data.errors[key][0];
                            break;
                        }
                    }
                    if (errorMessage) {
                        toast.error(errorMessage, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }
            });
        }
    };

    const orderStatus = orders?.data[0]?.order_status

    const inputCSVRef = useRef(null)

    return (
        <Box >
            <CustomPaperBox
                style={{ padding: "1rem" }}
                elevation={5}

            >
                <Grid style={{ padding: "1rem" }} container spacing={2}>
                    <Grid
                        style={{
                            display: "flex", alignContent: "center", justifyContent: "center", alignItems: "center"
                        }}
                        item
                        xs={12}
                        md={4}
                    >
                        <h3>Add Deliveries</h3>
                    </Grid>
                    <Grid
                        item
                        style={{
                            display: "flex", alignContent: "center", justifyContent: "center", alignItems: "center"
                        }}
                        xs={12}
                        md={4}
                    >
                        <Box sx={{ justifyContent: "center", display: "flex" }}>
                            <AddCircle cursor="pointer" onClick={() => {
                                setIsPackageEditing(false);
                                setShowAddPackageDialog((prevState) => !prevState);
                            }} fontSize="large" />
                        </Box>
                    </Grid>
                    {packages.length !== 0 && packages.map((pack, index) => (<>
                        <Box sx={{
                            border: "1px solid #dbdbeb61",
                            borderRadius: "10px",
                            margin: "15px",
                            display: "flex",
                            justifyContent: 'space-between',
                            alignItems: "center",
                            width: '100%',
                            padding: '15px'
                        }}>
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: '15px',
                            }}>
                                <Box>
                                    <Typography variant="span"
                                        sx={{
                                            border: "1px solid #dbdbeb61",
                                            width: "5rem",
                                            height: "5rem",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            pl: 3,
                                            p: 2,
                                            m: 1
                                        }}
                                    >
                                        {pack.dimension === 1 && "upto 1kg"}
                                        {pack.dimension === 2 && "upto 5kg"}
                                        {pack.dimension === 3 && "upto 10kg"}
                                    </Typography>
                                </Box>

                                <Box>
                                    {!pack.drop_address.lat && !pack.drop_address.long &&
                                        <Box sx={{
                                            color: "red"
                                        }}>Address is invalid please update the address</Box>}
                                    {pack.contact_name} <br /> {pack.contact_email} <br />
                                    {pack.contact_phone} <br />
                                    {pack.drop_address && pack.drop_address.address} <br />
                                    {pack.contact_note} <br />
                                    <div>{pack.quote_distribution && `Amount: AUD  ${(pack.quote_distribution.amount / 100).toFixed(2)}`} </div>
                                    <div>{pack.quote_distribution && `Distance: ${(pack.quote_distribution.distance / 1000).toFixed(2)} Km`} </div>
                                </Box>
                            </Box>
                            <Box>
                                <MoreHorizIcon cursor="pointer" onClick={(e) => {
                                    setMenuAnchor(e.currentTarget);
                                    setSelectedPackage(pack);
                                    setIsMenuOpen(true);
                                }} />
                            </Box>
                        </Box>
                    </>))}
                    <Menu
                        id="basic-menu"
                        anchorEl={menuAnchor}
                        anchorOrigin={{ vertical: "top", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                        open={isMenuOpen}
                        onClose={() => {
                            setMenuAnchor(null);
                            setIsMenuOpen(false);
                        }}
                        sx={{
                            '& .MuiMenu-paper': {
                                minWidth: "130px",
                                backgroundColor: 'rgb(43, 44, 64)',
                                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 6px -3px, rgba(0, 0, 0, 0.14) 0px 9px 12px 1px, rgba(0, 0, 0, 0.12) 0px 3px 16px 2px',
                                color: "#dbdbebde",
                            }
                        }}

                        MenuListProps={{
                            'aria-labelledby': 'lock-button',
                            role: 'listbox',
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                setIsPackageEditing(true);
                                setShowAddPackageDialog(prevState => !prevState);
                            }}
                        >
                            Edit
                            <EditIcon
                                style={{ cursor: "pointer", marginLeft: "auto" }}
                            />
                        </MenuItem>
                        <MenuItem onClick={() => callDeletePackage(selectedPackage?.id)}>
                            Delete
                            <DeleteIcon
                                style={{ cursor: "pointer", marginLeft: "auto" }}
                            />
                        </MenuItem>

                    </Menu>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {quoteData?.data?.distribution[0] ?
                            <Box sx={{
                                maxWidth: "400px",
                                width: "100%",
                                marginLeft: "auto"
                            }}>

                                <Box sx={{ marginBottom: "15px" }}>
                                    <Box sx={{
                                        background: "#696cff !important",
                                        padding: "6px 10px",
                                        textAlign: "center",
                                        "& p": {
                                            color: "#fff"
                                        }
                                    }}>
                                        <Typography>Current plan - BUSINESS Standard</Typography>
                                    </Box>

                                    <TableContainer sx={{ marginBottom: "15px" }}>
                                        <Table aria-label="simple table">
                                            <TableHead sx={{
                                                '& th': {
                                                    color: '#dbdbeb99',
                                                    borderBottomColor: '#dbdbeb1f',
                                                }
                                            }}>
                                                <TableRow>
                                                    <TableCell
                                                        sx={{ lineHeight: "normal", paddingTop: "6px", paddingBottom: "6px" }}>Name</TableCell>
                                                    <TableCell sx={{ lineHeight: "normal", paddingTop: "6px", paddingBottom: "6px" }}
                                                        align="right">Value</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody sx={{
                                                '& td,th': {
                                                    borderBottomColor: '#dbdbeb1f',
                                                    color: '#dbdbeb99',
                                                    '& td,th': {
                                                        borderBottomColor: '#dbdbeb1f',
                                                        color: '#dbdbeb99',
                                                        textAlign: 'center',
                                                        '& svg': {
                                                            fill: '#dbdbeb99'
                                                        },
                                                    }
                                                }
                                            }}>
                                                <TableRow
                                                    sx={{
                                                        "&:last-child td, &:last-child th": { border: 0, background: "#ffc36a", fontWeight: "bold" }
                                                    }}
                                                >
                                                    <TableCell sx={{ lineHeight: "normal", paddingTop: "6px", paddingBottom: "6px" }} component="th"
                                                        scope="row">
                                                        Surcharge
                                                    </TableCell>
                                                    <TableCell sx={{ lineHeight: "normal", paddingTop: "6px", paddingBottom: "6px" }}
                                                        align="right">{`AUD ${((quote?.data?.distribution[0]?.surcharge_amount) / 100).toFixed(2)}`}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ lineHeight: "normal", paddingTop: "6px", paddingBottom: "6px" }} component="th"
                                                        scope="row">
                                                        GST
                                                    </TableCell>
                                                    <TableCell sx={{ lineHeight: "normal", paddingTop: "6px", paddingBottom: "6px" }}
                                                        align="right">{`AUD ${((quote?.data?.distribution[0]?.gst_amount) / 100).toFixed(2)}`}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ lineHeight: "normal", paddingTop: "6px", paddingBottom: "6px" }} component="th"
                                                        scope="row">
                                                        Other Fees Amount
                                                    </TableCell>
                                                    <TableCell sx={{ lineHeight: "normal", paddingTop: "6px", paddingBottom: "6px" }}
                                                        align="right">{`AUD ${((quote?.data?.distribution[0]?.other_fees_amount) / 100).toFixed(2)}`}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ lineHeight: "normal", paddingTop: "6px", paddingBottom: "6px" }} component="th"
                                                        scope="row">
                                                        Total Without GST
                                                    </TableCell>
                                                    <TableCell sx={{ lineHeight: "normal", paddingTop: "6px", paddingBottom: "6px" }}
                                                        align="right">{`AUD ${((TotalAmountWithoutGST) / 100).toFixed(2)}`}</TableCell>
                                                </TableRow>
                                                <TableRow sx={{
                                                    "&:last-child td, &:last-child th": { border: 0, background: "#ffab00 !important", fontWeight: "bold" }
                                                }}>
                                                    <TableCell sx={{ lineHeight: "normal", paddingTop: "6px", paddingBottom: "6px", color: "#fff !important" }} component="th"
                                                        scope="row">
                                                        Total({`${quote?.data?.package_quotes?.length} Delivery`})
                                                    </TableCell>
                                                    <TableCell sx={{ lineHeight: "normal", paddingTop: "6px", paddingBottom: "6px", color: "#fff !important" }}
                                                        align="right">{`AUD ${((quote?.data?.distribution[0]?.total_charge_amount) / 100).toFixed(2)}`}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                {/* <h2>Quote: AUD {isQuoteSuccess ? quote.data.quote / 100 : "No quote yet"}</h2>  <h2>Subscription: </h2>*/}

                            </Box> : ""}
                    </Grid>
                </Grid>
                <Box mt={8}>
                    <Grid containter spacing={3} >
                        <Grid
                            style={{ display: "flex", justifyContent: "space-between", }}
                            item
                            xs={12}
                            md={12}
                        >
                            <Grid style={{ display: "flex", justifyContent: "start" }}
                                item
                                gap={2}
                                xs={6}
                                md={6}>
                                <CustomButton
                                    color="primary"
                                    onClick={() => inputCSVRef.current.click()}
                                    buttonName="Import CSV"
                                />
                                <input type="file" ref={inputCSVRef} onChange={(e) => handleImportCSV(e)} id="csvUpload" hidden accept=".csv"></input>
                                <CustomButton
                                    color="primary"
                                    disabled={isCsvLoading}
                                    isLoading={isCsvLoading}
                                    onClick={handleDownloadCSVTemplate}
                                    buttonName="Download CSV Template"
                                />
                            </Grid>

                            <Grid style={{ display: "flex", justifyContent: "end" }}
                                item
                                gap={2}
                                xs={6}
                                md={6}>
                                <CustomButton
                                    color="primary"
                                    onClick={handleGetQuote}
                                    buttonName="Get Quote"
                                    isLoading={isQuoteLoading}
                                    disabled={isQuoteLoading}
                                />
                                {showPayButton && <CustomButton
                                    color="primary"
                                    onClick={handlePaymentClicked}
                                    buttonName="Proceed To Pay"
                                />}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </CustomPaperBox>
            <CustomModal maxWidth="md"
                title="Add Deliveries"
                isModalOpen={showAddPackageDialog}
                isShowActionButton={false}
                handleClose={() => setShowAddPackageDialog(false)}
            >
                <CreatePackage resetOrderID={() => {
                    // setVariableOrderID(0);
                }}
                    orderStatus={orderStatus}
                    addressBooksData={addressBooksData}
                    orderID={orderID} setQuoteData={setQuoteData}
                    setShowAddPackageDialog={setShowAddPackageDialog}
                    isPackageEditing={isPackageEditing}
                    setIsPackageEditing={setIsPackageEditing}
                    selectedPackage={selectedPackage ? selectedPackage : null}
                    refetchMyOrder={refetchMyOrder}
                    refetchOrder={refetchOrder} setShowPayButton={setShowPayButton}
                    setIsMenuOpen={setIsMenuOpen}
                />
            </CustomModal>
        </Box >);
};

export default Packages;