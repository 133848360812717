import { Box, Button, Grid, TextField } from "@mui/material";
import { useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import Compressor from "compressorjs";
import { AddCircle, CloudUpload, Delete } from "@mui/icons-material";
import CustomInput from "../../theme/style-components/CustomInput";
import CustomButton from "../../theme/style-components/CustomButton";


const AddAttachment = (props) => {
  const { handleAddAttachmentSubmit, isAddAttachmentLoading } = props;
  const [formValue, setFormValue] = useState({
    comment: "",
    attachment_file: null
  });

  const [error, setError] = useState(false);

  const inputRef = useRef(null);


  const handleSubmit = () => {
    if (!formValue.attachment_file) {
      setError(true);
    } else {
      handleAddAttachmentSubmit(formValue);
    }
  }
  const compressImage = (file) => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        maxWidth: 1920,
        maxHeight: 1080,
        maxSize: 3 * 1024, // max size in bytes (3 MB)
        success: (compressedBlob) => {
          const compressedFile = new File([compressedBlob], file.name, {
            type: file.type,
            lastModified: file.lastModified,
          });
          resolve(compressedFile);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0]; // Assuming you're handling file input change event
    setError(false);
    if (!file) {
      return;
    };
    try {
      const compressedFile = await compressImage(file);
      if (compressedFile) {
        setFormValue((pre) => ({ ...pre, attachment_file: compressedFile }))
      }
    } catch (error) {
      console.error('Error compressing image:', error);
    }
  }

  const handleCommentChange = (e) => {
    setFormValue((pre) => ({ ...pre, comment: e.target.value }))
  }

  return (
    <>
      <Grid item xs={12} md={12} lg={12} sm={12}>
        <Box sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          border: "1px solid #dbdbeb61",
          padding: "14px 10px",
          borderRadius: "4px",
          color: "#dbdbebde",
        }}>
          <Typography>Attachment</Typography>
          <Typography>{formValue.attachment_file ? formValue.attachment_file.name : "Not Uploaded"}</Typography>
          <input
            type="file"
            ref={inputRef}
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <CloudUpload
            cursor="pointer"
            onClick={() => {
              inputRef.current.click();
              // setCurrentDocument(document.key);
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={12} lg={12} sm={12}>
        <CustomInput label="Comment" value={formValue.comment} onChange={handleCommentChange} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Box sx={{
          textAlign: 'center',
          paddingTop: '32px'
        }}>
          <Box>
            <CustomButton onClick={handleSubmit} disabled={isAddAttachmentLoading} isLoading={isAddAttachmentLoading} buttonName="Upload Attachment" color="primary" />
          </Box>
        </Box>
      </Grid>
    </>
  )
};

export default AddAttachment;