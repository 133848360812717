import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { apiSlice } from '../store/apiSlice';
import authReducer from '../store/auth/authSlice';
import merchantReducer from '../store/merchant/merchantSlice';
import brangersReducer from "../store/brangers/brangerSlice"
import { persistReducer, persistStore } from 'redux-persist';
import persistConfig from './persistConfig';
import attachmentReducer from "../store/attachments/attachmentsSlice";
import orderReducer from "../store/orders/orderSlice";
import addressBookReducer from "../store/address-book/addressBookSlice";
import mapReducer from "../store/map/mapSlice";
import refundReducer from "../store/refund/refundSlice";
import externalTransactionsReducer from "../store/external-transaction/externalTransationsSlice"

const rootReducer = combineReducers({
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    merchant: merchantReducer,
    brangers: brangersReducer,
    attachments: attachmentReducer,
    orders: orderReducer,
    addressBook: addressBookReducer,
    refundList: refundReducer,
    externalTransactions: externalTransactionsReducer,
    map: mapReducer
});


const persistedReducer = persistReducer(
    persistConfig,
    rootReducer
);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({ serializableCheck: false }).concat(apiSlice.middleware),
    devtools: true,
});

export const persistor = persistStore(store);