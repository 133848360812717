import { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItemText,
  Paper,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// import useAuth from "../../hooks/useAuth";
import { useNavigate, useLocation, Form, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setCredentials, setUser, setUserType } from "../../store/auth/authSlice";
import {
  useForgetPasswordMutation,
  useLoginMutation, useLoginWithGoogleMutation, useSignupMutation
} from "../../store/auth/authApiSlice";
import secureLocalStorage from "react-secure-storage";
import { Box } from "@mui/material";
import { AppBar } from "@mui/material";
import { Toolbar } from "@mui/material";
import { ReactComponent as BrangLogo } from "../../assets/logos/BrangLogo.svg";
import MenuIcon from '@mui/icons-material/Menu';
import loginBg from "../../assets/customer-img.jpg";
import { useLazyGetUserProfileQuery } from "../../store/users/usersApiSlice";
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import styled from "@emotion/styled";
import Checkbox from '@mui/material/Checkbox';
import CustomButton from "../../theme/style-components/CustomButton";
import CustomCheckBox from "../../theme/style-components/CustomCheckBox";
import CustomInput from "../../theme/style-components/CustomInput";
import * as yup from "yup";
import { useFormik } from "formik";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { decryptFn, encryptFn, showError } from "../../shared/helperFunction";

const LoginAppBar = styled(AppBar)`
  background: transparent;
  box-shadow: none;
  position: fixed;
  top: 0;
  .MuiToolbar-root {
    background-color: rgb(35, 35, 51);
    color: #dbdbeb99 important
  }
  &::after {
    content: "";
    background: #FFFF;
    width: 58.333333%;
    height: 64px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }
`;

const CustomToolBar = styled(Toolbar)`
    justify-content: space-between;
`;

const MenuList = styled(Box)`
    display: flex;
    text-align: center;
    gap: 20px;
`;

const CustomList = styled(List)`
    display: flex;
    text-align: center;
    color: #000;
    gap: 30px;
`;

const CustomListItemText = styled(ListItemText)`
    font-weight: 500;
    color:#dbdbebde;
`;

const MainBg = styled(Box)`
    height: 100%;
    position: relative;
`;

const MainBgImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position:top;
`;

const CustomerBox = styled(Box)`
    position: absolute;
    bottom: 30px;
    left: 44px;
`;
const CustomerHeading = styled(Typography)`
    font-weight: 500;
    font-size: 58.2602px;
    margin-bottom: 7px;
    color: #fff;
`;

const CustomerDetails = styled(Typography)`
    font-size: 11.9378px;
    line-height: 179.3%;
    margin-bottom: 49px;
    color: #fff;
    max-width: 410px;
    width: 100%;
`;

const BrangName = styled(Typography)`
    font-size: 17.561px;
    line-height: 13px;
    color: #fff;
    margin-bottom: 5px;
`;

const BrangQuote = styled(Typography)`
    font-size: 13.1572px;
    line-height: 125.3%;
    color: #fff;
    margin-bottom: 0;
`;

const LoginFormBox = styled(Box)`
    max-width: 486px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    height: calc(100% - 15px);
    display: flex;
    align-items: center;
    justify-content: center;
    //height: 100%;
    text-align: center;
`;

const FormHeading = styled(Typography)`
    font-size: 59.6211px;
    line-height: 120%;
    color: #dbdbebde;
`;

const FormHeadingDetails = styled(Typography)`
  color: #dbdbeb99;
  font-weight: 500;
  font-size: 22.3579px;
  margin-bottom: 30px;
`;

const CustomInputLabel = styled(InputLabel)`
  text-align: left;
  color: #dbdbeb99;
  font-weight: 400;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom:10px;
`;

const RememberLabel = styled(InputLabel)`
  text-align: left;
  color:#dbdbeb99;

`;

// const CustomInput = styled(TextField)`
//   border: 1.24211px solid #D7D7D7 !important;
//   box-shadow: 0px 1.24211px 2.48421px rgba(0, 0, 0, 0.08) !important;
//   border-radius: 10px !important;
//   color:#dbdbeb99;
// `;

const RememberMeBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CheckRememberMe = styled(Box)`
    display: flex;
    align-items: center;
`;

const ForgotButton = styled(Button)`
  font-weight: 400;
  font-size: 16px;
  color:#696cff;
  text-transform: capitalize;
`;

const LoginButton = styled(Button)`
  height: 60px;
  background: #dbdbeb99;
  box-shadow: 0px 1.24211px 2.48421px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const Login = (props) => {
  const location = useLocation();

  //API
  const [login, { isLoading }] = useLoginMutation();
  const [signup, { isLoading: isSignupLoading }] = useSignupMutation();
  const [forgetPassword, { isLoading: isForgotLoading }] = useForgetPasswordMutation();
  const [getUserProfile] = useLazyGetUserProfileQuery();
  const [loginWithGoogle] = useLoginWithGoogleMutation();
  const [passwordShow, setPasswordShow] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isForget, setIsForget] = useState(false);
  const [isShowSignup, setIsShowSignup] = useState(false);
  const [isRemember, setIsRemember] = useState(true);

  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Plase enter a valid email")
      .required("Please enter a email"),
    password: yup
      .string()
      .required("Please enter a password")
  });

  const signupValidationSchema = yup.object({
    email: yup
      .string()
      .email("Plase enter a valid email")
      .required("Please enter a email"),
    name: yup
      .string()
      .required("Please enter a name")
  });

  const forgotValidationSchema = yup.object({
    forgotEmail: yup
      .string()
      .email("Plase enter a valid email")
      .required("Please enter a email"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const { email, password } = values;
      try {
        const userData = await login({ email, password }).unwrap();
        localStorage.setItem("refreshToken", encryptFn(userData.refresh));
        localStorage.setItem("accessToken", encryptFn(userData.access));
        localStorage.setItem("email", email);
        localStorage.setItem("isAuthenticated", true);
        secureLocalStorage.setItem("password", password);
        try {
          await getUserProfile()
            .unwrap()
            .then((res) => {
              if (res) {
                dispatch(setCredentials({ ...userData, email }));
                localStorage.setItem("userType", res.data.user_type);
                dispatch(setUserType(res.data.user_type));
                dispatch(setUser(res.data));
              }

              if (res.data.user_type === "MERCHANT") {
                navigate("/dashboard", { replace: true });
              } else {
                navigate("/map", { replace: true });
              }
            });
        } catch (err) {
          console.log(err);
        }
        toast.success("You are logged in!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } catch (error) {
        if (error) {
          toast.error("Wrong username or password", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    }
  });

  const signupFormik = useFormik({
    initialValues: {
      name: "",
      email: "",
      generated_from: "fredit"
    },
    validationSchema: signupValidationSchema,
    onSubmit: async (values) => {
      const { email, name, generated_from } = values;
      try {
        const response = await signup({ name, email, generated_from }).unwrap();
        if (response) {
          toast.success(response?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (err) {
        if (err) {
          showError(err?.data);
        }
      }
    }
  });

  const forgotFormik = useFormik({
    initialValues: {
      forgotEmail: "",
    },
    validationSchema: forgotValidationSchema,
    onSubmit: async (values) => {
      const { forgotEmail } = values;
      try {
        const userData = await forgetPassword({ email: forgotEmail }).unwrap();
        toast.success("Email sent successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsForget(!isForget);
      } catch (err) {
        showError(err?.data);
      }
    }
  });

  useEffect(() => {
    const localEmail = localStorage.getItem("email");
    const localPassword = secureLocalStorage.getItem("password");
    if (localEmail && localPassword) {
      formik.setFieldValue("email", localEmail);
      formik.setFieldValue("password", localPassword);
    }
  }, []);

  const handleLoginWithGoogle = async (token) => {
    try {
      const userProfile = await loginWithGoogle(token).unwrap().then((res) => {
        if (res) {
          navigate("/map")
        }
      });
      toast.success("Login successful!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.log("Something went wrong!");
      if (error?.data) {
        toast.error(error.data.non_field_errors[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  // Now you can access various properties of the location object
  const { pathname, search, hash, state } = location;

  const googleLogin = useGoogleLogin({
    // flow: 'auth-code',
    scope: "https://www.googleapis.com/auth/userinfo.email",
    onSuccess: tokenResponse => {
      if (tokenResponse?.access_token) {
        handleLoginWithGoogle(tokenResponse?.access_token)
      }
    },
    onError: () => {
      console.log('Login Failed');
    },
  });

  const handleRemember = (event) => {
    setIsRemember(event.target.checked);
  };

  console.log(isForget, isShowSignup, "!isForget && !isShowSignup");
  return (
    <Box sx={{
      backgroundColor: 'rgb(35, 35, 51)',
      color: "#dbdbeb99 !important"
    }}>

      {/* <Box sx={{
        flexGrow: 1,
        display: { xl: 'block', lg: 'block', md: 'block', sm: 'none', xs: 'none' }
      }}>
        <LoginAppBar position="static">
          <CustomToolBar>
            <Box lignheight="0">
              <BrangLogo />
            </Box>
            <MenuList>
              <CustomList>
                <CustomListItemText>Login</CustomListItemText>
                <CustomListItemText>
                  Register
                </CustomListItemText>
              </CustomList>
              <IconButton
                size="large"
                edge="start"
                color="#000"
                aria-label="menu"
                sx={{ mr: 2, color: '#dbdbebde' }}
              >
                <MenuIcon />
              </IconButton>
            </MenuList>
          </CustomToolBar>
        </LoginAppBar>
      </Box> */}

      <Grid container sx={{
        height: 'calc(100vh - 7px)'
      }}>
        <Grid item lg={5} md={5} sm={12} xs={12} sx={{
          height: '100%',
          display: { xl: 'block', lg: 'block', md: 'block', sm: 'none', xs: 'none' }
        }}>
          <MainBg>
            <MainBgImage src={loginBg} />
            <CustomerBox>
              <BrangName variant="h4">
                BrangChain
              </BrangName>
            </CustomerBox>
          </MainBg>
        </Grid>
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <LoginFormBox>
            {!isForget ? isShowSignup ? <form>
              <FormHeading variant="h2" sx={{
                fontSize: { xl: '60px', lg: '60px', md: '40px', sm: '30px', xs: '30px' }
              }}>
                Welcome back
              </FormHeading>
              <FormHeadingDetails sx={{
                fontSize: { xl: '22px', lg: '22px', md: '20px', sm: '16px', xs: '16px' }
              }}>
                Please enter your contact details to connect.
              </FormHeadingDetails>
              {search === "?fredit" && <Box display="flex" gap="2" justifyContent="center" alignItems="center">
                <Button fullWidth sx={{ backgroundColor: !isForget && !isShowSignup && "#1976d20a !important" }} onClick={() => {
                  setIsForget(false);
                  setIsShowSignup(false);
                }}>Login</Button>
                <Button fullWidth sx={{ backgroundColor: !isForget && isShowSignup && "#1976d20a !important" }} onClick={() => {
                  setIsForget(false);
                  setIsShowSignup(true);
                }}>Signup</Button>
              </Box>}
              <CustomInputLabel>Name</CustomInputLabel>
              <CustomInput
                id="name"
                variant="outlined"
                margin="normal"
                name="name"
                required
                fullWidth
                value={signupFormik.values.name}
                onChange={signupFormik.handleChange}
                error={
                  signupFormik.touched.name &&
                  Boolean(signupFormik.errors.name)
                }
                helperText={
                  signupFormik.touched.name && signupFormik.errors.name
                }
              />
              <CustomInputLabel>Email</CustomInputLabel>
              <CustomInput
                id="email"
                variant="outlined"
                margin="normal"
                name="email"
                required
                fullWidth
                value={signupFormik.values.email}
                onChange={signupFormik.handleChange}
                error={
                  signupFormik.touched.email &&
                  Boolean(signupFormik.errors.email)
                }
                helperText={
                  signupFormik.touched.email && signupFormik.errors.email
                }
              />
              <RememberMeBox>
                <CheckRememberMe>
                  <CustomCheckBox />
                  <RememberLabel htmlFor="persist" >Remember me</RememberLabel>
                </CheckRememberMe>
                <ForgotButton
                  onClick={() => setIsForget(!isForget)}
                  align="right"
                  variant="subtitle2"
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  Forgot Password?
                </ForgotButton>
              </RememberMeBox>
              <Box sx={{ marginBottom: "10px" }}>
                <CustomButton
                  fullWidth
                  disabled={isSignupLoading}
                  size="large"
                  color="primary"
                  variant="outlined"
                  onClick={signupFormik.handleSubmit}
                  buttonName={isSignupLoading ? "Sign up..." : "Sign up"}
                />
              </Box>

              {/*<GoogleLogin*/}
              {/*  clientId={googleClientId}*/}
              {/*  ClientSecret="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"*/}
              {/*  redirectUri="http://localhost:3000"*/}
              {/*  scope="https://www.googleapis.com/auth/userinfo.email"*/}
              {/*  onSuccess={onSuccess}*/}
              {/*  onFailure={onFailure}*/}
              {/*  useOneTap*/}
              {/*/>*/}

              {/*<Button onClick={googleLogin}       startIcon={<GoogleIcon />}*/}
              {/*        className={classes.googlebutton} fullWidth  variant='contained' size='large'>*/}
              {/*  Log in with Google*/}
              {/*</Button>*/}
              {/*<Typography className={classes.account}>Don’t have an account ? <Link className={classes.signup}>Sign up here</Link></Typography>*/}
            </form> : (
              <form>
                <FormHeading variant="h2" sx={{
                  fontSize: { xl: '60px', lg: '60px', md: '40px', sm: '30px', xs: '30px' }
                }}>
                  Welcome back
                </FormHeading>
                <FormHeadingDetails sx={{
                  fontSize: { xl: '22px', lg: '22px', md: '20px', sm: '16px', xs: '16px' }
                }}>
                  Please enter your contact details to connect.
                </FormHeadingDetails>
                {search === "?fredit" && <Box display="flex" gap="2" justifyContent="center" alignItems="center">
                  <Button fullWidth sx={{ backgroundColor: !isForget && !isShowSignup && "#1976d20a !important" }} onClick={() => {
                    setIsForget(false);
                    setIsShowSignup(false);
                  }}>Login</Button>
                  <Button fullWidth sx={{ backgroundColor: !isForget && isShowSignup && "#1976d20a !important" }} onClick={() => {
                    setIsForget(false);
                    setIsShowSignup(true);
                  }}>Signup</Button>
                </Box>}
                <CustomInputLabel>Username</CustomInputLabel>
                <CustomInput
                  id="Username"
                  variant="outlined"
                  margin="normal"
                  name="email"
                  required
                  fullWidth
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.email &&
                    Boolean(formik.errors.email)
                  }
                  helperText={
                    formik.touched.email && formik.errors.email
                  }
                />
                <CustomInputLabel>Password</CustomInputLabel>
                <CustomInput
                  id="Password"
                  type={passwordShow ? 'text' : "password"}
                  name="password"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password &&
                    Boolean(formik.errors.password)
                  }
                  helperText={
                    formik.touched.password && formik.errors.password
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={{
                        cursor: 'pointer', "& svg": {
                          fill: "#696cff"
                        }
                      }}>
                        {passwordShow ? <VisibilityOffIcon onClick={() => setPasswordShow(!passwordShow)} /> : <VisibilityIcon onClick={() => setPasswordShow(!passwordShow)} />}
                      </InputAdornment>
                    )
                  }}
                />
                <RememberMeBox>
                  <CheckRememberMe>
                    <CustomCheckBox />
                    <RememberLabel htmlFor="persist" >Remember me</RememberLabel>
                  </CheckRememberMe>
                  <ForgotButton
                    onClick={() => setIsForget(!isForget)}
                    align="right"
                    variant="subtitle2"
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    Forgot Password?
                  </ForgotButton>
                </RememberMeBox>
                <Box sx={{ marginBottom: "10px" }}><CustomButton
                  fullWidth
                  disabled={isLoading}
                  size="large"
                  color="primary"
                  onClick={formik.handleSubmit}
                  buttonName={isLoading ? "Logging in..." : "Login"}
                /></Box>

                {/*<GoogleLogin*/}
                {/*  clientId={googleClientId}*/}
                {/*  ClientSecret="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"*/}
                {/*  redirectUri="http://localhost:3000"*/}
                {/*  scope="https://www.googleapis.com/auth/userinfo.email"*/}
                {/*  onSuccess={onSuccess}*/}
                {/*  onFailure={onFailure}*/}
                {/*  useOneTap*/}
                {/*/>*/}

                {/*<Button onClick={googleLogin}       startIcon={<GoogleIcon />}*/}
                {/*        className={classes.googlebutton} fullWidth  variant='contained' size='large'>*/}
                {/*  Log in with Google*/}
                {/*</Button>*/}
                {/*<Typography className={classes.account}>Don’t have an account ? <Link className={classes.signup}>Sign up here</Link></Typography>*/}
              </form>
            ) : (
              <form>
                <Box sx={{
                  textAlign: "end", "& svg": {
                    color: "#dbdbeb99",
                    marginBottom: "20px"
                  }
                }}>
                  <IconButton sx={{
                    paddingRight: "0px",
                  }}
                    onClick={() => {
                      setIsForget(false);
                      setIsShowSignup(false);
                    }}>
                    <KeyboardBackspaceIcon />
                  </IconButton>
                </Box>
                <FormHeading variant="h2">
                  Forget Password
                </FormHeading>
                <CustomInputLabel>Username</CustomInputLabel>
                <CustomInput
                  id="Username"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="forgotEmail"
                  value={forgotFormik.values.forgotEmail}
                  onChange={forgotFormik.handleChange}
                  error={
                    forgotFormik.touched.forgotEmail &&
                    Boolean(forgotFormik.errors.forgotEmail)
                  }
                  helperText={
                    forgotFormik.touched.forgotEmail && forgotFormik.errors.forgotEmail
                  }
                />
                <Box sx={{ marginTop: "20px" }}>
                  <CustomButton
                    type="submit"
                    fullWidth
                    disabled={isForgotLoading}
                    size="large"
                    color="primary"
                    onClick={forgotFormik.handleSubmit}
                    buttonName={isForgotLoading ? "Submiting..." : "Submit"}
                  />
                </Box>
              </form>
            )}
          </LoginFormBox>
        </Grid>
      </Grid>
    </Box >
  );
};

export default Login;