import { apiSlice } from "../apiSlice"

export const refundApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getRefund: builder.query({
            query: ({ page, query }) => `/brangchain/wallet/transactions/?page=${page}${query}`,
            keepUnusedDataFor: 0,
        }),
        createRefund: builder.mutation({
            query: (data) => ({
                url: '/brangchain/wallet/manage/',
                method: 'POST',
                body: data,
            }),
            // Ensure errors are thrown when HTTP error status is received
            throwOnError: true,
        }),
    })
});

export const {
    useGetRefundQuery,
    useCreateRefundMutation
} = refundApiSlice;